import * as React from 'react';
import { Box } from '@mui/material';
import { formatCurrency } from 'lib';
import { PreviewCard, PreviewLayout } from 'components';
import { MinifigInventory } from 'model';

interface Props {
    minifigs: MinifigInventory[];
}

export const SetMinifigs: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.minifigs.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <PreviewLayout headerText='Set Minifigs'>
            {props.minifigs.map((inventory) => (
              <PreviewCard
                cardTitle={`${inventory.minifig.bricklink_id} x${inventory.quantity}`}
                pricing={`Avg: ${formatCurrency(
                  inventory.minifig.new_bricklink_prices.avg
                )}; Min: ${formatCurrency(
                  inventory.minifig.used_bricklink_prices.min
                )}; Max: ${formatCurrency(
                  inventory.minifig.used_bricklink_prices.max
                )}`}
                image={inventory.minifig.rebrickable_image_url}
                imageAlt={inventory.minifig.name}
                url={`/minifigs/${inventory.minifig.bricklink_id}`}
                target='_page'
              />
            ))}
          </PreviewLayout>
        </Box>
      )}
    </>
  );
};
