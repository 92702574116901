import * as React from 'react';

import { Cancel, CheckCircle, Loop } from '@mui/icons-material';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';

import { useRetry } from 'api';
import { formatJustDate } from 'lib';
import { AuditModel } from 'model';

interface StatusProps {
  label: string;
  status: boolean;
}

const AuditStatus: React.FC<StatusProps> = ({ label, status }) => {
  return (
    <Grid item flex="1">
      <Grid container alignItems="center">
        <h4 style={{ margin: 0, marginRight: 10 }}>{label}:</h4>
        {status ? <CheckCircle sx={{ color: green[500] }} /> : <Cancel sx={{ color: red[500] }} />}
      </Grid>
    </Grid>
  );
};

interface Props {
  audit: AuditModel;
}

export const AuditInfo: React.FC<Props> = ({ audit }) => {
  const retry = useRetry(audit.id); // Refactor this to use an audit context

  return (
    <Grid item>
      <Paper sx={{ p: 4 }}>
        <Grid container flexDirection="column" spacing="10" sx={{ pl: 2 }}>
          <Grid item>
            <Grid container spacing={1} alignItems="center" sx={{ pb: 1 }}>
              <Grid item sx={{ height: '' }}>
                <h4 style={{ margin: 0, marginRight: 10 }}>Type:</h4>
              </Grid>
              <Grid item>
                <div>{audit.audit_type}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center" sx={{ pb: 1 }}>
              <Grid item sx={{ height: '' }}>
                <h4 style={{ margin: 0, marginRight: 10 }}>Date:</h4>
              </Grid>
              <Grid item>
                <div>{formatJustDate(audit.created_at)}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container sx={{ mb: -1 }}>
              <AuditStatus label="Compliant" status={audit.compliant} />
              <AuditStatus label="Addressed" status={audit.addressed} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center" sx={{ pb: 1 }}>
              <Grid item sx={{ height: '' }}>
                <h4 style={{ margin: 0, marginRight: 10 }}>Notes:</h4>
              </Grid>
              <Grid item>
                <div>{audit.notes}</div>
              </Grid>
            </Grid>
          </Grid>
          {!audit.addressed && (
            <Grid item>
              <Grid container spacing={1} sx={{ pt: 2, pb: 2 }}>
                <Grid item sx={{ height: '' }}>
                  <Button variant="contained" color="primary" onClick={retry}>
                    Refresh Audit
                    <Loop />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
