import { StatusCodes } from 'http-status-codes';
import {api, PasswordCheckService, PasswordCheckStrength} from 'lib';
import * as React from 'react';
import {ForgotPasswordContext, TForgotPasswordModalContext} from "contexts";

interface SavePasswordProps {
  status: string;
  message: string;
  save: (email: string, code: string, password: string, confirmPassword: string) => void;
}

interface UpdatePasswordProps {
  status: string;
  message: string;
  update: (currentPassword: string, newPassword: string, confirmNewPassword: string) => void;
}

interface State {
  status: string;
  message: string;
}

export function usePasswordFields() {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const checker = new PasswordCheckService();

  const updatePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
  const updateConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

  const passwordsMatch = React.useMemo(() => confirmPassword.length === 0 || password === confirmPassword, [password, confirmPassword]);
  const validPassword = React.useMemo(() => password.length === 0 || checker.checkPasswordStrength(password) >= PasswordCheckStrength.Ok, [password]);
  const disable = React.useMemo(() => !passwordsMatch || !validPassword || confirmPassword.length === 0, [passwordsMatch, validPassword, confirmPassword]);

  const passwordError = React.useMemo(() => {
    if (checker.checkPasswordStrength(password) === PasswordCheckStrength.Common) {
      return "Your password contains a common phrase, please choose something different";
    }
    if (checker.checkPasswordStrength(password) === PasswordCheckStrength.Short) {
      return "Your password is too short, must contain at least 7 characters";
    }
    if (checker.checkPasswordStrength(password) === PasswordCheckStrength.Weak) {
      return "Your password must contain at least 1 numeric, 1 lower-case and 1 upper-case character";
    }
    return "";
  }, [password]);



  return {
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    passwordsMatch,
    validPassword,
    disable,
    passwordError,
    updatePassword,
    updateConfirmPassword,
  };
}

export const useSavePasswordWithoutToken = (): UpdatePasswordProps => {
  const [state, setState] = React.useState<State>({ status: api.idle, message: '' });

  const update = React.useCallback(async ( currentPassword: string, newPassword: string, confirmNewPassword: string) => {
    const payload = { current_password: currentPassword, password: newPassword, password_confirmation: confirmNewPassword };
    setState({ status: api.loading, message: '' });
    const resp = await api.patch(`/api/auth/password`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, message: '' });
    } else {
      setState({ status: api.error, message: resp.body.error });
    }
  }, []);

  return {
    status: state.status,
    message: state.message,
    update: update
  };
}


export function useUpdatePassword() {
  const [email, setEmail] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const updateCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(event.target.value);
  const { password, confirmPassword, setPassword, setConfirmPassword, ...passwordFields } = usePasswordFields();
  const { status, message: apiMessage, update: updatePasswordWithoutToken } = useSavePasswordWithoutToken();
  const [message, setMessage] = React.useState(apiMessage);

  const saveSuccess = React.useMemo(() => status === api.success, [status]);
  const saveError = React.useMemo(() => status === api.error, [status]);

  // clear message on password change
  React.useEffect(() => {
      setMessage('');
  }, [currentPassword]);

  React.useEffect(() => {
    setMessage(apiMessage);
  }, [apiMessage]);

  // clear password fields on success
  React.useEffect(() => {
    if (saveSuccess) {
      setPassword('');
      setCurrentPassword('');
      setConfirmPassword('');
    }
  }, [saveSuccess])

  const doUpdatePassword = async () => updatePasswordWithoutToken(currentPassword, password, confirmPassword);
  return {
    email,
    setEmail,
    password,
    confirmPassword,
    currentPassword,
    setCurrentPassword,
    updateCurrentPassword,
    doUpdatePassword,
    status,
    saveSuccess,
    saveError,
    message,
    ...passwordFields
  }
}

export const useSavePassword = (): SavePasswordProps => {
  const [state, setState] = React.useState<State>({ status: api.idle, message: '' });

  const save = React.useCallback(async (email: string, token: string, password: string, confirmPassword: string) => {
    const payload = { email: email, token: token, password: password, password_confirmation: confirmPassword };
    setState({ status: api.loading, message: '' });
    const resp = await api.post(`/api/auth/reset`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, message: '' });
    } else {
      setState({ status: api.error, message: resp.body.error });
    }
  }, []);

  return {
    status: state.status,
    message: state.message,
    save: save,
  };
};


export function useResetPassword() {
  const { email, verificationCode, hideForgotPassword } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;
  const { status: saveStatus, message: saveMessage, save: saveNewPassword } = useSavePassword();
  const { password, confirmPassword, ...passwordFields } = usePasswordFields();

  const saveSuccess = React.useMemo(() => saveStatus === api.success, [saveStatus]);
  const saveError = React.useMemo(() => saveStatus === api.error, [saveStatus]);

  const doSaveNewPassword = async () => saveNewPassword(email, verificationCode, password, confirmPassword);

  React.useEffect(() => {
    if (saveStatus === 'success') {
      hideForgotPassword("Your password has been updated. You may now Login with your new password.");
    }
  }, [saveStatus, hideForgotPassword]);

  return {
    ...passwordFields,
    password,
    confirmPassword,
    doSaveNewPassword,
    saveSuccess,
    saveError,
    saveMessage,
    saveStatus,
  };
}