import * as React from "react";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

import { useDailyBuysList, useUnitsList } from "api";
import { DatePicker, Page, UnitAutocomplete } from "components";
import { api, formatCurrency } from "lib";
import { UnitModel } from "model";

import { CashTypesCsv } from "./cash_types_csv";
import { BuysCsv } from "./daily_summary_csv";

const getRowId = (row: any) => {
  return `${row.year}-${row.month}-${row.day}`;
};

interface TotalsByCashType {
  [cashType: string]: number;
}
interface CashTypeRow {
  id: string;
  cashType: string;
  totalPaid: number;
}

const columns: GridColDef[] = [
  {
    field: "date",
    headerName: "Date",
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.month}-{params.row.day}-{params.row.year}
      </>
    ),
  },
  {
    field: "completed",
    type: "number",
    headerName: "Completed",
  },
  {
    field: "cancelled",
    type: "number",
    headerName: "Cancelled",
  },
  {
    field: "cash_count",
    type: "number",
    headerName: "Cash",
  },
  {
    field: "cash_paid",
    type: "number",
    headerName: "Cash Paid",
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{formatCurrency(params.row.cash_paid)}</>
    ),
  },
  {
    field: "credit_count",
    type: "number",
    headerName: "Credit",
  },
  {
    field: "credit_paid",
    type: "number",
    headerName: "Credit Paid",
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{formatCurrency(params.row.credit_paid)}</>
    ),
  },
  {
    field: "new_sets",
    type: "number",
    headerName: "New in Box",
  },
  {
    field: "used_sets",
    type: "number",
    headerName: "Used Sets",
  },
  {
    field: "minifigs",
    type: "number",
    headerName: "Minifigs",
  },
  {
    field: "animals",
    type: "number",
    headerName: "Animals",
  },
  {
    field: "bulk",
    type: "number",
    headerName: "Bulk",
    renderCell: (params: GridRenderCellParams<any, string>) => <>{params.row.bulk}g</>,
  },
  {
    field: "misc",
    type: "number",
    headerName: "Misc",
  },
];

export const BuysReport = () => {
  const { dailyBuysList, getDailyBuysList, resetDailyBuysList } = useDailyBuysList();
  const { units, list: getUnits } = useUnitsList();
  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(null);
  const [totalsByCashType, setTotalsByCashType] = React.useState<TotalsByCashType>({});

  const [startDate, setStartDate] = React.useState<string>(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState<string>(dayjs().endOf("month").format("YYYY-MM-DD"));

  React.useEffect(() => {
    getUnits();
  }, []);

  const setUnit = (unit: UnitModel | null) => {
    setSelectedUnit(unit);
    resetDailyBuysList();
  };

  const run = async () => {
    await Promise.all([
      getDailyBuysList(startDate, endDate, selectedUnit),
      fetchTotalsByCashType(startDate, endDate),
    ]);
  };

  const fetchTotalsByCashType = async (startDate: string, endDate: string) => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const resp = await api.get("/api/reports/buys/by_cash_type", params);
      setTotalsByCashType(resp.body);
    } catch (error) {
      console.error("Error fetching totalsByCashType:", error);
      return [];
    }
  };

  const totals = React.useMemo(() => {
    const totals = {
      total: 0,
      totalPaid: 0,
      cash: 0,
      credit: 0,
      cashPaid: 0,
      creditPaid: 0,
      cancelled: 0,
    };
    dailyBuysList.buys.forEach((line) => {
      totals.total += +line.cash_count;
      totals.total += +line.credit_count;
      totals.totalPaid += +line.cash_paid;
      totals.totalPaid += +line.credit_paid;
      totals.cash += +line.cash_count;
      totals.credit += +line.credit_count;
      totals.cashPaid += +line.cash_paid;
      totals.creditPaid += +line.credit_paid;
      totals.cancelled += +line.cancelled;
    });
    return totals;
  }, [dailyBuysList]);

  const cash_type_rows: CashTypeRow[] = Object.entries(totalsByCashType).map(
    ([cashType, totalPaid]) => ({
      id: cashType,
      cashType,
      totalPaid,
    })
  );

  const cash_type_columns = [
    { field: "cashType", headerName: "Cash Type" },
    { field: "totalPaid", headerName: "Total Paid" },
  ];

  return (
    <Page title="Daily Buys Summary">
      <Grid container spacing={2} alignItems="center">
        {units.length > 1 && (
          <Grid item xs="auto">
            <UnitAutocomplete units={units} onSelect={setUnit} value={selectedUnit} />
          </Grid>
        )}
        <Grid item xs="auto">
          <DatePicker date={startDate} onChange={(date) => setStartDate(date)} />
        </Grid>
        <Grid item xs="auto">
          <DatePicker date={endDate} onChange={(date) => setEndDate(date)} />
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" onClick={run}>
            Run
          </Button>
        </Grid>
        <Grid item xs="auto">
          <BuysCsv list={dailyBuysList.buys} />
        </Grid>
        <Grid item xs="auto"></Grid>
        <Box sx={{ width: "100%" }} />
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Buys:</strong>&nbsp;&nbsp;
            {totals.total} / {formatCurrency(totals.totalPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Cash Buys:</strong>&nbsp;&nbsp;
            {totals.cash} / {formatCurrency(totals.cashPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Credit Buys:</strong>&nbsp;&nbsp;
            {totals.credit} / {formatCurrency(totals.creditPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Cancelled Buys:</strong>&nbsp;&nbsp;
            {totals.cancelled}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={dailyBuysList.buys}
            columns={columns}
            getRowId={getRowId}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 31 },
              },
            }}
            loading={dailyBuysList.status === api.loading}
            disableRowSelectionOnClick
            paginationMode="server"
          />
        </Grid>

        <Grid container spacing={2} sx={{ p: 2, mt: 8 }} alignItems="flex-start">
          <Grid item xs={4}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Cash Type Totals</Typography>
              </Grid>
              <Grid item>
                <CashTypesCsv list={totalsByCashType} />
              </Grid>
            </Grid>
            <DataGrid rows={cash_type_rows} columns={cash_type_columns} />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
