import * as React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { debounce } from "lodash";

import { CustomerInput, useAddCustomer, useImportSearch } from "api";
import { ErrorBar } from "components";
import { SessionContext, TSessionContext, api, formatCurrency, isEmail, isPhoneNumber } from "lib";
import { CustomerImportModel } from "model";

const columns: GridColDef[] = [
  {
    field: "external_id",
    headerName: "Heartland ID",
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{params.row.external_id === null ? "N/A" : params.row.external_id}</>
    ),
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.first_name} {params.row.last_name}
      </>
    ),
    flex: 1,
  },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "phone", headerName: "Phone Number", flex: 1 },
  {
    field: "store_credit_balance",
    headerName: "Store Credit",
    type: "number",
    renderCell: (params: GridRenderCellParams<any, string>) => {
      const balance = params.row.store_credit_balance
        ? params.row.store_credit_balance.replace("$", "")
        : "0.00";
      return <>{params.row.external_id === null ? "N/A" : formatCurrency(parseFloat(balance))}</>;
    },
    flex: 1,
  },
];

interface SearchProps {
  onSelect: (customer: CustomerImportModel) => void;
}

const Search: React.FC<SearchProps> = ({ onSelect }: SearchProps) => {
  const [query, setQuery] = React.useState<string>("");
  const { status, error, customers, total, search } = useImportSearch();
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);

  React.useEffect(() => {
    if (query.length > 2) {
      doSearch(query);
    }
  }, [query, doSearch]);

  const handleRowClick = (params: GridRowParams) => {
    if (onSelect) {
      onSelect(params.row);
    }
  };

  const getRowId = (row: CustomerImportModel) => {
    return row.id || row.external_id;
  };

  return (
    <Box sx={{ mt: 1 }}>
      <TextField
        label="search"
        placeholder="search"
        value={query}
        size="small"
        fullWidth
        onChange={(e) => setQuery(e.target.value)}
        autoFocus
        sx={{ mb: 1 }}
      />
      {status === api.error ? (
        <ErrorBar error={error} />
      ) : (
        <DataGrid
          rows={customers}
          getRowId={getRowId}
          columns={columns}
          loading={status === api.loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          rowCount={total}
          onRowClick={handleRowClick}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          }}
        />
      )}
    </Box>
  );
};

const initialCustomer = {
  heartland_id: 0,
  external_id: null,
  first_name: "",
  last_name: "",
  email: null,
  phone: null,
};

interface NewCustomerFormProps {
  updateCustomer(customer: CustomerInput): void;
  close(): void;
}

const NewCustomerForm: React.FC<NewCustomerFormProps> = ({
  updateCustomer,
  close,
}: NewCustomerFormProps) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const [newCustomer, setNewCustomer] = React.useState<CustomerInput>(initialCustomer);
  const { add, customer, status, error } = useAddCustomer();

  React.useEffect(() => {
    if (currentHeartland) {
      newCustomer.heartland_id = currentHeartland.id;
    }
  }, [currentHeartland, newCustomer]);

  React.useEffect(() => {
    if (status === api.success && customer) {
      setNewCustomer(initialCustomer);
      updateCustomer(customer);
    } else if (status === api.error) {
      alert(error);
    }
  }, [status, error, customer, setNewCustomer, updateCustomer]);

  const customerIsValid = React.useMemo(() => {
    if (
      newCustomer.first_name &&
      newCustomer.first_name.trim().length > 1 &&
      newCustomer.last_name &&
      newCustomer.last_name.trim().length > 1
    ) {
      if (newCustomer.phone && isPhoneNumber(newCustomer.phone)) {
        return true;
      }
      if (newCustomer.email && isEmail(newCustomer.email)) {
        return true;
      }
    }
    return false;
  }, [newCustomer]);

  const addNewCustomer = () => add(newCustomer);

  const updatePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, phone: event.target.value });
  };

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, email: event.target.value });
  };

  const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, first_name: event.target.value });
  };

  const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomer({ ...newCustomer, last_name: event.target.value });
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="first_name"
              label="First Name"
              variant="outlined"
              fullWidth
              value={newCustomer.first_name}
              onChange={updateFirstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="last_name"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={newCustomer.last_name}
              onChange={updateLastName}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              value={newCustomer.email}
              onChange={updateEmail}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="phone"
              label="Phone"
              variant="outlined"
              fullWidth
              value={newCustomer.phone}
              onChange={updatePhone}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {status === api.loading ? (
          <CircularProgress />
        ) : (
          <Button disabled={!customerIsValid} onClick={addNewCustomer}>
            Create & Add Customer
          </Button>
        )}
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  onAdd: (customer: CustomerImportModel) => void;
}

export const CustomerModal: React.FC<Props> = (props: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const [importCustomer, setImportCustomer] = React.useState<CustomerImportModel>();
  const [showNewForm, setShowNewForm] = React.useState<boolean>(false);

  const showNewCustomerForm = () => {
    setShowNewForm(true);
  };
  const hideNewCustomerForm = () => {
    setShowNewForm(false);
  };

  const addNewCustomer = (customer: CustomerImportModel) => {
    props.onAdd(customer);
  };

  const addCustomer = () => {
    if (importCustomer && currentHeartland) {
      const customer = {
        id: importCustomer.id,
        heartland_id: importCustomer.heartland_id,
        external_id: importCustomer.external_id,
        first_name: importCustomer.first_name,
        last_name: importCustomer.last_name,
        email:
          importCustomer.email && importCustomer.email.length > 6 ? importCustomer.email : null,
        phone:
          importCustomer.phone && importCustomer.phone.length > 6 ? importCustomer.phone : null,
        address: importCustomer.address,
        status: "active",
      };
      props.onAdd(customer);
    }
  };

  const doClose = () => {
    setShowNewForm(false);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={doClose}
      aria-labelledby="select-customer-modal"
      maxWidth="lg"
      fullWidth
      disableRestoreFocus
      scroll="paper"
    >
      <DialogTitle>
        {showNewForm === true ? (
          <h2>Create & Add Customer</h2>
        ) : (
          <>
            <Box sx={{ float: "right" }}>
              <Button onClick={showNewCustomerForm} variant="contained">
                Create New Customer
              </Button>
            </Box>
            <h2>Select Customer</h2>
          </>
        )}
      </DialogTitle>
      {showNewForm === true && (
        <NewCustomerForm updateCustomer={addNewCustomer} close={hideNewCustomerForm} />
      )}
      {showNewForm === false && (
        <>
          <DialogContent>
            <Search onSelect={setImportCustomer} />
          </DialogContent>
          <DialogActions>
            <>
              <Box sx={{ mr: 4 }}>
                Selected Customer:{" "}
                <strong>
                  {importCustomer
                    ? `${importCustomer.first_name} ${importCustomer.last_name}`
                    : "none"}
                </strong>
              </Box>
              <Button onClick={addCustomer}>Add</Button>
            </>
            <Button onClick={doClose}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
