import * as React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useTagsList } from "api";
import { api } from "lib";
import { TagModel } from "model";

interface Props {
  tags: TagModel[];
  onChange: (event: React.SyntheticEvent, value: Array<string | TagModel>, reason: string) => void;
  limitTags?: number;
}

export const Tags: React.FC<Props> = ({ tags, onChange, limitTags = 3 }: Props) => {
  const { listTags } = useTagsList();

  React.useEffect(() => {
    listTags.list();
  }, []);

  return (
    <Autocomplete
      multiple
      fullWidth
      limitTags={limitTags}
      id="tags"
      options={listTags.tags}
      loading={listTags.status === api.loading}
      freeSolo
      value={tags}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        } else {
          return option.name;
        }
      }}
      renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
      sx={{ width: "500px" }}
      onChange={onChange}
    />
  );
};
