import * as React from 'react';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { Button } from '@mui/material';

const header = ["Cash Type", "Total Paid"];

interface TotalsByCashType {
  [cashType: string]: number;
}

interface Props {
  list: TotalsByCashType;
}

export const CashTypesCsv: React.FC<Props> = ({ list }: Props) => {
    const csvLink = React.useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null);

  const importData = React.useMemo(() => {
    return [
      header, 
      ...Object.entries(list).map(([cashType, totalPaid]) => [cashType, totalPaid])
    ];
  }, [list]);

  const doDownload = () => {
    csvLink?.current?.link?.click();
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={doDownload}>Download CSV</Button>
      <CSVLink ref={csvLink} data={importData} enclosingCharacter="" filename={`patron_buys_by_cash_type.csv`} target="_blank" />
    </>
  );

};