import * as React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Page, TitleLink } from 'components';
import { AuditModel } from 'model';

import { AuditComments } from '../comments';
import { AuditInfo } from '../info';

interface Props {
  audit: AuditModel;
}

export const AuditDashboard: React.FC<Props> = ({ audit }) => {
  return (
    <Page title={<TitleLink linkText="Audits" postText={`#${audit.id}`} />}>
      <Grid container flexDirection="column" spacing={2}>
        <AuditInfo audit={audit} />
        <AuditComments audit={audit} />
      </Grid>
    </Page>
  );
};
