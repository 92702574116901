import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import LaunchIcon from "@mui/icons-material/Launch";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { useUpdateHeartland } from "api/heartland";
import { InfoBar, SettingsHeader, UserSelector } from "components";
import { api } from "lib";
import { HeartlandModel, InfoBarModel, UserModel } from "model";

import { HeartlandAuthenticationSettings } from "./heartland_auth_settings";

interface FormProps {
  heartland: HeartlandModel;
}

export const HeartlandSettingsTab: React.FC<FormProps> = (props: FormProps) => {
  const { state } = useLocation();
  const { status, error, heartland: updatedHeartland, update } = useUpdateHeartland();
  const [heartland, setHeartland] = React.useState<HeartlandModel>(props.heartland);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const updateHeartland = (property: string, value: string | UserModel) =>
    setHeartland({ ...heartland, [property]: value });
  const doUpdate = () => update(heartland);
  const reset = () => setHeartland(props.heartland);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (status === api.success && updatedHeartland) {
      setHeartland(updatedHeartland);
      setInfoBar({ status: "success", message: "successfully saved." });
    } else if (status === api.error) {
      setInfoBar({ status: "error", message: error });
    } else {
      setInfoBar(null);
    }
  }, [state, status, error, setInfoBar, setHeartland, updatedHeartland]);

  const heartlandUrl = React.useMemo(() => {
    return `https://${heartland.domain}.retail.heartland.us`;
  }, [heartland.domain]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {infoBar && (
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <SettingsHeader
              headerText="General"
              helpText="Everyday settings related to setting up a heartland."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="domain"
              label="Domain"
              variant="outlined"
              fullWidth
              value={heartland.domain}
              onChange={(e) => updateHeartland("domain", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Link to={heartlandUrl} className="externalLink">
              <Box display="flex" alignItems="center">
                <span>{heartlandUrl}</span>
                <LaunchIcon />
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <UserSelector
              value={heartland.user}
              onSelect={(value) => updateHeartland("user", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <HeartlandAuthenticationSettings
              heartland={heartland}
              onHeartlandChange={(heartland) => {
                setHeartland(heartland);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Save Edits"
              startIcon={<SaveIcon />}
              onClick={doUpdate}
              fullWidth
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Reset"
              startIcon={<RestorePageIcon />}
              onClick={reset}
              fullWidth
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Cancel"
              startIcon={<CancelIcon />}
              href="/heartlands"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
