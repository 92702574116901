import * as React from 'react';

import { Box, Link } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Navigate, useLocation } from 'react-router-dom';

import { InfoBar, useLogout } from 'components';
import { SessionContext, TSessionContext } from 'lib/session';

import { ForgotPasswordModal } from './forgotpassword';
import {
  ForgotPasswordContext,
  ForgotPasswordProvider,
  ForgotPasswordState,
  TForgotPasswordModalContext,
} from 'contexts';
import { useStyles } from './styles';
import { useVerifyToken } from './api/verifytoken';
import { api } from 'lib';

export const Login = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState<string | null | undefined>('');
  const { login } = React.useContext(SessionContext) as TSessionContext;
  const [authenticated, setAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const doLogin = async () => {
    const res = await login(email, password);
    if (res) {
      setAuthenticated(true);
      setErrorMessage(null);
    } else {
      setAuthenticated(false);
      setErrorMessage('Invalid Login, please try again.');
    }
  };

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const updatePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      doLogin();
    }
  };

  if (authenticated) {
    return <Navigate replace to="/" />;
  }
  return (
    <ForgotPasswordProvider>
      <Box sx={{ width: 480 }}>
        <h1 style={{ textAlign: 'center', marginBottom: '25px' }}>Welcome to Patron</h1>
        <LoginMessage />
        <div className={classes.box}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={updateEmail}
            onKeyDown={keyPress}
          />
        </div>
        <div className={classes.box}>
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={updatePassword}
            onKeyDown={keyPress}
          />
        </div>
        <div className={classes.error}>{errorMessage}</div>
        <div className={classes.action}>
          <Button variant="contained" onClick={doLogin} name={'login-button'}>
            LOGIN
          </Button>
        </div>
        <ForgotPasswordLink />
        <ForgotPasswordModal />
      </Box>
    </ForgotPasswordProvider>
  );
};

const ForgotPasswordLink: React.FC = () => {
  const classes = useStyles();
  const { changeState, updateEmail, updateVerificationCode } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const showForgotPassword = () => {
    changeState(ForgotPasswordState.request);
  };
  const { status: verifyStatus, verifyToken, resetStatus: resetVerifyStatus } = useVerifyToken();
  const location = useLocation();
  const { logout } = useLogout();
  const searchParams = new URLSearchParams(location.search);
  const encodedData = searchParams.get('data');

  const verifyEmail = (email: string) => {
    if (currentUser?.email !== email) {
      logout();
      changeState(ForgotPasswordState.idle);
    }
  }
  // Check for reset password token in URL
  React.useEffect(() => {
    const decodedParams = atob(encodedData || '');
    const urlParams = new URLSearchParams(decodedParams);
    const code = urlParams.get('token');
    const email = urlParams.get('email');
    const reset = (Number(urlParams.get('reset')) as ForgotPasswordState) || ForgotPasswordState.idle;

    if (reset !== ForgotPasswordState.idle) {
      changeState(reset);
      if (code && email) {
        updateEmail(email)
        updateVerificationCode(code);
        verifyEmail(email)
        verifyToken(email, code);
      }
    }

  }, [location]);

  React.useEffect(() => {
      if (verifyStatus === api.success) {
        changeState(ForgotPasswordState.reset);
      }
      return (() => {
        resetVerifyStatus();
      });
    }, [verifyStatus, changeState, resetVerifyStatus]);

  return (
    <div className={classes.links}>
      <Link onClick={showForgotPassword} href="#">
        Forgot Password
      </Link>
    </div>
  );
};

const LoginMessage: React.FC = () => {
  const { message } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;

  if (message) {
    return <InfoBar message={message} status="info" />;
  } else {
    return <></>;
  }
};
