import * as React from 'react';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useUsersList } from 'api/user';
import { InfoBar, Page } from 'components';
import { api } from 'lib';

import useStyles from './styles';

const userLink = (id: number) => {
  return `/users/${id}`;
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'username',
    headerName: 'Username',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={userLink(params.row.id)}>{params.row.username}</Link>
      </>
    ),
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={userLink(params.row.id)}>{params.row.first_name}</Link>
      </>
    ),
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={userLink(params.row.id)}>{params.row.last_name}</Link>
      </>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/users/${params.row.id}`}>{params.row.email}</Link>
      </>
    ),
  },
  {
    field: 'user_type',
    headerName: 'Type',
    minWidth: 120,
  },
];

interface ListProps {
  query: string;
  archived: boolean;
}

const List: React.FC<ListProps> = ({ query, archived }: ListProps) => {
  const { usersList, list, listArchived } = useUsersList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  React.useEffect(() => {
    if (archived) {
      listArchived();
    } else if (query.length === 0 || query.length > 2) {
      list(query, paginationModel.page, paginationModel.pageSize);
    }
  }, [paginationModel, list, query]);

  if (usersList.status === api.error) {
    return <InfoBar status="error" message={usersList.error} />;
  }
  return (
    <DataGrid
      rows={usersList.users}
      loading={usersList.status !== api.success}
      columns={columns}
      rowCount={usersList.total}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      paginationMode="server"
    />
  );
};

interface UserListProps {
  archived?: boolean;
}

export const UserList: React.FC<UserListProps> = ({ archived = false }) => {
  const classes = useStyles();
  const [query, setQuery] = React.useState<string>('');

  return (
    <Page title="Users">
      <Grid container spacing={2} justifyContent="left" alignItems="center" sx={{ mb: 1 }}>
        <Grid item xs={8}>
          <TextField
            name="query"
            label="Search"
            fullWidth
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" startIcon={<PersonAddIcon />} href="/users/new">
            New User
          </Button>
          {archived ? (
            <Button href="/users">View Active Users</Button>
          ) : (
            <Button href="/users/archived">View Archived Users</Button>
          )}
        </Grid>
      </Grid>

      <List query={query} archived={archived} />
    </Page>
  );
};
