import * as React from "react";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Alert, AlertColor, Button, Grid, Snackbar } from "@mui/material";

interface Props {
  message: any;
  status: AlertColor;
  onClose?: () => void;
  autoHideDuration?: number;
  withClose?: boolean;
}

export const InfoBar: React.FC<Props> = ({
  message,
  status,
  onClose,
  autoHideDuration = 4000,
  withClose = false,
}: Props) => {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (!open && onClose) {
      onClose();
    }
  }, [open]);

  const renderMessage = () => {
    if (Array.isArray(message)) {
      return (
        <ul>
          {message.map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      );
    } else {
      return <span style={{ paddingRight: 16 }}>{message}</span>;
    }
  };

  return (
    <Snackbar
      sx={{ mt: 8 }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={onClose}
    >
      <Alert
        severity={status}
        variant="filled"
        onClose={withClose ? onClose : undefined}
        sx={{ width: "100%" }}
      >
        {renderMessage()}
      </Alert>
    </Snackbar>
  );
};
