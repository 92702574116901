import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, IconButton } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";

import { CopyToClipboardButton } from "components";
import { SessionContext, TSessionContext } from "lib";
import { LegoSetImageModel } from "model";

interface LegoSetImageProps {
  image: LegoSetImageModel;
  selectedPrimaryImageId: number | null;
  onSelectPrimary: (id: number) => void;
}

export const LegoSetImage: React.FC<LegoSetImageProps> = ({
  image,
  selectedPrimaryImageId,
  onSelectPrimary,
}) => {
  const { deleteImage, legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;

  const doRemove = (image_id: number) => {
    if (image_id) {
      deleteImage({ id: image_id, lego_set_id: legoSet.id} as LegoSetImageModel);
    }
  };

  return (
    <Grid item key={image.id} xs="auto">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: 100,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: 64,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedPrimaryImageId === image.id ? "grey.300" : "transparent",
              borderRadius: 2,
              padding: 1,
              cursor: isAdmin() ? "pointer" : "default",
            }}
            onClick={isAdmin() ? () => image.id && onSelectPrimary(image.id) : undefined}
          >
            <img
              alt={legoSet.name}
              src={image.image_url}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CopyToClipboardButton content={image.image_url} />
            {isAdmin() && (
              <IconButton
                onClick={() => image.id && doRemove(image.id)}
                sx={{
                  color: "grey",
                }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
