import * as React from "react";
import { Button, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";
import { SessionContext, TSessionContext } from "lib";
import { LegoSetImageModel } from "model";
import { CarouselImagesDisplay } from "./carousel_images_display";
import { Description } from "./description";
import { LegoSetImage } from "./lego_set_image";
import { SetMinifigs } from "./set_minifigs";

export const Details: React.FC = () => {
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { addImage } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const [url, setUrl] = React.useState<string>("");
  const [selectedPrimaryImageId, setSelectedPrimaryImageId] = React.useState<number | null>(null);

  const doAdd = () => {
    if (legoSet) {
      addImage({ image_url: url, lego_set_id: legoSet.id, condition: "new" } as LegoSetImageModel);
      setUrl("");
    }
  };

  const updateUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value.split("?")[0]);
  };

  return (
    <Grid container spacing={2} sx={{ mb: 8 }}>
      <Grid item xs={4}>
        <CarouselImagesDisplay images={legoSet.new_images} />
      </Grid>
      <Grid item xs={8}>
        <Description />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Images
          </Typography>
          <Grid container spacing={2} sx={{ mb: 1 }}>
            {legoSet.new_images.map((i) => (
              <Grid item xs={isAdmin() ? 12 : "auto"} key={i.id}>
                <LegoSetImage
                  image={i}
                  selectedPrimaryImageId={selectedPrimaryImageId}
                  onSelectPrimary={setSelectedPrimaryImageId}
                />
              </Grid>
            ))}
            {legoSet.new_images.length === 0 && legoSet.rebrickable_image_url && (
              <Grid item>
                <Skeleton variant="rectangular" width={64} height={64} animation="wave" />
              </Grid>
            )}
          </Grid>
          {isAdmin() && (
            <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Add a new image"
                  value={url}
                  onChange={updateUrl}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={doAdd}>
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={8}>
          <SetMinifigs minifigs={legoSet.minifigs} />
        </Grid>
      </Grid>
    </Grid>
  );
};
