import * as React from "react";

import { useUpdateRoyaltyFee } from "api";
import { api } from "lib";
import { RoyaltyFeeModel } from "model";

type Callback = (stauts: string, error: string) => void;

interface ActionState {
  action: string;
  status: string;
  error: string;
}

export type TRoyaltyFeeContext = {
  royaltyFee: RoyaltyFeeModel;
  actionStatus: ActionState;
  update: (key: string, value: number) => void;
  addNote: (note: string) => void;
  submit: () => void;
  resubmit: () => void;
  startReview: () => void;
  reject: () => void;
  approve: () => void;
  save: () => void;
  resetAction: () => void;
};

interface RoyaltyFeeContextProps {
  royaltyFee: RoyaltyFeeModel;
  children: React.ReactNode;
}

export const RoyaltyFeeContext = React.createContext<TRoyaltyFeeContext | null>(null);

export const RoyaltyFeeProvider: React.FC<RoyaltyFeeContextProps> = (
  props: RoyaltyFeeContextProps
) => {
  const { children } = props;
  const [royaltyFee, setRoyaltyFee] = React.useState<RoyaltyFeeModel>(props.royaltyFee);
  const [actionState, setActionState] = React.useState<ActionState>({
    action: "none",
    status: api.idle,
    error: "",
  });
  const { updateRoyaltyFee } = useUpdateRoyaltyFee();

  const resetAction = () => setActionState({ action: "none", status: api.idle, error: "" });

  const reject = () => updateRoyaltyFee.reject(royaltyFee);
  const approve = () => updateRoyaltyFee.approve(royaltyFee);
  const startReview = () => updateRoyaltyFee.startReview(royaltyFee);
  const save = () => updateRoyaltyFee.update(royaltyFee);
  const submit = () => updateRoyaltyFee.submit(royaltyFee);
  const resubmit = () => updateRoyaltyFee.resubmit(royaltyFee);
  const addNote = (newNote: string) => updateRoyaltyFee.addNote(royaltyFee, newNote);

  React.useEffect(() => {
    setActionState({
      status: updateRoyaltyFee.status,
      error: updateRoyaltyFee.error,
      action: updateRoyaltyFee.action,
    });
    if (
      updateRoyaltyFee.status === api.success &&
      updateRoyaltyFee.action === "add_note" &&
      updateRoyaltyFee.royaltyFee
    ) {
      setRoyaltyFee({ ...royaltyFee, notes: updateRoyaltyFee.royaltyFee.notes });
    } else if (updateRoyaltyFee.status === api.success && updateRoyaltyFee.royaltyFee) {
      setRoyaltyFee(updateRoyaltyFee.royaltyFee);
    }
  }, [
    updateRoyaltyFee.status,
    updateRoyaltyFee.action,
    updateRoyaltyFee.error,
    updateRoyaltyFee.royaltyFee,
  ]);

  const update = (key: string, value: number) => {
    const updatedRoyaltyFee = { ...royaltyFee, [key]: value };
    setRoyaltyFee(updatedRoyaltyFee);
  };

  return (
    <RoyaltyFeeContext.Provider
      value={{
        royaltyFee: royaltyFee,
        actionStatus: actionState,
        submit,
        resubmit,
        startReview,
        reject,
        approve,
        save,
        update,
        addNote,
        resetAction,
      }}
    >
      {children}
    </RoyaltyFeeContext.Provider>
  );
};
