import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { CustomerModel } from "model";

interface State {
  status: string;
  error: string;
  customer: CustomerModel | null;
}

interface Props extends State {
  sync: (id: number) => void;
  reset: () => void;
}

const initialState = {
  status: api.idle,
  error: "",
  customer: null,
};

export const useSyncCustomer = (): Props => {
  const [state, setState] = React.useState<State>(initialState);

  const sync = React.useCallback(async (customer_id: number) => {
    setState({ status: api.loading, customer: null, error: "" });
    const resp = await api.get(`/api/customers/${customer_id}/sync`);

    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, customer: resp.body, error: "" });
    } else if (resp.status === StatusCodes.NOT_FOUND) {
      setState({ status: api.error, customer: null, error: "Customer not found" });
    } else {
      setState({ status: api.error, customer: null, error: resp.body.error });
    }
  }, []);

  const reset = () => {
    setState(initialState);
  };

  return {
    ...state,
    sync,
    reset,
  };
};
