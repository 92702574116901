import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { TagModel } from "model";

interface State {
  status: string;
  error: string;
  tags: TagModel[];
  total: number;
}

interface Props {
  listTags: State & {
    list: (page?: number, pageSize?: number, sort?: string, direction?: string) => void;
  };
}

const defaultState = {
  status: api.idle,
  error: "",
  tags: [],
  total: 0,
};

export const useTagsList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const list = React.useCallback(
    async (page = 0, pageSize = 0, sort = "name", direction = "asc") => {
      setState({ status: api.loading, total: 0, tags: [], error: "" });
      const options = {
        page: page.toString(),
        size: pageSize.toString(),
        sort: sort,
        direction: direction,
      };
      const resp = await api.get("/api/tags", options);
      if (resp.status === StatusCodes.OK) {
        setState({ status: api.success, total: resp.body.total, tags: resp.body.tags, error: "" });
      } else {
        setState({ status: api.error, total: 0, tags: [], error: resp.body });
      }
    },
    []
  );

  return { listTags: { ...state, list } };
};
