import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import { Box, Grid, Tooltip } from "@mui/material";

interface Props {
  helpText: string;
  headerText: string;
}

export const SettingsHeader: React.FC<Props> = ({ helpText, headerText }) => {
  return (
    <>
      <Box sx={{ background: "#eeeeee", p: 1 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title={helpText}>
              <HelpIcon sx={{ fontSize: 14 }} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Box>{headerText}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
