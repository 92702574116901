import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { AuditSearchList, Page } from 'components';

export const Audits: React.FC = () => {
  return (
    <Page title="Audits">
      <AuditSearchList resultCount={10} />
    </Page>
  );
};
