import * as React from "react";

import { Grid } from "@mui/material";

import { Explain } from "./explain";

interface InputRowProps {
  summary: string;
  details: React.ReactNode | string;
  children: React.ReactNode;
}

export const InputRow: React.FC<InputRowProps> = ({
  summary,
  details,
  children,
}: InputRowProps) => {
  return (
    <Grid container spacing={1} alignItems={"center"} sx={{ my: 0.75 }}>
      <Grid item xs={9}>
        <Explain summary={summary} details={details} />
      </Grid>
      <Grid item xs={3}>
        {children}
      </Grid>
    </Grid>
  );
};
