import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { RoyaltyFeeModel } from "model";

interface State {
  status: string;
  error: string;
  royaltyFee: RoyaltyFeeModel | null;
}

interface Props {
  getRoyaltyFee: State & {
    get: (id: number) => void;
    reset: () => void;
  };
}

const defaultState: State = {
  status: api.idle,
  error: "",
  royaltyFee: null,
};

export const useGetRoyaltyFee = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(
    async (id: number) => {
      setState((prevState) => ({ ...prevState, error: "", status: api.loading }));
      const resp = await api.get(`/api/royalties/${id}`);
      if (resp.status === StatusCodes.OK) {
        setState({
          status: api.success,
          royaltyFee: resp.body.royalty_fee,
          error: "",
        });
      } else {
        setState({ status: api.error, royaltyFee: null, error: resp.body });
      }
    },
    [setState]
  );

  const reset = () => {
    setState(defaultState);
  };

  return {
    getRoyaltyFee: {
      ...state,
      get: get,
      reset: reset,
    },
  };
};
