import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import { Button, Grid, Paper, TextField, Tooltip } from "@mui/material";

import { useLoadBuySettings } from "api";
import { useSaveBuySettings } from "api/buy/save_settings";
import { InfoBar } from "components";
import { SessionContext, TSessionContext, api } from "lib";
import { BuySettingModel, InfoBarModel } from "model";

export const GeneralBuySettingsTab: React.FC = () => {
  const { currentHeartland, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentHeartland && currentUser) {
      load(currentHeartland.id);
    }
  }, [currentHeartland, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings({ ...saveBuySettings.settings.settings });
      setInfoBar({ status: "success", message: saveBuySettings.msg });
      saveBuySettings.reset();
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else if (saveBuySettings.status === api.loading) {
      setInfoBar({ status: "info", message: "Saving" });
    }
  }, [saveBuySettings.status]);

  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: value };
      setSettings(newSettings);
    }
  };

  const resetSettings = () => {
    if (currentHeartland) {
      saveBuySettings.resetSettings(currentHeartland.id, "misc");
    }
  };

  const saveSettings = () => {
    if (settings) {
      saveBuySettings.save({ ...s, settings: settings });
    }
  };

  if (settings) {
    return (
      <Paper sx={{ p: 2, height: "100%" }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar && (
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          )}
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={0.3}>
                <Tooltip
                  title={`List of cash payment methods your  store supports. Enter a comma separated list`}
                >
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                Cash Payment Methods
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.cash_types}
                  onChange={(e) => updateSetting("cash_types", e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>
                  Reset to default settings
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return <></>;
  }
};
