import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { Result, SessionContext, TSessionContext, api } from "lib";
import { RoyaltyFeeModel } from "model";

interface State {
  status: string;
  action: string;
  error: string;
  royaltyFee: RoyaltyFeeModel | null;
}

interface Props {
  updateRoyaltyFee: State & {
    update: (royaltyFee: RoyaltyFeeModel) => void;
    submit: (royaltyFee: RoyaltyFeeModel) => void;
    resubmit: (royaltyFee: RoyaltyFeeModel) => void;
    startReview: (royaltyFee: RoyaltyFeeModel) => void;
    addNote: (royaltyFee: RoyaltyFeeModel, note: string) => void;
    approve: (royaltyFee: RoyaltyFeeModel) => void;
    reject: (royaltyFee: RoyaltyFeeModel) => void;
    reset: () => void;
  };
}

const defaultState: State = {
  status: api.idle,
  action: "",
  error: "",
  royaltyFee: null,
};

interface RoyaltyFeePayload {
  reported_store_sales: number;
  additional_online_sales: number;
  other_sales: number;
  inter_store_sales: number;
  marketing_alternative_spend: number;
  marketing_digital_spend: number;
  marketing_event_spend: number;
  marketing_in_store_spend: number;
  marketing_outdoor_spend: number;
  marketing_guerilla_spend: number;
  marketing_print_spend: number;
  marketing_social_spend: number;
  marketing_traditional_spend: number;
  overdue: boolean;
}

const buildPayload = (royaltyFee: RoyaltyFeeModel): RoyaltyFeePayload => {
  return {
    reported_store_sales: royaltyFee.reported_store_sales,
    additional_online_sales: royaltyFee.additional_online_sales,
    other_sales: royaltyFee.other_sales,
    inter_store_sales: royaltyFee.inter_store_sales,
    marketing_alternative_spend: royaltyFee.marketing_alternative_spend,
    marketing_digital_spend: royaltyFee.marketing_digital_spend,
    marketing_event_spend: royaltyFee.marketing_event_spend,
    marketing_in_store_spend: royaltyFee.marketing_in_store_spend,
    marketing_outdoor_spend: royaltyFee.marketing_outdoor_spend,
    marketing_guerilla_spend: royaltyFee.marketing_guerilla_spend,
    marketing_print_spend: royaltyFee.marketing_print_spend,
    marketing_social_spend: royaltyFee.marketing_social_spend,
    marketing_traditional_spend: royaltyFee.marketing_traditional_spend,
    overdue: royaltyFee.overdue,
  };
};

const loadingState = (action: string, prevState: State) => ({
  ...prevState,
  action: action,
  status: api.loading,
  error: "",
});

const errorState = (action: string, message: string) => ({
  action: action,
  status: api.error,
  royaltyFee: null,
  error: message,
});

const successState = (action: string, royaltyFee: RoyaltyFeeModel) => ({
  action: action,
  status: api.success,
  royaltyFee: royaltyFee,
  error: "",
});

export const useUpdateRoyaltyFee = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  const processResponse = (action: string, resp: Result<any>) => {
    if (resp.status === StatusCodes.OK) {
      setState(successState(action, resp.body.royalty_fee));
    } else {
      setState(errorState(action, resp.body));
    }
  };

  const addNote = async (royaltyFee: RoyaltyFeeModel, note: string) => {
    const action = "add_note";
    if (currentUser) {
      setState(loadingState(action, state));
      const resp = await api.post(`/api/royalties/${royaltyFee.id}/note`, { note: note });
      processResponse(action, resp);
    } else {
      setState(errorState(action, "Unauthorized Request, missing submitter"));
    }
  };
  const submit = async (royaltyFee: RoyaltyFeeModel) => {
    const action = "submit";
    if (currentUser) {
      setState(loadingState(action, state));
      const resp = await api.post(
        `/api/royalties/${royaltyFee.id}/submit`,
        buildPayload(royaltyFee)
      );
      processResponse(action, resp);
    } else {
      setState(errorState(action, "Unauthorized Request, missing submitter"));
    }
  };

  const resubmit = async (royaltyFee: RoyaltyFeeModel) => {
    const action = "resubmit";
    if (currentUser) {
      setState(loadingState(action, state));
      const resp = await api.post(
        `/api/royalties/${royaltyFee.id}/resubmit`,
        buildPayload(royaltyFee)
      );
      processResponse(action, resp);
    } else {
      setState(errorState(action, "Unauthorized Request, missing submitter"));
    }
  };

  const startReview = async (royaltyFee: RoyaltyFeeModel) => {
    const action = "start_review";
    setState(loadingState(action, state));
    const resp = await api.post(
      `/api/royalties/${royaltyFee.id}/start_review`,
      buildPayload(royaltyFee)
    );
    processResponse(action, resp);
  };

  const approve = async (royaltyFee: RoyaltyFeeModel) => {
    const action = "approve";
    if (currentUser) {
      setState(loadingState(action, state));
      const resp = await api.post(
        `/api/royalties/${royaltyFee.id}/approve`,
        buildPayload(royaltyFee)
      );
      processResponse(action, resp);
    } else {
      setState(errorState(action, "Unauthorized Request, missing approver"));
    }
  };

  const reject = async (royaltyFee: RoyaltyFeeModel) => {
    const action = "reject";
    if (currentUser) {
      setState(loadingState(action, state));
      const resp = await api.post(
        `/api/royalties/${royaltyFee.id}/reject`,
        buildPayload(royaltyFee)
      );
      processResponse(action, resp);
    } else {
      setState(errorState(action, "Unauthorized Request, missing approver"));
    }
  };

  const update = React.useCallback(
    async (royaltyFee: RoyaltyFeeModel) => {
      const action = "update";
      setState(loadingState(action, state));
      const payload = buildPayload(royaltyFee);
      const resp = await api.put(`/api/royalties/${royaltyFee.id}`, payload);
      processResponse(action, resp);
    },
    [setState]
  );

  const reset = () => {
    setState(defaultState);
  };

  return {
    updateRoyaltyFee: {
      ...state,
      addNote,
      submit,
      resubmit,
      startReview,
      approve,
      reject,
      update,
      reset,
    },
  };
};
