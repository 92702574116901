import * as React from "react";

import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  onRevokeOauth: () => void;
}

export const ConfirmOauthRevoke: React.FC<Props> = ({ open, onClose, onRevokeOauth }) => {
  const revokeOauth = () => {
    onRevokeOauth();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      fullWidth
      disableRestoreFocus
      scroll="paper"
      sx={{ minHeight: "800px" }}
    >
      <DialogTitle>Revoke Oauth</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ text: "center" }}>
              Are you sure you want to revoke this Heartland instance's authentication? (Webhooks
              won't work without OAuth integration)
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="error" onClick={revokeOauth} fullWidth>
              Yes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onClose} fullWidth>
              No
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
