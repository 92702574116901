import * as React from "react";

import { Alert, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

interface OAuthAlertState {
  auth_message: string;
  auth_success: boolean;
  auth_display: boolean;
}

const defaultOauthAlertState = {
  auth_message: "",
  auth_success: false,
  auth_display: false,
};

export const OAuthAlert: React.FC = () => {
  const location = useLocation();
  const [alertState, setAlertState] = React.useState<OAuthAlertState>(defaultOauthAlertState);

  React.useEffect(() => {
    if (location.state) {
      setAlertState(location.state);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <>
      {alertState.auth_display && (
        <Grid item xs={12}>
          <Alert sx={{ mb: 2 }} severity={alertState.auth_success ? "success" : "error"}>
            {alertState.auth_message}
          </Alert>
        </Grid>
      )}
    </>
  );
};
