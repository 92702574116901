import * as React from 'react';
import { Box, Button, Link, TextField } from '@mui/material';
import { api, isEmail } from 'lib';

import { useRequestReset } from './api/requestReset';
import { ForgotPasswordContext, ForgotPasswordState, TForgotPasswordModalContext } from 'contexts';
import { rowStyle, } from './styles';


export const RequestForm: React.FC = () => {
  const { status: requestStatus, message: requestMessage, requestReset, setOk: setRequestOk, resetStatus: resetRequestStatus } = useRequestReset();
  const { email, state, updateEmail, changeState } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;
  const [skipMessage, setSkipMessage] = React.useState<string>('');
  const updateEmailField = (event: React.ChangeEvent<HTMLInputElement>) => updateEmail(event.target.value);

  React.useEffect(() => {
    if (requestStatus === api.success) {
      changeState(ForgotPasswordState.verify);
    }
  }, [requestStatus, changeState]);

  React.useEffect(() => {
    if (state !== ForgotPasswordState.request) {
      resetRequestStatus();
    }
  }, [state, resetRequestStatus]);

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      doRequestReset();
    }
  };

  const skipRequest = () => {
    if (isEmail(email)) {
      setRequestOk();
    } else {
      setSkipMessage("A valid email is required!");
    }
  };

  const doRequestReset = async () => {
    if (isEmail(email)) {
      setSkipMessage("");
      requestReset(email);
    } else {
      setSkipMessage("A valid email is required!");
    }
  };

  const renderRequestErrors = (): string => {
    if (skipMessage.length > 1) {
      return skipMessage;
    } else if (requestStatus === api.error) {
      return requestMessage;
    } else {
      return ''
    }
  };

  return (
    <>
      <h2>Forgot your password?</h2>
      <Box>
        <p>Enter your email address to reset your password</p>
      </Box>
      <Box sx={rowStyle}>
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={updateEmailField}
          onKeyDown={keyPress}
        />
      </Box>
      <Box sx={rowStyle} style={{ width: '60%' }}>
        <div className="error">{renderRequestErrors()}</div>
      </Box>
      <Box sx={rowStyle}>
        <Button variant="contained" onClick={doRequestReset}>CONTINUE</Button>
        <p style={{ fontSize: '0.9rem' }}>Already have a code? <Link href="#" onClick={skipRequest}>Continue</Link></p>
      </Box>
    </>
  );
};