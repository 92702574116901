import * as React from 'react';

import { Autocomplete, SelectChangeEvent, TextField } from '@mui/material';

import { UnitModel } from 'model';

interface AllUnitSelectorProps {
  value: UnitModel | null;
  onSelect: (unit: UnitModel | null) => void;
  units: UnitModel[];
}

export const UnitAutocomplete: React.FC<AllUnitSelectorProps> = ({ units, onSelect, value }) => {
  const [unit, setUnit] = React.useState<UnitModel | null>(null);

  const select = (e: SelectChangeEvent) => {
    const unit = units.find((unit) => unit.id.toString() === e.target.value.toString());
    if (unit) {
      onSelect(unit);
    } else {
      onSelect(null);
    }
  };

  React.useEffect(() => {
    if (value != null) {
      const selectedUnit = units.find((u) => u.id == value.id);

      if (selectedUnit != null) {
        setUnit(selectedUnit);
      }
    }
  }, [value, units]);

  const selectUnit = (event: any, newUnit: UnitModel | null) => {
    if (onSelect) {
      onSelect(newUnit);
    } else {
      setUnit(newUnit);
    }
  };

  return (
    <Autocomplete
      options={units}
      getOptionLabel={(entry) => entry.name}
      onChange={selectUnit}
      renderInput={(params) => <TextField {...params} label="Unit" />}
      sx={{ width: 200 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};
