import * as React from "react";

import { Link } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";

import { formatCurrency, formatPercentage } from "lib";
import { DailySaleModel } from "model";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Unit",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/units/${params.row.unit_id}`}>{params.row.unit_name}</Link>
      </>
    ),
  },
  {
    field: "net_sales",
    type: "number",
    headerName: "Sales",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.net_sales)} </>
    ),
  },
  {
    field: "tickets",
    type: "number",
    headerName: "Tickets",
    headerAlign: "right",
    width: 60,
    align: "right",
  },
  {
    field: "atv",
    type: "number",
    headerName: "ATV",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.atv)} </>
    ),
  },
  {
    field: "margin",
    type: "number",
    headerName: "Margin",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatPercentage(params.row.margin)} </>
    ),
  },
  {
    field: "bulk_sales",
    type: "number",
    headerName: "Bulk",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.bulk_sales)} </>
    ),
  },
  {
    field: "minifig_sales",
    type: "number",
    headerName: "Minifig Sales",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.minifig_sales)} </>
    ),
  },
  {
    field: "used_set_sales",
    type: "number",
    headerName: "Used Sets",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.used_set_sales)} </>
    ),
  },
  {
    field: "new_set_sales",
    type: "number",
    headerName: "New Sets",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.new_set_sales)} </>
    ),
  },
  {
    field: "aftermarket_sales",
    type: "number",
    headerName: "Aftermarket",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.aftermarket_sales)} </>
    ),
  },
  {
    field: "misc_sales",
    type: "number",
    headerName: "Misc",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.misc_sales)} </>
    ),
  },
  {
    field: "events_sales",
    type: "number",
    headerName: "Events",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.events_sales)} </>
    ),
  },
];

interface TableProps {
  data: DailySaleModel[];
  loading: boolean;
}

export const DailySalesTable: React.FC<TableProps> = ({ data, loading }: TableProps) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  return (
    <DataGridPro
      rows={data}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: "net_sales", sort: "desc" }],
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 50 },
        },
      }}
      loading={loading}
      rowCount={data.length}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
    />
  );
};
