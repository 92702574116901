import * as React from "react";

import { Box } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";

import { LegoSetMyStoreInfo } from "components";
import { SessionContext, TSessionContext } from "lib";

import { BuyHistory } from "./buy_history";

export const MyStoreInfo: React.FC = () => {
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { isHeartlandLive } = React.useContext(SessionContext) as TSessionContext;

  return (
    <Box>
      {isHeartlandLive() && (
        <Box sx={{ mb: 5, width: "80%", mx: "auto", textAlign: "left" }}>
          <LegoSetMyStoreInfo legoSet={legoSet} showImport={true} />
        </Box>
      )}
      <Box sx={{ mb: 4, width: "80%", mx: "auto", textAlign: "left" }}>
        <BuyHistory legoSet={legoSet} type="New" />
        <BuyHistory legoSet={legoSet} type="Used" />
      </Box>
    </Box>
  );
};
