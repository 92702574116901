import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Checkbox as MUICheckbox, TableCell, TableRow } from "@mui/material";
import { BuyContext, TBuyContext } from "contexts";

import {
  Checkbox,
  CurrencyInput,
  HideOnPrint,
  HideOnScreen,
  NotesInput,
  QuantityInput,
} from "components";
import { formatCurrency } from "lib";
import { MiscBuyLineModel } from "model";

interface Props {
  row: MiscBuyLineModel;
}

export const MiscBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteMisc, updateMisc } = React.useContext(BuyContext) as TBuyContext;

  const remove = (id: number) => deleteMisc(id);
  const update = (prop: string, value: string | number | boolean) =>
    updateMisc({ ...row, [prop]: value });
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>
        <Box sx={{ mb: 1, fontWeight: 600 }}>{row.description}</Box>
        <HideOnPrint>
          <NotesInput value={row.notes} onChange={(value: string) => update("notes", value)} />
        </HideOnPrint>
        <HideOnScreen>{row.notes}</HideOnScreen>
      </TableCell>
      <TableCell>
        <Checkbox
          label="Overstock"
          checked={row.overstock}
          onChange={(e) => update("overstock", e.target.checked)}
        />
        <br />
      </TableCell>
      <TableCell align="center">
        <QuantityInput
          quantity={row.quantity}
          onChange={(value) => update("quantity", value)}
          decimal
          width={80}
        />
      </TableCell>
      <TableCell align="right">
        <HideOnPrint>
          <CurrencyInput
            value={row.value}
            label={""}
            onChange={(e) => update("value", +e.target.value)}
            error={row.value === 0}
          />
        </HideOnPrint>
      </TableCell>
      <TableCell align="right" sx={{ width: 100 }}>
        <HideOnPrint>{formatCurrency(row.value - row.value_discount)}</HideOnPrint>
      </TableCell>
      <TableCell align="right" sx={{ width: 120 }}>
        <HideOnPrint>{formatCurrency(row.quantity * (row.value - row.value_discount))}</HideOnPrint>
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <IconButton aria-label="delete" onClick={() => remove(row.key)}>
            {" "}
            <DeleteIcon />
          </IconButton>
          <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
        </HideOnPrint>
      </TableCell>
    </TableRow>
  );
};
