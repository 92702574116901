import * as React from 'react';

import { StatusCodes } from 'http-status-codes';

import { api } from 'lib';
import { DailySaleModel, UnitModel } from 'model';

interface State {
  status: string;
  error: string;
  sales: DailySaleModel[];
  total: DailySaleModel | null;
}

interface Props {
  dailySalesList: State;
  getDailySalesList: (startDate: string, endDate: string, UnitModel?: UnitModel | null) => void;
  resetDailySalesList: () => void;
}

const defaultState: State = {
  status: api.idle,
  error: '',
  sales: [],
  total: null,
};

export const useDailySalesList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(
    async (startDate: string, endDate: string, unit: UnitModel | null = null) => {
      setState((prevState) => ({ ...prevState, status: api.loading }));
      const options: Record<string, string> = {
        start_date: startDate,
        end_date: endDate,
      };

      if (unit) {
        options.unit = unit.id.toString();
      }

      const resp = await api.get('/api/reports/sales/daily', options);
      if (resp.status === StatusCodes.OK) {
        setState({
          status: api.success,
          sales: resp.body.sales,
          total: resp.body.total,
          error: '',
        });
      } else {
        setState({ status: api.error, sales: [], total: resp.body.total, error: resp.body });
      }
    },
    [setState]
  );

  const reset = () => {
    setState(defaultState);
  };

  return {
    dailySalesList: {
      ...state,
    },
    getDailySalesList: get,
    resetDailySalesList: reset,
  };
};
