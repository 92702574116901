import * as React from 'react';

import { StatusCodes } from 'http-status-codes';

import { api } from 'lib';
import {
  AnimalBuyLineModel,
  BulkBuyLineModel,
  BuyModel,
  CustomerImportModel,
  MinifigBuyLineModel,
  MiscBuyLineModel,
  NewSetBuyLineModel,
  SeriesMinifigBuyLineModel,
  UsedSetBuyLineModel,
} from 'model';

import { addKeys } from './helpers';

interface Props {
  status: string;
  error: string;
  buy: BuyModel | null;
  save: (buy: BuyModel) => void;
  complete: (buy: BuyModel) => void;
  finalize: (buy: BuyModel) => void;
  cancel: (buy: BuyModel, note: string) => void;
  editComplete: (buy: BuyModel, note: string) => void;
}

const extractNewSets = (buyLines: NewSetBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      lego_set_id: l.lego_set.id,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      damaged: l.damaged,
      opened: l.opened,
      overstock: l.overstock,
      location: l.location,
      desirability: l.desirability,
      notes: l.notes,
      _destroy: l.deleted,
    }));
};

const extractUsedSets = (buyLines: UsedSetBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      lego_set_id: l.lego_set.id,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      complete: l.complete,
      certifiable: l.certifiable,
      dirty: l.dirty,
      overstock: l.overstock,
      location: l.location,
      desirability: l.desirability,
      notes: l.notes,
      _destroy: l.deleted,
    }));
};

const extractMinifigs = (buyLines: MinifigBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      minifig_id: l.minifig?.id,
      description: l.description,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      condition: l.condition,
      overstock: l.overstock,
      location: l.location,
      notes: l.notes,
      _destroy: l.deleted,
    }));
};

const extractAnimals = (buyLines: AnimalBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      animal_id: l.animal?.id,
      description: l.description,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      condition: l.condition,
      overstock: l.overstock,
      location: l.location,
      notes: l.notes,
      _destroy: l.deleted,
    }));
};

const extractSeriesMinifigs = (buyLines: SeriesMinifigBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      series_minifig_id: l.series_minifig?.id,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      condition: l.condition,
      overstock: l.overstock,
      location: l.location,
      notes: l.notes,
      _destroy: l.deleted,
    }));
};

const extractBulk = (buyLines: BulkBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      dirty: l.dirty,
      overstock: l.overstock,
      location: l.location,
      notes: l.notes,
      description: l.description,
      non_lego: l.non_lego,
      premium_parts: l.premium_parts,
      minifigs: l.minifigs,
      _destroy: l.deleted,
    }));
};

const extractMisc = (buyLines: MiscBuyLineModel[], isNew: boolean) => {
  return buyLines
    .filter((l) => !isNew || (!l.deleted && isNew))
    .map((l) => ({
      id: isNew || l.id === 0 ? null : l.id,
      quantity: l.quantity,
      value: l.value,
      value_discount: l.value_discount,
      offer_discount: l.offer_discount,
      cost_final: l.cost_final,
      overstock: l.overstock,
      location: l.location,
      notes: l.notes,
      description: l.description,
      _destroy: l.deleted,
    }));
};

const extractCustomer = (customer: CustomerImportModel, heartlandId: number) => {
  return {
    id: customer.id,
    external_id: customer.external_id,
    heartland_id: heartlandId,
    first_name: customer.first_name,
    last_name: customer.last_name,
    email: customer.email,
    phone: customer.phone,
    address: {
      address_id: customer.address?.address_id,
      line_1: customer.address?.line_1,
      line_2: customer.address?.line_2,
      city: customer.address?.city,
      state: customer.address?.state,
      postal_code: customer.address?.postal_code,
      country: customer.address?.country,
    },
    store_credit_balance: customer.custom?.store_credit_balance,
    source: 'buy/trade',
    active: true,
  };
};

const transformBuy = (buy: BuyModel) => {
  const {
    id,
    total_nib_qty,
    total_nib_value,
    total_used_qty,
    total_used_value,
    total_misc_qty,
    total_misc_value,
    total_cmf_qty,
    total_cmf_value,
    total_minifig_qty,
    total_minifig_value,
    total_animal_qty,
    total_animal_value,
    total_bulk_qty,
    total_bulk_value,
    new_set_buy_lines,
    buy_store_credit_log,
    bulk_buy_lines,
    used_set_buy_lines,
    minifig_buy_lines,
    animal_buy_lines,
    misc_buy_lines,
    series_minifig_buy_lines,
    creator,
    customer,
    unit,
    notes,
    numZero,
    all_minifig_selected,
    all_animal_selected,
    all_new_set_selected,
    all_used_set_selected,
    all_bulk_selected,
    all_misc_selected,
    num_selected,
    ...newBuy
  } = buy;
  return newBuy;
};

const isNewBuy = (buy: BuyModel) => (buy.id > 0 ? false : true);

const getPayload = (buy: BuyModel) => {
  if (buy.unit.heartland_id) {
    const buyPayload = transformBuy(buy);
    const newBuy = isNewBuy(buy);
    const payload: {
      buy: { [k: string]: any };
      heartland_customer: { [k: string]: any };
    } = {
      buy: {
        id: newBuy ? null : buy.id,
        creator_id: buy.creator.id,
        unit_id: buy.unit.id,
        heartland_customer_id: buy.customer?.id,
        animal_buy_lines_attributes: extractAnimals(buy.animal_buy_lines, newBuy),
        series_minifig_buy_lines_attributes: extractSeriesMinifigs(
          buy.series_minifig_buy_lines,
          newBuy
        ),
        new_set_buy_lines_attributes: extractNewSets(buy.new_set_buy_lines, newBuy),
        minifig_buy_lines_attributes: extractMinifigs(buy.minifig_buy_lines, newBuy),
        used_set_buy_lines_attributes: extractUsedSets(buy.used_set_buy_lines, newBuy),
        bulk_buy_lines_attributes: extractBulk(buy.bulk_buy_lines, newBuy),
        misc_buy_lines_attributes: extractMisc(buy.misc_buy_lines, newBuy),
        ...buyPayload,
      },
      heartland_customer: {},
    };
    if (buy.customer) {
      payload.heartland_customer = extractCustomer(buy.customer, buy.unit.heartland_id);
    }
    return payload;
  }
  return null;
};

interface State {
  status: string;
  error: string;
  buy: BuyModel | null;
}

const defaultState = {
  status: api.idle,
  error: '',
  buy: null,
};

export const useSaveBuy = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const finalize = React.useCallback(
    async (buy: BuyModel) => {
      if (!buy.customer || !buy.unit.heartland_id) {
        setState((prevState) => ({
          ...prevState,
          error: 'A customer needs to be selected to save this buy',
          status: api.error,
        }));
        return;
      }
      if (buy.cash_paid === 0 && buy.credit_paid === 0) {
        setState((prevState) => ({
          ...prevState,
          error: 'A buy can not be completed without a cash or store credit paid out.',
          status: api.error,
        }));
        return;
      }
      setState((prevState) => ({ ...prevState, status: api.loading }));
      let resp: any;
      const payload = getPayload(buy);
      if (!payload) {
        setState((prevState) => ({
          ...prevState,
          error: 'A format error occurred trying to save the buy.',
          status: api.error,
        }));
        return;
      }
      resp = await api.post(`/api/buys/${buy.id}/finalize`, payload);
      if (resp.status === StatusCodes.OK) {
        setState({ error: '', status: api.success, buy: addKeys(resp.body) });
      } else {
        setState((prevState) => ({
          ...prevState,
          error: resp.body.error,
          status: api.error,
        }));
      }
    },
    [setState]
  );

  const complete = React.useCallback(
    async (buy: BuyModel) => {
      if (!buy.customer || !buy.unit.heartland_id) {
        setState((prevState) => ({
          ...prevState,
          error: 'A customer needs to be selected to save this buy',
          status: api.error,
        }));
        return;
      }
      if (buy.cash_paid === 0 && buy.credit_paid === 0) {
        setState((prevState) => ({
          ...prevState,
          error: 'A buy can not be completed without a cash or store credit paid out.',
          status: api.error,
        }));
        return;
      }
      setState((prevState) => ({ ...prevState, status: api.loading }));
      let resp: any;
      const payload = getPayload(buy);
      if (!payload) {
        setState((prevState) => ({
          ...prevState,
          error: 'A format error occurred trying to save the buy.',
          status: api.error,
        }));
        return;
      }
      resp = await api.post(`/api/buys/${buy.id}/complete`, payload);
      if (resp.status === StatusCodes.OK) {
        setState({ error: '', status: api.success, buy: addKeys(resp.body) });
      } else {
        setState((prevState) => ({
          ...prevState,
          error: resp.body.error,
          status: api.error,
        }));
      }
    },
    [setState]
  );

  const cancel = React.useCallback(async (buy: BuyModel, note: string) => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    let resp: any;
    resp = await api.post(`/api/buys/${buy.id}/cancel`, { note: note });
    if (resp.status === StatusCodes.OK) {
      setState({ error: '', status: api.success, buy: addKeys(resp.body) });
    } else {
      setState((prevState) => ({
        ...prevState,
        error: resp.body.error,
        status: api.error,
      }));
    }
  }, []);

  const save = React.useCallback(async (buy: BuyModel) => {
    if (!buy.unit.heartland_id) {
      setState((prevState) => ({
        ...prevState,
        error: 'A location needs to be selected to save this buy',
        status: api.error,
      }));
      return;
    }
    setState((prevState) => ({ ...prevState, status: api.loading }));
    let resp: any;
    const payload = getPayload(buy);
    if (!payload) {
      setState((prevState) => ({
        ...prevState,
        error: 'A format error occurred trying to save the buy.',
        status: api.error,
      }));
      return;
    }
    if (isNewBuy(buy)) {
      resp = await api.post(`/api/buys/`, payload);
    } else {
      resp = await api.put(`/api/buys/${buy.id}`, payload);
    }
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buy: addKeys(resp.body), error: '' });
    } else {
      setState((prevState) => ({
        ...prevState,
        error: resp.body.error,
        status: api.error,
      }));
    }
  }, []);

  const editComplete = React.useCallback(async (buy: BuyModel, editNote: string): Promise<void> => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    let resp: any;
    const payload = getPayload(buy);
    if (!payload) {
      setState((prevState) => ({
        ...prevState,
        error: 'A format error occurred trying to save the buy.',
        status: api.error,
      }));
      return;
    }

    const payloadWithNote: {
      buy: { [k: string]: any };
      heartland_customer: { [k: string]: any };
      note: string;
    } = {
      ...payload,
      note: editNote,
    };

    resp = await api.patch(`/api/buys/${buy.id}`, payloadWithNote);

    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buy: addKeys(resp.body), error: '' });
    } else {
      setState((prevState) => ({
        ...prevState,
        error: resp.body.error,
        status: api.error,
      }));
    }
  }, []);

  return {
    status: state.status,
    error: state.error,
    buy: state.buy,
    finalize,
    save,
    complete,
    cancel,
    editComplete,
  };
};
