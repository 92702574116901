import * as React from "react";

import LoginIcon from "@mui/icons-material/Login";
import { Button, Grid, Switch, Tooltip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";

import { useHeartlandAuth } from "api";
import { SettingsHeader } from "components";
import { api } from "lib";
import { HeartlandModel } from "model";

import { ConfirmOauthRevoke } from "./confirm_oauth_revoke";
import { ApiKey } from "./heartland_api_key";
import { OAuthAlert } from "./oauth_alert";

interface Props {
  heartland: HeartlandModel;
  onHeartlandChange: (heartland: HeartlandModel) => void;
}

export const HeartlandAuthenticationSettings: React.FC<Props> = ({
  heartland,
  onHeartlandChange,
}) => {
  const heartlandAuth = useHeartlandAuth();
  const [oauthAuthenticated, setOauthAuthenticated] = React.useState(heartland.oauth_authenticated);
  const [useOauth, setUseOauth] = React.useState(heartland.use_oauth);
  const [revokeOpen, setRevokeOpen] = React.useState(false);

  const flags = useFlags();

  const saveApiKey = (api_key: string) => {
    heartlandAuth.updateApiKey(heartland.id, api_key);
  };

  React.useEffect(() => {
    if (heartlandAuth.status === api.success && heartlandAuth.heartland) {
      onHeartlandChange({ ...heartlandAuth.heartland });
      heartlandAuth.reset();
    }
  }, [heartlandAuth.heartland]);

  const revokeOauth = () => {
    setOauthAuthenticated(false);
    heartlandAuth.revokeOauth(heartland.id);
  };

  const onSwitch = () => {
    const newOauth = !useOauth;
    setUseOauth(newOauth);
    heartlandAuth.switchAuthentication(heartland.id, newOauth);
  };

  const openRevokeModal = () => {
    setRevokeOpen(true);
  };

  return (
    <>
      <SettingsHeader
        headerText="Authentication"
        helpText="All settings relating to Patron's connection to Heartland"
      />
      <Grid container alignItems="center" sx={{ pt: "1em" }}>
        {flags.heartlandOauth && (
          <>
            <OAuthAlert />
            <Grid item xs={6}>
              {oauthAuthenticated ? (
                <Tooltip
                  placement="bottom"
                  title="To troubleshoot authetication, please contact tech support"
                >
                  <Button variant="contained" color="error" onClick={openRevokeModal}>
                    Revoke Heartland OAuth
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<LoginIcon />}
                  onClick={() => {
                    heartlandAuth.visitHeartland(heartland.id);
                  }}
                >
                  Connect To Heartland
                </Button>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <ApiKey apiKey={heartland.api_key} onSave={saveApiKey} />
        </Grid>
        {flags.heartlandOauth && (
          <>
            <Grid container item xs={12} alignItems="center" spacing={4}>
              <Grid item xs="auto">
                <strong>Authentication Type:</strong>
              </Grid>
              <Grid container item xs="auto" alignItems="center">
                <Grid item xs="auto">
                  Api Key
                </Grid>
                <Grid item xs="auto">
                  <Switch color="default" checked={useOauth} onClick={onSwitch} />
                </Grid>
                <Grid item xs="auto">
                  OAuth
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <ConfirmOauthRevoke
        open={revokeOpen}
        onClose={() => setRevokeOpen(false)}
        onRevokeOauth={revokeOauth}
      />
    </>
  );
};
