import { Address } from "model";

import { isPostalCode } from "./is_postal_code";

export const validateAddress = (address: Address) => {
  if (!address.line_1 && !address.city && !address.postal_code && !address.state) {
    return true;
  }

  if ((address.line_1?.length ?? 0) < 1) return false; 
  if ((address.city?.length ?? 0) < 1) return false;
  if (!address.postal_code || (address.postal_code && !isPostalCode(address.postal_code))) return false;
  if ((address.state?.length ?? 0) < 1) return false; 
  return true;
};
