import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Menu, MenuItem } from "@mui/material";

import { SessionContext, TSessionContext } from "../../lib";
import useStyles from "./styles";

export const AuditsMenu: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        className="menuItem"
        id="audits-button"
        aria-controls={open ? "audits-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Compliance & Fees
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="audits-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{ "aria-labelledby": "audits-button" }}
      >
        <MenuItem component={"a"} href="/royalties" onClick={handleClose}>
          Royalties
        </MenuItem>
        <MenuItem component={"a"} href="/audits?audit_type=department" onClick={handleClose}>
          Department
        </MenuItem>
      </Menu>
    </>
  );
};
