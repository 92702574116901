import * as React from "react";

import { Grid } from "@mui/material";

interface ReadOnlyRowProps {
  children: React.ReactNode;
}

export const ReadOnlyRow: React.FC<ReadOnlyRowProps> = ({ children }: ReadOnlyRowProps) => {
  return (
    <Grid container spacing={1} alignItems={"center"} sx={{ my: 0.75 }}>
      <Grid item xs={9}></Grid>
      <Grid item xs={3}>
        {children}
      </Grid>
    </Grid>
  );
};
