import * as React from "react";

import SyncIcon from "@mui/icons-material/Sync";
import { Button, Grid, Link, TextField } from "@mui/material";

import { useSyncCustomer } from "api";
import { InfoBar } from "components";
import { SessionContext, TSessionContext, api } from "lib";
import { CustomerModel, InfoBarModel } from "model";

import { CustomerAddress } from "./customer_address";

interface Props {
  customer: CustomerModel;
  onCustomerChange: (customer: CustomerModel) => void;
}

export const CustomerInfo: React.FC<Props> = ({ customer, onCustomerChange }) => {
  const { isAdmin, currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const syncCustomer = useSyncCustomer();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  React.useEffect(() => {
    if (syncCustomer.status === api.success && syncCustomer.customer) {
      onCustomerChange(syncCustomer.customer);
      setInfoBar({ status: "success", message: "Customer Synced" });
    } else if (syncCustomer.status === api.error) {
      setInfoBar({ status: "error", message: syncCustomer.error });
    } else {
      setInfoBar(null);
    }
  }, [syncCustomer]);

  const handleSync = () => {
    if (customer.id) syncCustomer.sync(customer.id);
  };

  const clearInfoBar = () => {
    setInfoBar(null);
    syncCustomer.reset();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="id"
                label="Patron ID"
                variant="outlined"
                fullWidth
                value={customer.id}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="external_id"
                label="Heartland ID"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.external_id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="first_name"
                label="First Name"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.first_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="last_name"
                label="Last Name"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.last_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="phone"
                label="Phone Number"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="store_credit_balance"
                label="Store Credit Balance"
                variant="outlined"
                fullWidth
                InputProps={{ readOnly: true }}
                value={customer.store_credit_balance}
              />
            </Grid>
            {isAdmin() && (
              <Grid item xs={12} md={12}>
                <h3>Associated Units</h3>
                <Grid container spacing={2}>
                  {customer.units &&
                    customer.units.map((u) => (
                      <Grid item key={u.id} xs={6}>
                        <Link href={`/units/${u.id}`}>{u.name}</Link>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Sync with Heartland"
                startIcon={<SyncIcon />}
                onClick={handleSync}
                disabled={syncCustomer.status === api.loading}
                fullWidth
              >
                Sync with Heartland
              </Button>
            </Grid>
            {currentHeartland && customer.heartland_id && (
              <>
                <div className="info">
                  Heartland ID:{" "}
                  <Link
                    href={`https://${currentHeartland.domain}.retail.heartland.us/#customers/edit/${customer.heartland_id}`}
                  >
                    {customer.heartland_id}
                  </Link>
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <CustomerAddress customer={customer} />
        <Grid item xs={12} md={12}>
          {infoBar && (
            <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
