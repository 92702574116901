import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RoyaltyFeeContext, TRoyaltyFeeContext } from "contexts";

import { CurrencyInput, InfoBar } from "components";
import { SessionContext, TSessionContext, api, formatCurrency, formatPercentage } from "lib";
import { InfoBarModel } from "model";

import { InputRow } from "./input_row";
import { PosSales } from "./pos_sales";
import { ReadOnlyRow } from "./read_only_row";

const readOnlyStyle = { background: "#f0f0f0", textAlign: "right" };

interface InputRowProps {
  summary: string;
  details: React.ReactNode | string;
  children: React.ReactNode;
}

export const RoyaltyFeeForm: React.FC = () => {
  const {
    royaltyFee,
    actionStatus,
    update,
    addNote,
    save,
    submit,
    resubmit,
    startReview,
    approve,
    reject,
    resetAction,
  } = React.useContext(RoyaltyFeeContext) as TRoyaltyFeeContext;
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [newNote, setNewNote] = React.useState<string>("");
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (actionStatus.status === api.success) {
      setInfoBar({ status: "success", message: `${actionStatus.action} successful` });
      if (actionStatus.action === "add_note") {
        setNewNote("");
      }
      resetAction();
    } else if (actionStatus.status === api.error) {
      setInfoBar({ status: "error", message: actionStatus.error });
      resetAction();
    }
  }, [actionStatus.status]);

  const totalSales = React.useMemo(() => {
    return (
      royaltyFee.reported_store_sales + royaltyFee.other_sales + royaltyFee.additional_online_sales
    );
  }, [royaltyFee]);

  const netSales = React.useMemo(() => {
    return (
      royaltyFee.reported_store_sales +
      royaltyFee.other_sales +
      royaltyFee.additional_online_sales -
      royaltyFee.inter_store_sales
    );
  }, [royaltyFee]);

  const calculatedRoyalty = React.useMemo(() => {
    const fee = netSales * royaltyFee.royalty_rate;
    return fee > royaltyFee.minimum_royalty_fee ? fee : royaltyFee.minimum_royalty_fee;
  }, [royaltyFee]);

  const calculatedAdvertisingFee = React.useMemo(() => {
    const fee = netSales * royaltyFee.advertising_fee_rate;
    return royaltyFee.advertising_default_fee > 0 ? royaltyFee.advertising_default_fee : fee;
  }, [royaltyFee]);

  const calculatedTotalFees = React.useMemo(() => {
    return (
      calculatedRoyalty +
      calculatedAdvertisingFee +
      royaltyFee.tech_fee +
      royaltyFee.extra_tech_fee +
      royaltyFee.late_fee
    );
  }, [royaltyFee]);

  const status = React.useMemo(() => {
    switch (royaltyFee.status) {
      case "submitted":
        return "WAITING FOR REVIEW";
      case "in_review":
        return "IN REVIEW";
      case "approved":
        return "APPROVED";
      case "pending":
        return "UNSUBMITTED";
      case "rejected":
        return "CORRECTIONS REQUESTED";
    }
  }, [royaltyFee]);

  const calculatedMarketingTotalSpend = React.useMemo(() => {
    return (
      royaltyFee.marketing_alternative_spend +
      royaltyFee.marketing_digital_spend +
      royaltyFee.marketing_event_spend +
      royaltyFee.marketing_guerilla_spend +
      royaltyFee.marketing_in_store_spend +
      royaltyFee.marketing_outdoor_spend +
      royaltyFee.marketing_print_spend +
      royaltyFee.marketing_social_spend +
      royaltyFee.marketing_traditional_spend
    );
  }, [royaltyFee]);

  const isLoading = React.useMemo(() => actionStatus.status === api.loading, [actionStatus]);

  const statusStyle = React.useMemo(() => {
    if (royaltyFee.status === "pending" || royaltyFee.status === "rejected") {
      return { ...readOnlyStyle, background: "#f5b7b1" };
    } else if (royaltyFee.status === "approved") {
      return { ...readOnlyStyle, background: "#d4efdf" };
    }
    return readOnlyStyle;
  }, [royaltyFee.status]);

  const renderAdminButtons = () => {
    if (royaltyFee.status === "pending") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={submit} disabled={isLoading}>
            Submit
          </Button>
        </Stack>
      );
    } else if (royaltyFee.status === "rejected") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={resubmit} disabled={isLoading}>
            Resubmit
          </Button>
        </Stack>
      );
    } else if (royaltyFee.status === "submitted") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={startReview} disabled={isLoading}>
            Start Review
          </Button>
        </Stack>
      );
    } else if (royaltyFee.status === "in_review") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={reject} disabled={isLoading}>
            Reject
          </Button>
          <Button variant="contained" onClick={approve} disabled={isLoading}>
            Approve
          </Button>
        </Stack>
      );
    }
    return <></>;
  };

  const renderOwnerButtons = () => {
    if (royaltyFee.status === "pending") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={submit} disabled={isLoading}>
            Submit
          </Button>
        </Stack>
      );
    } else if (royaltyFee.status === "rejected") {
      return (
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
          <Button variant="contained" onClick={resubmit} disabled={isLoading}>
            Resubmit
          </Button>
        </Stack>
      );
    } else if (royaltyFee.status === "submitted") {
      return (
        <>
          <Button variant="contained" onClick={save} disabled={isLoading}>
            Save
          </Button>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid container columnSpacing={12} sx={{ my: 2 }} alignItems="flext-start">
        {infoBar && (
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        )}
        <Grid container columnSpacing={2} rowSpacing={1} item xs={9} alignItems="center">
          <Grid item xs={2}>
            <TextField
              id="date"
              label="Worksheet For"
              variant="outlined"
              fullWidth
              value={`${royaltyFee.month}, ${royaltyFee.year}`}
              inputProps={{ readOnly: true, sx: readOnlyStyle }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="status"
              label="Status"
              variant="outlined"
              fullWidth
              value={status}
              inputProps={{ readOnly: true, sx: statusStyle }}
            />
          </Grid>
          {isAdmin() && (
            <>
              <Grid item xs={2.5}>
                <PosSales unitId={royaltyFee.unit.id} date={royaltyFee.date} />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="go_date"
                  label="GO Date"
                  variant="outlined"
                  fullWidth
                  value={royaltyFee.unit.grand_open_date || "Unavailable"}
                  inputProps={{ readOnly: true, sx: readOnlyStyle }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <InputRow
              summary="POS Revenue"
              details={
                <>
                  Derived from POS Back End Reporting. Use the <strong>"Net Sales"</strong>revenue
                  figure.
                </>
              }
            >
              <CurrencyInput
                value={royaltyFee.reported_store_sales}
                label={"POS Revenue"}
                fullWidth
                size="medium"
                error={royaltyFee.reported_store_sales === 0}
                onChange={(e) => {
                  update("reported_store_sales", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow
              summary="Other Online Revenue"
              details={
                <>
                  Total amount for the month for all online sales (not already reported through the
                  POS above), include shipping fees that have a markup, but EXCLUDING any sales tax
                  collected or shipping costs with no markup. Input the amount in the "Online Sales
                  Input Cell" on this spreadsheet. This figure includes eCommerce sales, as well as
                  ebay, bricklink, etc.
                </>
              }
            >
              <CurrencyInput
                value={royaltyFee.additional_online_sales}
                label={"Other Online Revenue"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("additional_online_sales", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow
              summary="All Other Revenue"
              details={
                <>
                  Total amount for the month for all other sales derived from any other sales
                  process not already included or reported in either In-Store or Online Sales above.
                  Such sales would be those derived from events held outside of a Bricks & Minifigs
                  store location. Examples include events sponsored by schools, clubs, companies,
                  not-for-profit organizations, Comic-cons, fairs and shows, etc. Sales shipping
                  fees that include a markup, but EXCLUDING any sales tax collected or shipping
                  costs with no markup. Input the amount in the "All Other Sales Input Cell" on this
                  spreadsheet.
                </>
              }
            >
              <CurrencyInput
                value={royaltyFee.other_sales}
                label={"All Other Revenue"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("other_sales", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <ReadOnlyRow>
              <TextField
                id="total_sales"
                label="Gross Revenue"
                variant="outlined"
                fullWidth
                value={formatCurrency(totalSales)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
            </ReadOnlyRow>
            <InputRow
              summary="Reduction for Inter-Store Sales"
              details={
                <>
                  Total amount for the month for any sale of product from one store to another for
                  business resale purposes, where that product will ultimately be sold to a
                  customer. This excludes inter-store sales for personal or gift buying purposes.
                  Input the amount in the "Inter-Store Sales Input Cell."
                </>
              }
            >
              <CurrencyInput
                value={royaltyFee.inter_store_sales}
                label={"Inter-Store Sales"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("inter_store_sales", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <ReadOnlyRow>
              <TextField
                id="net_sales"
                label="Net Revenue"
                variant="outlined"
                fullWidth
                value={formatCurrency(netSales)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
            </ReadOnlyRow>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Fees Summary</Typography>
              <TextField
                id="royalty_rate"
                label="Royalty Rate"
                variant="outlined"
                fullWidth
                value={formatPercentage(royaltyFee.royalty_rate)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="calculated_royalty"
                label="Calculated Royalty"
                variant="outlined"
                fullWidth
                value={formatCurrency(calculatedRoyalty)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="advertising_fee"
                label={`Advertising Fee ${formatPercentage(royaltyFee.advertising_fee_rate)}`}
                variant="outlined"
                fullWidth
                value={formatCurrency(calculatedAdvertisingFee)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="tech_fee"
                label="Technology Fee"
                variant="outlined"
                fullWidth
                value={formatCurrency(royaltyFee.tech_fee)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="extra_tech_fee"
                label="Additional Tech Fee"
                variant="outlined"
                fullWidth
                value={formatCurrency(royaltyFee.extra_tech_fee)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="late_fee"
                label="Late Fees"
                variant="outlined"
                fullWidth
                value={formatCurrency(royaltyFee.late_fee)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="total_fee"
                label="Total Royaly & Fees"
                variant="outlined"
                fullWidth
                value={formatCurrency(calculatedTotalFees)}
                inputProps={{ readOnly: true, sx: { ...readOnlyStyle, background: "#d6eaf8" } }}
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Grid container columnSpacing={12} sx={{ my: 2 }} alignItems="flext-start">
        <Grid item xs={12}>
          <Typography variant="h6">Reported Marketing Spend</Typography>
        </Grid>
        <Grid container columnSpacing={2} rowSpacing={1} item xs={9} alignItems="center">
          <Grid item xs={12}>
            <InputRow summary="Print" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_print_spend}
                label={"Print"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_print_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Digital" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_digital_spend}
                label={"Digital"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_digital_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Social" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_social_spend}
                label={"Social"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_social_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Traditional (Radio/TV)" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_traditional_spend}
                label={"Traditional"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_traditional_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="In Store" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_in_store_spend}
                label={"In Store"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_in_store_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Outdoor" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_outdoor_spend}
                label={"Outdoor"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_outdoor_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Event" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_event_spend}
                label={"Event"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_event_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Alternative" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_alternative_spend}
                label={"alternative"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_alternative_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
            <InputRow summary="Guerilla" details={<></>}>
              <CurrencyInput
                value={royaltyFee.marketing_guerilla_spend}
                label={"Guerilla"}
                fullWidth
                size="medium"
                onChange={(e) => {
                  update("marketing_guerilla_spend", parseFloat(e.target.value) || 0);
                }}
              />
            </InputRow>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Marketing Spend Summary</Typography>
              <TextField
                id="marketing_total_spend"
                label="Marketing Total Spend"
                variant="outlined"
                fullWidth
                value={formatCurrency(calculatedMarketingTotalSpend)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="target_acutal"
                label="FA % Marketing Actual"
                variant="outlined"
                fullWidth
                error={calculatedMarketingTotalSpend / netSales < 0.03}
                value={formatPercentage(calculatedMarketingTotalSpend / netSales)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
              <TextField
                id="advertising_fee"
                label="FA % Marketing Target"
                variant="outlined"
                fullWidth
                value={formatPercentage(0.03)}
                inputProps={{ readOnly: true, sx: readOnlyStyle }}
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Accordion sx={{ my: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="notes-content"
          id="notes-header"
        >
          <strong>Notes</strong>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{royaltyFee.notes}</pre>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={10}>
              <TextField
                id="addNote"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                onClick={() => {
                  addNote(newNote);
                }}
                variant="contained"
              >
                Add Note
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container justifyContent="center">
        {isAdmin() ? renderAdminButtons() : renderOwnerButtons()}
      </Grid>
    </>
  );
};
