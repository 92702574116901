import * as React from "react";

import { Box, Grid, Switch, TextField } from "@mui/material";

import { CurrencyInput } from "components";
import { MinifigPriceBand } from "model";

export interface MinifigPriceBandEntryProps {
  priceBand: MinifigPriceBand;
  updatePriceBand(property: string, value: number | string | boolean): void;
}

export const MinifigPriceBandEntry: React.FC<MinifigPriceBandEntryProps> = ({
  priceBand,
  updatePriceBand,
}: MinifigPriceBandEntryProps) => {
  const update = (prop: string, value: number | string | boolean) => {
    updatePriceBand(prop, value);
  };

  return (
    <>
      <Grid item xs={4.3}>
        <TextField
          size="small"
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.name}
          onChange={(e) => update("name", e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <CurrencyInput
          label="Value"
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.value}
          onChange={(e) => update("value", e.target.value)}
        />
      </Grid>
      <Grid item xs="auto">
        <Switch
          checked={priceBand.active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => update("active", e.target.checked)}
        />
      </Grid>
      <Box sx={{ width: "100%" }} />
    </>
  );
};
