import * as React from "react";

import { Box, Grid, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";

import { useUnitsList } from "api";
import { useBuysList } from "api/buy";
import { InfoBar } from "components";
import { BuyColumns, Page, UnitAutocomplete } from "components";
import { api } from "lib";
import { UnitModel } from "model";

interface Props {
  status: string;
  unitFilter: UnitModel | null;
}

const BuysTable = ({ status, unitFilter }: Props) => {
  const { buysList, listBuys } = useBuysList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    listBuys(
      status,
      paginationModel.page,
      paginationModel.pageSize,
      unitFilter,
      status === "completed" ? "completed_at" : "updated_at"
    );
  }, [paginationModel, listBuys, status, unitFilter]);

  if (buysList.status === api.error) {
    return <InfoBar status="error" message={buysList.error} />;
  }
  return (
    <DataGrid
      rows={buysList.buys}
      columns={BuyColumns(status)}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      loading={buysList.status === api.loading}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      rowCount={buysList.total}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      paginationMode="server"
    />
  );
};

export const BuysList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = React.useState<string>(() => searchParams.get("status") || "active");

  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(null);
  const { units, list: getUnits } = useUnitsList();

  React.useEffect(() => {
    setStatus(searchParams.get("status") || "active");
  }, [searchParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ status: newValue });
  };

  React.useEffect(() => {
    getUnits();
  }, [getUnits]);

  return (
    <Page
      title={
        <Grid container gap={4} alignItems="center">
          <Grid item>
            <Typography variant="h5">Buys</Typography>
          </Grid>
          {units.length > 1 && (
            <Grid item>
              <UnitAutocomplete units={units} onSelect={setSelectedUnit} value={selectedUnit} />
            </Grid>
          )}
        </Grid>
      }
    >
      <Tabs onChange={handleChange} value={status} aria-label="Buys" sx={{ mb: 2, mt: 2 }}>
        <Tab label="Active" value="active" sx={{ px: 8 }} />
        <Tab label="Pending" value="pending" sx={{ px: 8 }} />
        <Tab label="Complete" value="completed" sx={{ px: 8 }} />
        <Tab label="Cancelled" value="cancelled" sx={{ px: 8 }} />
      </Tabs>
      <BuysTable status={status} unitFilter={selectedUnit} />
    </Page>
  );
};
