import * as React from "react";

import { Box, LinearProgress, Link, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { useGetLegoSet } from "api";
import { NotFound, Page, TitleLink } from "components";
import { api } from "lib";
import { SessionContext, TSessionContext } from "lib";

import { LegoSetProvider } from "contexts";
import { Details, MyStoreInfo, PriceGuide } from "./components";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const LegoSetShow: React.FC = () => {
  const { isEmployee, isOwner } = React.useContext(SessionContext) as TSessionContext;

  const { getLegoSetState, getLegoSet } = useGetLegoSet();
  const { bricklink_id } = useParams();

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newTab: number) => setTab(newTab);

  React.useEffect(() => {
    if (bricklink_id) {
      getLegoSet(bricklink_id);
    }
  }, [bricklink_id, getLegoSet]);

  if (getLegoSetState.status === api.success && getLegoSetState.legoSet) {
    return (
      <Page
        title={
          <TitleLink
            link="/catalog"
            linkText="Catalog"
            postText={`${getLegoSetState.legoSet.set_number} ${getLegoSetState.legoSet.name}`}
          />
        }
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleChange} aria-label="Heartland Tabs">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Price Guide" {...a11yProps(1)} />
            {(isEmployee() || isOwner()) && <Tab label="@ My Store" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <LegoSetProvider legoSet={getLegoSetState.legoSet}>
          <>
            <Box hidden={tab !== 0} id="tabpanel-0">
              <Details />
            </Box>
            <Box hidden={tab !== 1} id="tabpanel-1">
              <PriceGuide />
            </Box>
            <Box hidden={tab !== 2} id="tabpanel-2">
              <MyStoreInfo />
            </Box>
          </>
        </LegoSetProvider>
      </Page>
    );
  } else if (getLegoSetState.status === api.error) {
    return (
      <div style={{ width: 800, margin: "0 auto", textAlign: "center" }}>
        <NotFound />
        <h4>
          <Link href="/catalog">Return to Catalog</Link>
        </h4>
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: 32, width: 800 }}>
        <LinearProgress />
      </div>
    );
  }
};
