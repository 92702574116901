import * as React from "react";

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useHeartlandsList } from "api";
import { api } from "lib";
import { HeartlandModel } from "model";

interface Props {
  value: HeartlandModel | undefined;
  readOnly?: boolean;
  onSelect?: (heartland: HeartlandModel) => void;
}

export const HeartlandSelector: React.FC<Props> = ({
  value,
  onSelect,
  readOnly = false,
}: Props) => {
  const { heartlands, status, list } = useHeartlandsList();
  const [selectedHeartland, setSelectedHeartland] = React.useState<HeartlandModel | undefined>(
    value
  );

  const handleChange = (event: SelectChangeEvent) => {
    const heartlandId: number = +event.target.value;
    const heartland = heartlands.find((u) => u.id === heartlandId);
    if (heartland) {
      setSelectedHeartland(heartland);
      if (onSelect) {
        onSelect(heartland);
      }
    }
  };

  React.useEffect(() => {
    list();
  }, []);

  if (status === api.error) {
    return <div>Unable to load heartland instances</div>;
  } else if (status === api.loading) {
    return (
      <div style={{ width: 800 }}>
        <CircularProgress />
      </div>
    );
  } else if (status === api.success && heartlands) {
    return (
      <FormControl fullWidth>
        <InputLabel id="heartland-select-label">Heartland</InputLabel>
        <Select
          labelId="heartland-select-label"
          id="heartland"
          value={`${selectedHeartland ? selectedHeartland.id : 0}`}
          onChange={handleChange}
          inputProps={{ readOnly: readOnly }}
          label="Heartland"
        >
          <MenuItem value={0}>------------------</MenuItem>
          {heartlands.map((heartland: HeartlandModel) => (
            <MenuItem key={heartland.id} value={`${heartland.id}`}>
              {heartland.domain}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return <>{status}</>;
  }
};
