import * as React from "react";

import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useBuysList } from "api";
import { BuyColumns, InfoBar } from "components";
import { api } from "lib";
import { CustomerModel } from "model";

interface Props {
  customer: CustomerModel;
}

export const CustomerTradeHistory: React.FC<Props> = ({ customer }) => {
  const { buysList, listBuysByCustomer } = useBuysList();

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    if (customer.id) {
      listBuysByCustomer(customer.id, paginationModel.page, paginationModel.pageSize);
    }
  }, [customer, paginationModel, listBuysByCustomer]);

  return (
    <Grid item xs={12} md={12}>
      <h3>Buy/Trade History</h3>
      {buysList.status === api.error && <InfoBar status="error" message={buysList.error} />}
      <Box sx={{ pt: 2, fontSize: "0.9rem" }}>
        <DataGrid
          rows={buysList.buys}
          columns={BuyColumns("active")}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          loading={buysList.status === api.loading}
          rowCount={buysList.total}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          paginationMode="server"
        />
      </Box>
    </Grid>
  );
};
