import * as React from "react";

import { Box, LinearProgress, Link, Tab, Tabs } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams, useSearchParams } from "react-router-dom";

import { useGetHeartland } from "api";
import { AuditSearchList, NotFound, Page, RewardsFormTab, TitleLink } from "components";
import { api } from "lib";

import { CustomFieldsList, HeartlandSettingsTab } from "./components";
import { UnitsTab } from "./components/units_tab";

enum heartlandTab {
  settings = "settings",
  units = "units",
  rewards = "rewards",
  custom_fields = "custom-fields",
  audits = "audits",
}

function tabMap(index: number) {
  switch (index) {
    case 0:
      return heartlandTab.settings;
    case 1:
      return heartlandTab.units;
    case 2:
      return heartlandTab.rewards;
    case 3:
      return heartlandTab.custom_fields;
    case 4:
      return heartlandTab.audits;
    default:
      return heartlandTab.settings;
  }
}

function a11yProps(index: string) {
  return {
    id: `heartland-tab-${index}`,
    "aria-controls": `heartland-tabpanel-${index}`,
  };
}

export const HeartlandShow: React.FC = () => {
  const flags = useFlags();
  const { getHeartland } = useGetHeartland();
  const { heartland_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = React.useState(() => {
    return searchParams.get("tab") || heartlandTab.settings;
  });

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    const tabName = tabMap(newTab);
    setSearchParams({ tab: tabName });
    setTab(tabName);
  };

  const heartland = React.useMemo(() => getHeartland.heartland, [getHeartland]);

  const customFields = React.useMemo(() => {
    if (heartland && heartland.customer_custom_fields && heartland.item_custom_fields) {
      return heartland.customer_custom_fields?.concat(heartland.item_custom_fields);
    }
    return [];
  }, [heartland]);

  React.useEffect(() => {
    if (heartland_id) {
      getHeartland.byId(parseInt(heartland_id));
    }
  }, [heartland_id, getHeartland.byId]);

  if (getHeartland.status === api.loading) {
    return (
      <div style={{ margin: "32px 0", width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (getHeartland.status === api.success && heartland) {
    return (
      <Page
        title={<TitleLink link="/heartlands" linkText="Heartlands" postText={heartland.domain} />}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleChange} aria-label="Heartland Tabs">
            <Tab label="Settings" {...a11yProps(heartlandTab.settings)} />
            <Tab label="Units" {...a11yProps(heartlandTab.units)} />
            <Tab label="Rewards Signup Form" {...a11yProps(heartlandTab.rewards)} />
            <Tab label="Custom Fields" {...a11yProps("custom-fields")} />
            {flags.audit && <Tab label="Audits" {...a11yProps("audits")} />}
          </Tabs>
        </Box>
        {tab === heartlandTab.settings && (
          <Box role="tabpanel" id="heartland-tabpanel-0" aria-labelledby="heartland-tab-panel-0">
            <HeartlandSettingsTab heartland={heartland} />
          </Box>
        )}
        {tab === heartlandTab.units && (
          <Box role="tabpanel" id="heartland-tabpanel-1" aria-labelledby="heartland-tab-panel-1">
            <UnitsTab heartland={heartland} />
          </Box>
        )}
        {tab === heartlandTab.rewards && (
          <Box role="tabpanel" id="heartland-tabpanel-2" aria-labelledby="heartland-tab-panel-2">
            <RewardsFormTab
              rewardsSignupForm={heartland.rewards_signup_form}
              customFields={heartland.customer_custom_fields || []}
            />
          </Box>
        )}
        {tab === heartlandTab.custom_fields && (
          <Box role="tabpanel" id="heartland-tabpanel-3" aria-labelledby="heartland-tab-panel-3">
            <CustomFieldsList heartlandId={heartland.id} fields={customFields || []} />
          </Box>
        )}
        {tab === heartlandTab.audits && (
          <Box
            role="tabpanel"
            hidden={tab !== heartlandTab.audits}
            id="heartland-tabpanel-4"
            aria-labelledby="heartland-tab-panel-4"
          >
            <AuditSearchList inputHeartlandId={Number(heartland_id)} resultCount={5} />
          </Box>
        )}
      </Page>
    );
  } else if (getHeartland.status === api.error) {
    return <NotFound />;
  }
  return <></>;
};
