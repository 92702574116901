import * as React from "react";

import { Box, Typography, TypographyProps } from "@mui/material";

interface Props {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  title_variant?: TypographyProps["variant"];
  title_margin?: number | string;
  width?: string;
}

export const Page: React.FC<Props> = ({ title, children, title_variant, title_margin, width }) => {
  return (
    <>
      <Box width={width || "90%"} sx={{ py: 2 }}>
        {title && typeof title == "string" ? (
          <Typography variant={title_variant || "h5"} sx={{ mb: title_margin || 2 }}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {children}
      </Box>
    </>
  );
};
