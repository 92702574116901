import * as React from 'react';

import { Link } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useMonthlyBuysByUnits } from 'api';
import { Page } from 'components';
import { api, formatCurrency } from 'lib';

const columns: GridColDef[] = [
  {
    field: 'unit',
    headerName: 'Unit',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Link href={`/units/${params.row.unit_id}`} title={params.row.unit_name}>
        {params.row.unit_name}
      </Link>
    ),
    minWidth: 150,
  },
  {
    field: 'completed',
    type: 'number',
    headerName: 'Completed',
  },
  {
    field: 'cancelled',
    type: 'number',
    headerName: 'Cancelled',
  },
  {
    field: 'cash_count',
    type: 'number',
    headerName: 'Cash Buys',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.cash_count} / {formatCurrency(params.row.cash_paid)}
      </>
    ),
  },
  {
    field: 'credit_count',
    type: 'number',
    headerName: 'Credit Buys',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.credit_count} / {formatCurrency(params.row.credit_paid)}
      </>
    ),
  },
  {
    field: 'new_sets',
    type: 'number',
    headerName: 'New Sets',
    width: 90,
  },
  {
    field: 'used_sets',
    type: 'number',
    headerName: 'Used Sets',
    width: 90,
  },
  {
    field: 'minifigs',
    type: 'number',
    headerName: 'Minifigs',
    width: 90,
  },
  {
    field: 'animals',
    type: 'number',
    headerName: 'Animals',
    width: 90,
  },
  {
    field: 'bulk',
    type: 'number',
    headerName: 'Bulk',
    renderCell: (params: GridRenderCellParams<any, string>) => <>{params.row.bulk}g</>,
    width: 90,
  },
  {
    field: 'misc',
    type: 'number',
    headerName: 'Misc',
    width: 90,
  },
];

export const MonthlyReportsByUnits: React.FC = () => {
  const { monthlyBuysByUnits, getMonthlyBuysByUnit } = useMonthlyBuysByUnits();

  React.useEffect(() => {
    getMonthlyBuysByUnit(2024);
  }, [getMonthlyBuysByUnit]);

  return (
    <Page title="Month to Date Buys">
      <DataGrid
        rows={monthlyBuysByUnits.buys}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        loading={monthlyBuysByUnits.status === api.loading}
        disableRowSelectionOnClick
        paginationMode="server"
      />
    </Page>
  );
};
