import * as React from 'react';
import { Box, Modal } from '@mui/material';
import { style } from './styles';
import { ForgotPasswordContext, ForgotPasswordState, TForgotPasswordModalContext } from 'contexts';
import { RequestForm } from './request_form';
import { VerifyCodeForm } from './verify_code_form';
import { ResetPasswordForm } from './reset_password_form';

export const ForgotPasswordModal: React.FC = () => {
  const { isOpen, state, hideForgotPassword } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;

  return (
    <Modal
      open={isOpen}
      onClose={() => hideForgotPassword()}
      aria-labelledby="Forgot Password"
      aria-describedby="Forgot Password Dialog"
    >
      <Box sx={style}>
        <div style={{ margin: '48px auto', textAlign: 'center', width: 600 }}>
          {state === ForgotPasswordState.request && <RequestForm />}
          {state === ForgotPasswordState.verify && <VerifyCodeForm />}
          {state === ForgotPasswordState.reset && <ResetPasswordForm />}
        </div>
      </Box>
    </Modal>
  );
};