import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { UnitModel } from "model";

interface State {
  status: string;
  error: string;
  unit: UnitModel | null;
}

interface Props {
  updateUnit: State & {
    update: (unit: UnitModel) => void;
  };
}

const defaultState = {
  status: api.idle,
  error: "",
  unit: null,
};

export const useUpdateUnit = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const update = React.useCallback(async (updatedUnit: UnitModel) => {
    const { id, heartland, tags, owner, ...unitProps } = updatedUnit;

    setState({ status: api.loading, unit: null, error: "" });
    const payload = {
      unit: {
        ...unitProps,
        unit_tags: tags,
        owner_id: owner.id,
        heartland_id: heartland ? heartland.id : null,
      },
    };
    const resp = await api.put(`/api/units/${updatedUnit.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, unit: resp.body, error: "" });
    } else {
      setState({ status: api.error, unit: null, error: resp.body.error });
    }
  }, []);

  return {
    updateUnit: {
      ...state,
      update,
    },
  };
};
