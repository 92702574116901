import * as React from "react";

import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";

import { api } from "lib";
import { HeartlandModel } from "model";

interface State {
  status: string;
  error: string;
  heartland: HeartlandModel | null;
}

interface Props extends State {
  visitHeartland: (heartland_id: number) => void;
  revokeOauth: (heartland_id: number) => void;
  storeAccessCode: (state: string, authorization_code: string) => void;
  reset: () => void;
  updateApiKey: (id: number, apiKey: string) => void;
  switchAuthentication: (heartland_id: number, use_oauth: boolean) => void;
}

const defaultState = {
  status: api.idle,
  error: "",
  heartland: null,
};

// Used to generate the URL for a specific Heartland instance and direct the user to the correct heartland login page.
export const useHeartlandAuth = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);
  const navigate = useNavigate();

  const visitHeartland = React.useCallback(async (heartland_id: number) => {
    setState({ ...state, status: api.loading });
    const resp = await api.get(`/api/oauth/heartland/${heartland_id}/redirect`);

    if (resp.status === StatusCodes.OK) {
      window.location.href = resp.body.heartland_url;
    } else {
      setState({ ...state, status: api.error, error: resp.body.message });
    }
  }, []);

  const revokeOauth = React.useCallback(async (heartland_id: number) => {
    setState({ ...state, status: api.loading });

    const resp = await api.delete(`/api/oauth/heartland/${heartland_id}`);

    if (resp.status === StatusCodes.OK) {
      setState({ ...state, status: api.success, heartland: resp.body });
    } else {
      setState({ ...state, status: api.error, error: resp.body.message });
    }
  }, []);

  const storeAccessCode = React.useCallback(
    async (oauth_state: string, authorization_code: string) => {
      setState({ ...state, status: api.loading });

      const results = await api.post("/api/oauth/heartland", {
        state: oauth_state,
        authorization_code: authorization_code,
      });

      if (results.status == StatusCodes.OK) {
        navigate(`/heartlands/${results.body.heartland_id}`, {
          state: {
            auth_message:
              "Successfully connected to Heartland, make sure to turn switch to using oAuth below",
            auth_success: true,
            auth_display: true,
          },
          replace: true,
        });
      } else {
        navigate(`/heartlands/${results.body.heartland_id}`, {
          state: {
            auth_message:
              "Error on our end regarding storing the authentication token. Please reach out to technical support",
            auth_success: false,
            auth_display: true,
          },
          replace: true,
        });
      }
    },
    []
  );

  const updateApiKey = React.useCallback(async (heartland_id: number, apiKey: string) => {
    setState({ ...state, status: api.loading });
    const payload = {
      heartland: {
        api_key: apiKey,
      },
    };
    const resp = await api.put(`/api/heartlands/${heartland_id}`, payload);
    if (resp.status === StatusCodes.OK) {
      const updatedHeartland: HeartlandModel = resp.body.heartland;
      updatedHeartland.rewards_signup_form = resp.body.rewards_signup_form;
      setState((previousState) => ({
        ...previousState,
        status: api.success,
        heartland: updatedHeartland,
      }));
    } else {
      setState((previousState) => ({
        ...previousState,
        status: api.error,
        error: resp.body.message,
      }));
    }
  }, []);

  const switchAuthentication = React.useCallback(
    async (heartland_id: number, use_oauth: boolean) => {
      const payload = {
        heartland: {
          use_oauth: use_oauth,
        },
      };

      const resp = await api.put(`/api/heartlands/${heartland_id}`, payload);

      if (resp.status === StatusCodes.OK) {
        setState((previousState) => ({
          ...previousState,
          heartland: resp.body.heartland,
          status: api.success,
          error: resp.body.message,
        }));
      } else {
        setState((previousState) => ({
          ...previousState,
          status: api.error,
          error: resp.body.message,
        }));
      }
    },
    []
  );

  const reset = () => {
    setState(defaultState);
  };

  return {
    ...state,
    reset,
    visitHeartland,
    revokeOauth,
    storeAccessCode,
    updateApiKey,
    switchAuthentication,
  };
};
