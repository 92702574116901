import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid, TextField } from "@mui/material";

interface Props {
  apiKey: string;
  onSave: (apiKey: string) => void;
}

export const ApiKey: React.FC<Props> = ({ apiKey, onSave }) => {
  const [newApiKey, setNewApiKey] = React.useState<string>("");
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowEdit(false);
    setNewApiKey("");
  }, [apiKey]);

  const doSave = () => {
    onSave(newApiKey);
  };

  if (showEdit) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            id="apiKey"
            label="Heartland API Key"
            variant="outlined"
            fullWidth
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Button variant="contained" aria-label="Save" startIcon={<SaveIcon />} onClick={doSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12} md="auto">
          <Button
            variant="contained"
            aria-label="Cancel"
            startIcon={<CancelIcon />}
            onClick={() => setShowEdit(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              id="displayApiKey"
              label="Heartland API Key"
              fullWidth
              variant="outlined"
              value={apiKey}
              disabled
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setShowEdit(true)}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
};
