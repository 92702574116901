import * as React from "react";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CancelIcon from "@mui/icons-material/Cancel";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { tagInternalFilter } from "@mui/x-data-grid/internals";
import { useLocation, useParams } from "react-router-dom";

import { useHeartlandLocations } from "api";
import {
  CountrySelector,
  DatePicker,
  HeartlandSelector,
  InfoBar,
  NotFound,
  Page,
  StateSelector,
  Tags,
  TitleLink,
  UserSelector,
} from "components";
import { SessionContext, TSessionContext, api } from "lib";
import { HeartlandModel, InfoBarModel, TagModel, UnitModel, UserModel } from "model";

import { useGetUnit, useUpdateUnit } from "./api";
import useStyles from "./styles";

interface FormProps {
  unit: UnitModel;
  adminView: boolean;
  message?: string;
}

const UnitForm: React.FC<FormProps> = (props: FormProps) => {
  const { state } = useLocation();
  const { heartlandLocations } = useHeartlandLocations();
  const [unit, setUnit] = React.useState<UnitModel>({ ...props.unit });
  const { updateUnit } = useUpdateUnit();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const updateProp = (
    prop: string,
    value: boolean | string | number | HeartlandModel | UserModel
  ) => setUnit({ ...unit, [prop]: value });
  const clearInfoBar = () => setInfoBar(null);
  const reset = () => setUnit({ ...props.unit });
  const doUpdate = () => updateUnit.update(unit);

  const updateLocation = (value: string) => {
    if (heartlandLocations.locations) {
      heartlandLocations.locations.find((l) => {
        if (l.location_id === value) {
          setUnit({
            ...unit,
            heartland_name: l.location_name,
            heartland_location_id: l.location_id,
          });
        }
      });
    }
  };
  const updateCountry = (value: string) => updateProp("country", value);
  const updateState = (value: string) => updateProp("state", value);

  React.useEffect(() => {
    if (unit?.heartland?.id) {
      heartlandLocations.list(unit.heartland.id);
    }
  }, []);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (updateUnit.status === api.success) {
      setInfoBar({ status: "success", message: "Unit updated!" });
      if (updateUnit.unit) {
        setUnit(updateUnit.unit);
      }
    } else if (updateUnit.status === api.error) {
      setInfoBar({ status: "error", message: updateUnit.error });
    } else {
      setInfoBar(null);
    }
  }, [updateUnit.status, state]);

  const transformIncomingTags = (incoming: Array<string | TagModel>): TagModel[] =>
    incoming.map((tag) => {
      if (typeof tag === "string") {
        return { id: null, tag_id: null, name: tag };
      } else if ("tag_id" in tag) {
        return tag;
      } else {
        return { id: null, tag_id: tag["id"], name: tag["name"] };
      }
    });

  const updateTags = (
    _event: React.SyntheticEvent,
    value: Array<string | TagModel>,
    reason: string
  ) => {
    let newTags: TagModel[] = [];
    const incomingTags = transformIncomingTags(value);
    if (reason === "removeOption") {
      unit.tags.forEach((tag) => {
        const found = incomingTags.find((incomingTag) => incomingTag.name === tag.name);
        if (!found) {
          if (tag.id) {
            newTags.push({ ...tag, _destroy: true });
          }
        } else {
          newTags.push(found);
        }
      });
    } else {
      incomingTags.forEach((incomingTag) => {
        const found = unit.tags.find((existingTag) => existingTag.name === incomingTag.name);
        if (found) {
          if (found._destroy && found.id) {
            newTags.push({ ...found, _destroy: false });
          } else newTags.push(found);
        } else {
          newTags.push(incomingTag);
        }
      });
      newTags = newTags.concat(unit.tags.filter((tag) => tag._destroy));
    }
    setUnit({ ...unit, tags: newTags });
  };

  const currentTags = React.useMemo(
    () => unit.tags.filter((tag) => !("_destroy" in tag) || !tag._destroy),
    [unit.tags]
  );

  return (
    <Page title={<TitleLink link="/units" linkText="Units" postText={unit.name} />}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {infoBar && (
            <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
          )}
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={2}>
            {props.adminView ? (
              <>
                <Grid item md={6} xs={6}>
                  <Tags tags={currentTags} onChange={updateTags} />
                </Grid>
                <Grid item md={2} xs={2}>
                  <DatePicker
                    label="Soft Open"
                    date={unit.soft_open_date}
                    onChange={(date) => updateProp("soft_open_date", date)}
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <DatePicker
                    label="Grand Open"
                    date={unit.grand_open_date}
                    onChange={(date) => updateProp("grand_open_date", date)}
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={unit.open}
                        onChange={(e) => updateProp("open", e.target.checked)}
                      />
                    }
                    label="Open"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item md={2} xs={12}>
              <TextField
                id="franchise_id"
                label="Franchise ID"
                variant="outlined"
                fullWidth
                value={unit.franchise_id}
                inputProps={{ readOnly: !props.adminView }}
                onChange={(e) => updateProp("franchise_id", e.target.value)}
              />
            </Grid>
            <Grid item md={10} xs={12}>
              {props.adminView ? (
                <UserSelector value={unit.owner} onSelect={(user) => updateProp("owner", user)} />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth
                value={unit.name}
                inputProps={{ readOnly: !props.adminView }}
                onChange={(e) => updateProp("name", e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {props.adminView && (
                <HeartlandSelector
                  value={unit.heartland}
                  onSelect={(value) => updateProp("heartland", value)}
                />
              )}
              {unit.heartland && (
                <Link
                  href={`https://${unit.heartland.domain}.retail.heartland.us`}
                  target="_heartland"
                  className="externalLink"
                  sx={{ ml: 1 }}
                >
                  {`https://${unit.heartland.domain}.retail.heartland.us`}
                </Link>
              )}
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                fullWidth
                value={unit ? unit.address : ""}
                onChange={(e) => updateProp("address", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="address2"
                label="Address (Unit/Suite)"
                variant="outlined"
                fullWidth
                value={unit ? unit.address2 : ""}
                onChange={(e) => updateProp("address2", e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="city"
                label="City"
                variant="outlined"
                fullWidth
                value={unit ? unit.city : ""}
                onChange={(e) => updateProp("city", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <StateSelector value={unit.state} country={unit.country} onSelect={updateState} />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                id="postal"
                label="Postal/Zip Code"
                variant="outlined"
                fullWidth
                value={unit ? unit.postal_code : ""}
                onChange={(e) => updateProp("postal_code", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CountrySelector value={unit.country} onSelect={updateCountry} />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="phone"
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={unit ? unit.phone : ""}
                onChange={(e) => updateProp("phone", e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="email"
                label="Email Address"
                variant="outlined"
                fullWidth
                value={unit ? unit.email : ""}
                onChange={(e) => updateProp("email", e.target.value)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="body1" sx={{ mt: 3, mb: 2 }}>
                Select the location that matches this Unit in Heartland. This field needs to match
                your location in Heartland if you want to see your inventory levels and for Patron
                to create and receive Purchase Orders.
              </Typography>
              {heartlandLocations.status === api.loading && <CircularProgress />}
              {heartlandLocations.status === api.success && (
                <FormControl fullWidth>
                  <InputLabel id="location-select-label">Heartland Location</InputLabel>
                  <Select
                    labelId="location-select-label"
                    id="heartland_location"
                    value={unit.heartland_location_id ? unit.heartland_location_id : "0"}
                    onChange={(e) => updateLocation(e.target.value)}
                    label="Heartland Location"
                  >
                    <MenuItem value={0}>-----------</MenuItem>
                    {heartlandLocations.locations.map((location) => (
                      <MenuItem key={location.location_id} value={location.location_id}>
                        {location.location_id} &mdash; {location.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                aria-label="Save Edits"
                startIcon={<AddBusinessIcon />}
                onClick={doUpdate}
                fullWidth
              >
                Save
              </Button>
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                aria-label="Reset"
                startIcon={<RestorePageIcon />}
                onClick={reset}
                fullWidth
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/units"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export const UnitShow: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;

  const { unit, status, get } = useGetUnit();
  const { unit_id } = useParams();
  const classes = useStyles();

  React.useEffect(() => {
    if (unit_id) {
      get(parseInt(unit_id));
    }
  }, [unit_id, get]);

  if (status === api.loading) {
    return (
      <div style={{ marginTop: 32, width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.success && unit) {
    return <UnitForm unit={unit} adminView={isAdmin()} />;
  } else if (status === api.error) {
    return <NotFound />;
  }
  return <></>;
};
