import * as React from "react";

import { CircularProgress } from "@mui/material";
import { RoyaltyFeeProvider } from "contexts";
import { useParams } from "react-router-dom";

import { useGetRoyaltyFee } from "api";
import { Page, TitleLink } from "components";
import { api } from "lib";

import { RoyaltyFeeForm } from "./components";

export const ShowRoyaltyFee: React.FC = () => {
  const { getRoyaltyFee } = useGetRoyaltyFee();
  const { royaltyFeeId } = useParams();

  React.useEffect(() => {
    if (royaltyFeeId) {
      getRoyaltyFee.get(parseInt(royaltyFeeId));
    }
  }, [royaltyFeeId]);

  const royaltyFee = React.useMemo(() => getRoyaltyFee.royaltyFee, [getRoyaltyFee]);

  const Title: React.FC = () => {
    if (api.success && royaltyFee) {
      return (
        <TitleLink
          link="/royalties"
          linkText="Royalties"
          postText={`Monthly Worksheet for ${royaltyFee.unit.name}`}
        />
      );
    }
    return <TitleLink link="/royalties" linkText="Royalties" />;
  };

  return (
    <Page title={<Title />}>
      {getRoyaltyFee.status === api.loading && <CircularProgress />}
      {getRoyaltyFee.status === api.success && royaltyFee && (
        <RoyaltyFeeProvider royaltyFee={royaltyFee}>
          <RoyaltyFeeForm />
        </RoyaltyFeeProvider>
      )}
    </Page>
  );
};
