import * as React from "react";

import { FontDownload } from "@mui/icons-material";
import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { UnitsHeartlandLocationModel } from "model";

interface State {
  status: string;
  error: string;
  locations: UnitsHeartlandLocationModel[];
}

interface Props {
  heartlandLocations: State & {
    list: (heartlandId: number, cache?: boolean) => void;
    lookup: (locationId: string) => UnitsHeartlandLocationModel;
  };
}

const defaultState = {
  status: api.idle,
  error: "",
  locations: [],
};

export const useHeartlandLocations = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const lookup = React.useCallback((locationId: string) => {
    const cachedLocations = JSON.parse(localStorage.getItem("heartland_locations") as string);
    if (cachedLocations) {
      return cachedLocations.find(
        (location: UnitsHeartlandLocationModel) =>
          location.location_id.toString() === locationId.toString()
      );
    }
    return null;
  }, []);

  const list = React.useCallback(async (heartlandId: number, cache = true) => {
    setState({ status: api.loading, error: "", locations: [] });

    // we cache locations in local storage since this data doesn't change very often, so lets fetch it locally and if we don't have
    // then hit the server with a request
    const cachedLocations = JSON.parse(localStorage.getItem("heartland_locations") as string);

    if (cachedLocations?.length > 0 && cache) {
      setState({ status: api.success, error: "", locations: cachedLocations });
      return;
    }
    const resp = await api.get(`/api/heartlands/${heartlandId}/locations`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, error: "", locations: resp.body });
    } else {
      setState({ status: api.error, error: resp.body, locations: [] });
    }
  }, []);

  return {
    heartlandLocations: {
      ...state,
      list,
      lookup,
    },
  };
};
