import * as React from "react";

import { Grid, Tab, Tabs } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, useLocation } from "react-router-dom";

import { Page } from "components";

interface Props {
  children: React.ReactElement;
}

export const BuySettingsTabs: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();
  const flags = useFlags();

  return (
    <Page title="Buy Settings">
      <Grid container spacing={2}>
        <Grid xs={1.5}>
          <Tabs value={location.pathname} aria-label="Buy Settings Tabs" orientation="vertical">
            <Tab
              label="General"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/general"}
              value="/buy_settings/general"
            />
            <Tab
              label="Minifigs & CMFs"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/minifigs"}
              value="/buy_settings/minifigs"
            />
            <Tab
              label="New Sets"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/nib"}
              value="/buy_settings/nib"
            />
            <Tab
              label="Used Sets"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/used"}
              value="/buy_settings/used"
            />
            {flags.animalCatalog && (
              <Tab
                label="Animals"
                sx={{ alignItems: "start" }}
                component={Link}
                to={"/buy_settings/animals"}
                value="/buy_settings/animals"
              />
            )}
            <Tab
              label="Bulk"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/bulk"}
              value="/buy_settings/bulk"
            />
            <Tab
              label="Misc"
              sx={{ alignItems: "start" }}
              component={Link}
              to={"/buy_settings/misc"}
              value="/buy_settings/misc"
            />
          </Tabs>
        </Grid>
        <Grid xs={10.5}>{children}</Grid>
      </Grid>
    </Page>
  );
};
