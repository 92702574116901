import * as React from "react";

import { Box, Button, Grid, TextField } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";

import { Checkbox, LegoSetLinks, LegoSetSummary } from "components";
import { SessionContext, TSessionContext } from "lib";

export const Description: React.FC = () => {
  const { update, save } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;

  return (
    <>
      {isAdmin() ? (
        <Grid container sx={{ mb: 4 }} spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              label="Description"
              value={legoSet.description}
              onChange={(e) => update("description", e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              fullWidth
              label="Release Year"
              value={legoSet.release_year}
              onChange={(e) => update("release_year", e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              fullWidth
              label="Retirement Year"
              value={legoSet.retirement_year}
              onChange={(e) => update("retirement_year", e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              checked={legoSet.retired}
              label="Retired"
              onChange={(e) => update("retired", e.target.checked)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Number of Parts"
              value={legoSet.num_parts}
              onChange={(e) => update("num_parts", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Number of Minifigs"
              value={legoSet.num_minifigs}
              onChange={(e) => update("num_minifigs", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Bricklink ID"
              value={legoSet.bricklink_id}
              onChange={(e) => update("bricklink_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Brickset ID"
              value={legoSet.brickset_id}
              onChange={(e) => update("brickset_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Rebrickable ID"
              value={legoSet.rebrickable_id}
              onChange={(e) => update("rebrickable_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="BrickOwl ID"
              value={legoSet.brickowl_id}
              onChange={(e) => update("brickowl_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "right" }}>
              <Button variant="contained" onClick={save}>
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <LegoSetLinks legoSet={legoSet} />
          <LegoSetSummary legoSet={legoSet} />
        </>
      )}
    </>
  );
};
