import * as React from 'react';

import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  linkText: string;
  link?: string;
  postText?: string;
}

export const TitleLink: React.FC<Props> = ({ linkText, link, postText }) => {
  const navigate = useNavigate();
  const onBackClick = () => navigate(-1);

  return (
    <Typography variant="h5" sx={{ mb: 1 }}>
      {link ? (
        <>
          <Link href={link}>{linkText}</Link>
          {postText && <span> :: {postText}</span>}
        </>
      ) : (
        <>
          <a onClick={onBackClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {linkText}
          </a>
          {postText && <span> :: {postText}</span>}
        </>
      )}
    </Typography>
  );
};
