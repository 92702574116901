export const formatCurrency = (
  amount: number | undefined,
  fraction = true
): string => {
  if (!amount) {
    amount = 0;
  }

  return (Math.round(amount * 100) / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fraction ? 2 : 0,
    maximumFractionDigits: fraction ? 2 : 0,
  });
};

export const formatNumber = (x:string|number|undefined):string => {
  if(x === null || x === undefined) {
    return "0";
  } 
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatPercentage = (amount: number | undefined): string => {
  if (!amount) {
    amount = 0;
  }
  return `${(amount * 100).toFixed(2)}%`;
};

export const calcDiscount = (amount: number, discount: number): number =>
  (amount * discount) / 100;

export const calcMargin = (cost: any, total: any): string => {
  if (!total || !cost) {
    return '0.0%';
  } else {
    const margin = (cost / total) * 100;
    if (margin < 0) {
      return '0.0%';
    }
    return `${Math.round(margin * 10) / 10}%`;
  }
};

export const formatDate = (data: string | undefined): string => {
  if (data) {
    const date = new Date(data);
    return date.toLocaleString();
  }
  return '';
};

export const formatPrice = (val: any): string =>
  val ? '$' + Number(val).toFixed(2) : 'N/A';

export const formatAbbreviatedTimestamp = (isoString: string): string => {
  const date = new Date(isoString);

  const formattedDate = date.toLocaleDateString('en-US');
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate} - ${formattedTime}`;
};

export const formatJustDate = (isoString: string): string => {
  return `${new Date(isoString).toLocaleDateString('en-US')}`;
};

export const displayType = (type: string): string => {
  return type.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};
