import * as React from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { Navigate, useSearchParams } from "react-router-dom";

import { useDailySalesList } from "api";
import { DatePicker, Page } from "components";
import { SessionContext, TSessionContext, api, formatCurrency, formatNumber } from "lib";

import { DailySalesTable } from "./components/sales_table";

interface State {
  startDate: string;
  endDate: string;
}

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const defaultState = {
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
};

interface QuickDate {
  label: string;
  startDate: string;
  endDate: string;
}

type QuickDates = Record<string, QuickDate>;

const quickDates: QuickDates = {
  today: {
    label: "Today",
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  yesterday: {
    label: "Yesterday",
    startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  },
  wtd: {
    label: "Week to Date",
    startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  mtd: {
    label: "Month to Date",
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  ytd: {
    label: "Year to Date",
    startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  },
  lw: {
    label: "Last Week",
    startDate: dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
  },
  lm: {
    label: "Last Month",
    startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  custom: {
    label: "Custom",
    startDate: "",
    endDate: "",
  },
};

const summary = {
  p: 2,
  my: 1,
  ml: 2,
  lineHeight: "1.7em",
};

export const SalesSummary: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const [urlParams, setUrlParams] = useSearchParams(defaultState);

  const { dailySalesList, getDailySalesList } = useDailySalesList();
  const [quickDate, setQuickDate] = React.useState<string>("today");

  const range: State = React.useMemo(() => {
    const params = new URLSearchParams(urlParams);
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    if (startDate && endDate) {
      return { startDate: startDate, endDate: endDate };
    } else {
      return defaultState;
    }
  }, [urlParams]);

  React.useEffect(() => {
    getDailySalesList(range.startDate, range.endDate);
  }, []);

  const run = () => {
    getDailySalesList(range.startDate, range.endDate);
  };

  const updateStartDate = (day: string) => {
    const newParams = new URLSearchParams(urlParams);
    newParams.set("startDate", day);
    setUrlParams(newParams);
    setQuickDate("custom");
  };

  const updateEndDate = (day: string) => {
    const newParams = new URLSearchParams(urlParams);
    newParams.set("endDate", day);
    setUrlParams(newParams);
    setQuickDate("custom");
  };

  const updateQuickDate = (event: SelectChangeEvent) => {
    const value: string = event.target.value;
    if (value !== "custom") {
      setUrlParams({
        startDate: quickDates[value].startDate,
        endDate: quickDates[value].endDate,
      });
      getDailySalesList(quickDates[value].startDate, quickDates[value].endDate);
    }
    setQuickDate(value);
  };

  if (currentUser) {
    return (
      <Page title="Sales Summary">
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="quick-date-select-label">Date</InputLabel>
              <Select
                labelId="quick-date-select-label"
                id="quick-date-select"
                value={quickDate}
                label="Date"
                onChange={updateQuickDate}
              >
                {Object.keys(quickDates).map((key) => (
                  <MenuItem key={key} value={key}>
                    {quickDates[key].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs="auto">
            <DatePicker date={range.startDate} onChange={updateStartDate} />
          </Grid>
          <Grid item xs="auto">
            <DatePicker date={range.endDate} onChange={updateEndDate} />
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" onClick={run}>
              Run
            </Button>
          </Grid>
          <Box sx={{ width: "100%" }} />
          <Grid xs={1.5}>
            <Paper sx={summary}>
              <strong>Net Sales</strong>
              <br />
              {formatCurrency(dailySalesList.total?.net_sales)}
              <br />
              {formatNumber(dailySalesList.total?.tickets)} Tickets
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Minifigs
              <br />
              {formatCurrency(dailySalesList.total?.minifig_sales)}
              <br />
              {formatNumber(dailySalesList.total?.minifig_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Bulk
              <br />
              {formatCurrency(dailySalesList.total?.bulk_sales)}
              <br />
              {formatNumber(dailySalesList.total?.bulk_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Used Sets
              <br />
              {formatCurrency(dailySalesList.total?.used_set_sales)}
              <br />
              {formatNumber(dailySalesList.total?.used_set_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              New Sets
              <br />
              {formatCurrency(dailySalesList.total?.new_set_sales)}
              <br />
              {formatNumber(dailySalesList.total?.new_set_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Aftermarket
              <br />
              {formatCurrency(dailySalesList.total?.aftermarket_sales)}
              <br />
              {formatNumber(dailySalesList.total?.aftermarket_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Events
              <br />
              {formatCurrency(dailySalesList.total?.events_sales)}
              <br />
              {formatNumber(dailySalesList.total?.events_qty)}
            </Paper>
          </Grid>
          <Grid xs={1.5}>
            <Paper sx={summary}>
              Miscellaneous
              <br />
              {formatCurrency(dailySalesList.total?.misc_sales)}
              <br />
              {formatNumber(dailySalesList.total?.misc_qty)}
            </Paper>
          </Grid>
        </Grid>
        <DailySalesTable
          data={dailySalesList.sales}
          loading={dailySalesList.status === api.loading}
        />
      </Page>
    );
  } else {
    return <Navigate to="/Login" />;
  }
};
