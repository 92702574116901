import * as React from 'react';

import { Alert, Box, Grid, LinearProgress, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChartsItemContentProps, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import moment from 'moment';

import { useDailySalesList } from 'api';
import { InfoBar } from 'components';
import { api, formatCurrency, formatPercentage } from 'lib';
import { UnitModel } from 'model';

const Item = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  textAlign: 'left',
}));

interface DataPoint {
  id: string;
  label: string;
  value: number;
  margin: number;
  qty: number;
  discounts: number;
  color?: string;
}

interface CustomTooltipProps {
  data: DataPoint;
}

const TooltipItem = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingBottom: theme.spacing(1),
}));

const CustomTooltip: React.FC<CustomTooltipProps> = ({ data }) => {
  return (
    <Tooltip title="">
      <Paper
        elevation={0}
        sx={{
          m: 1,
          p: 1.5,
          border: 'solid',
          borderWidth: 2,
          borderColor: 'divider',
        }}
      >
        <Stack>
          <Typography sx={{ color: data.color, fontWeight: 700, pb: 1 }}>{data.label}</Typography>
          <TooltipItem>
            <strong>{formatCurrency(data.value)}</strong>
          </TooltipItem>
          <TooltipItem>
            <strong>{data.qty}</strong> Total Units Sold
          </TooltipItem>
          <TooltipItem>
            <strong>{formatPercentage(data.margin)}</strong> Sold Margin
          </TooltipItem>
          <TooltipItem>
            <strong>{formatCurrency(data.discounts)}</strong> Discounts
          </TooltipItem>
        </Stack>
      </Paper>
    </Tooltip>
  );
};

interface Props {
  unit: UnitModel | null;
}
export const DailySalesSummary: React.FC<Props> = ({ unit }: Props) => {
  const { dailySalesList, getDailySalesList } = useDailySalesList();
  React.useEffect(() => {
    const end = moment().format('YYYY-MM-DD');
    const start = moment().format('YYYY-MM-01');
    getDailySalesList(start, end);
  }, []);

  const dailySale = React.useMemo(() => {
    if (dailySalesList.sales.length > 0) {
      if (unit) {
        return dailySalesList.sales.find((sales) => sales.unit_id === unit.id);
      } else {
        return dailySalesList.total;
      }
    }
    return null;
  }, [dailySalesList, unit]);

  const series: DataPoint[] = React.useMemo(() => {
    if (dailySale) {
      return [
        {
          id: 'bulk',
          value: dailySale.bulk_sales,
          label: 'Bulk',
          margin: dailySale.bulk_margin,
          qty: dailySale.bulk_qty,
          discounts: dailySale.bulk_discounts,
        },
        {
          id: 'new_sets',
          value: dailySale.new_set_sales,
          label: 'New Sets',
          margin: dailySale.new_set_margin,
          qty: dailySale.new_set_qty,
          discounts: dailySale.new_set_discounts,
        },
        {
          id: 'used_sets',
          value: dailySale.used_set_sales,
          label: 'Used Sets',
          margin: dailySale.used_set_margin,
          qty: dailySale.used_set_qty,
          discounts: dailySale.used_set_discounts,
        },
        {
          id: 'minifigs',
          value: dailySale.minifig_sales,
          label: 'Minifigs',
          margin: dailySale.minifig_margin,
          qty: dailySale.minifig_qty,
          discounts: dailySale.minifig_discounts,
        },
        {
          id: 'aftermarket',
          value: dailySale.aftermarket_sales,
          label: 'Aftermarket',
          margin: dailySale.aftermarket_margin,
          qty: dailySale.aftermarket_qty,
          discounts: dailySale.aftermarket_discounts,
        },
        {
          id: 'events',
          value: dailySale.events_sales,
          label: 'Parties/Events',
          margin: dailySale.events_margin,
          qty: dailySale.events_qty,
          discounts: dailySale.events_discounts,
        },
        {
          id: 'misc',
          value: dailySale.misc_sales,
          label: 'Miscellaneous',
          margin: dailySale.misc_margin,
          qty: dailySale.misc_qty,
          discounts: dailySale.misc_discounts,
        },
      ];
    } else {
      return [];
    }
  }, [dailySale]);

  if (dailySalesList.status === api.loading || dailySalesList.status === api.idle) {
    return <LinearProgress />;
  } else if (dailySalesList.status === api.error) {
    return <InfoBar status="error" message={dailySalesList.error} />;
  } else if (dailySalesList.status === api.success && dailySale) {
    return (
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h5">This Month's Sales</Typography>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ padding: 2, background: '#f9f9f9' }}>
            <Stack spacing={1}>
              <Item>
                <strong>{formatCurrency(dailySale.net_sales)}</strong> Net Sales
              </Item>
              <Item>
                <strong>{dailySale.tickets}</strong> Tickets
              </Item>
              <Item>
                <strong>{formatPercentage(dailySale.margin)}</strong> Sold Margin
              </Item>
              <Item>
                <strong>{dailySale.qty_sold}</strong> Total Units Sold
              </Item>
              <Item>
                <strong>{formatCurrency(dailySale.atv)}</strong> Average Transaction Value
              </Item>
              <Item>
                <strong>{formatCurrency(dailySale.discounts)}</strong> Discounts
              </Item>
              <Item>
                <strong>{formatCurrency(dailySale.taxes)}</strong> Taxes
              </Item>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={7} sx={{ minHeight: 240 }}>
          <PieChart
            series={[
              {
                data: series,
                innerRadius: 50,
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: '#fff',
                },
              },
            ]}
            slots={{
              itemContent: (props) => {
                const index = props.itemData.dataIndex || 0;
                const data = props.series.data[index];
                return <CustomTooltip data={data as unknown as DataPoint} />;
              },
            }}
            width={500}
            sx={{
              '& .MuiChartsLegend-series text': { fontSize: '0.7em !important' },
              [`& .${pieArcLabelClasses.root}`]: {
                fill: '#fff',
                fontSize: '0.8em',
              },
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return <Alert severity="warning">Sorry, Sales data is currently not available</Alert>;
  }
};
