import * as React from 'react';

import { Box, LinearProgress, Link, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetCMF } from 'api';
import { NotFound, Page, TitleLink } from 'components';
import { SessionContext, TSessionContext, api } from 'lib';

import { Details, MyStoreInfo, PriceGuide } from './components';
import { CMFProvider } from './context';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const CMFShow: React.FC = () => {
  const { isEmployee, isOwner } = React.useContext(SessionContext) as TSessionContext;

  const { status, error, minifig, getCMF } = useGetCMF();
  const { bricklink_id } = useParams();

  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => setTab(newTab);

  React.useEffect(() => {
    if (bricklink_id) {
      getCMF(bricklink_id);
    }
  }, [bricklink_id, getCMF]);

  if (status === api.success && minifig) {
    return (
      <Page
        title={
          <TitleLink
            link="/catalog"
            linkText="Catalog"
            postText={`${minifig.bricklink_id} ${minifig.name}`}
          />
        }
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="Heartland Tabs">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Price Guide" {...a11yProps(1)} />
            {(isEmployee() || isOwner()) && <Tab label="@ My Store" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <CMFProvider minifig={minifig}>
          <Box hidden={tab !== 0} id="tabpanel-0">
            <Details />
          </Box>
          <Box hidden={tab !== 1} id="tabpanel-1">
            <PriceGuide />
          </Box>
          <Box hidden={tab !== 2} id="tabpanel-2">
            <MyStoreInfo />
          </Box>
        </CMFProvider>
      </Page>
    );
  } else if (status === api.error) {
    return (
      <>
        <div style={{ width: 800, margin: '0 auto', textAlign: 'center' }}>
          <NotFound />
          <h4>
            <Link href="/catalog">Return to Catalog</Link>
          </h4>
        </div>
      </>
    );
  } else {
    return (
      <div style={{ marginTop: 32, width: 800 }}>
        <LinearProgress />
      </div>
    );
  }
};
