import * as React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Alert, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

import { CountryCode, geographies, getByCountryCode } from "lib";
import { Address, CustomerModel } from "model";

interface Props {
  customer: CustomerModel;
}

function validateAddress(address: Address) {
  if (!address) {
    return false;
  }
  const nonNullableAttributes: (keyof Address)[] = [
    "line_1",
    "city",
    "state",
    "postal_code",
    "country",
  ];

  return nonNullableAttributes.every((attribute) => Boolean(address[attribute]));
}

export const CustomerAddress: React.FC<Props> = ({ customer }) => {
  const [tooltip, setTooltip] = React.useState<string>("");

  const region = React.useMemo(() => {
    if (customer.address == null) return "";

    const state = getByCountryCode(customer.address.country)?.states.find(
      (state) => state.abbreviation === customer.address.state
    );

    return state?.name || customer.address?.state || "";
  }, [customer]);

  const addressConcat = React.useMemo(() => {
    if (!customer.address) return "";

    return `${customer.address.line_1 || ""} ${customer.address.line_2 || ""} ${customer.address.city || ""}, ${region || ""} ${getByCountryCode(customer.address.country)?.abbreviation || ""} ${customer.address.postal_code || ""}`;
  }, [region, customer]);

  React.useEffect(() => {
    setTooltip(`Copy?`);
  }, [addressConcat]);

  const handleAddressCopy = async () => {
    await navigator.clipboard.writeText(addressConcat);
    setTooltip("Copied!");
    setTimeout(() => setTooltip(`Copy?`), 2000);
  };

  return validateAddress(customer.address) ? (
    <>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography>Address</Typography>
              </Grid>
              <Grid item>
                <Tooltip title={tooltip} placement="top" arrow>
                  <IconButton onClick={handleAddressCopy}>
                    <ContentCopyIcon
                      sx={{
                        width: "15px",
                        cursor: "pointer",
                        transition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="line_1"
              label="Street"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.address.line_1 || " "}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="line_2"
              label="Address (Apt/Suite)"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.address.line_2 || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.address.city || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="state"
              label="State"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={region || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={getByCountryCode(customer.address.country)?.name || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="zipcode"
              label="Postal Code"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.address.postal_code || " "}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <Grid item xs={9}>
      <Alert severity="warning">
        Note: Address has not been added to Patron. The address must be added to the customer
        account page in Heartland and all required fields should be filled out (Line 1, City, State,
        Country, and Postal Code). Click the sync button once this has been completed to update
        Patron.
      </Alert>
    </Grid>
  );
};
