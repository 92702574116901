import * as React from 'react';

import { Grid, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { Navigate } from 'react-router-dom';

import { useUnitsList } from 'api';
import { UnitAutocomplete } from 'components';
import { SessionContext, TSessionContext } from 'lib/session';
import { UnitModel } from 'model';

import { BuysSummary } from './components/buys_summary';
import { DailySalesSummary } from './components/sales_summary';

export const Dashboard = () => {
  const flags = useFlags();
  const { currentUser, isOwner } = React.useContext(SessionContext) as TSessionContext;
  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(null);
  const { units, list: getUnits } = useUnitsList();

  const container = {
    background: '#f9f9f9',
    height: '100%',
    p: 2,
  };

  React.useEffect(() => {
    getUnits();
  }, []);

  if (currentUser) {
    return (
      <Grid container spacing={2} sx={{ width: '90%' }}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="h6">
            Welcome {currentUser.display_name}! Today is {moment().format('L')}
          </Typography>
          {units.length > 1 && (
            <UnitAutocomplete units={units} value={selectedUnit} onSelect={setSelectedUnit} />
          )}
        </Grid>
        {isOwner() && flags.healthReports && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <DailySalesSummary unit={selectedUnit} />
          </Grid>
        )}
        <Grid item xs={12} sx={{ my: 3 }}>
          <BuysSummary selectedUnit={selectedUnit} />
        </Grid>
      </Grid>
    );
  } else {
    return <Navigate to="/Login" />;
  }
};
