import * as React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";

import { useGetInventorySettings, useMultipleHeartlandItems } from "api";
import { Checkbox } from "components/checkbox";
import { SessionContext, TSessionContext, api } from "lib";
import { AnimalModel, BuyModel, LegoSetModel, MinifigModel, SeriesMinifigModel } from "model";

const header = [
  "PO #",
  "PO Description",
  "PO Start Ship",
  "PO End Ship",
  "PO Vendor",
  "PO Received at location",
  "Item Description",
  "Item Default Cost",
  "Item Current Price",
  "Item Active?",
  "Item Track Inventory?",
  "Item Primary Vendor",
  "Item Taxable",
  "Item Department",
  "Item Category",
  "Item Series",
  "Item #",
  "PO Line Unit Cost",
  "PO Line Qty",
  "Item bricklink_id",
];

export interface ImportList {
  new_sets: boolean;
  used_sets: boolean;
  minifigs: boolean;
  cmf: boolean;
}

interface Props {
  buy: BuyModel;
}

export const PurchaseOrderImport: React.FC<Props> = ({ buy }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getInventorySettings } = useGetInventorySettings();
  const { multipleHeartlandItems } = useMultipleHeartlandItems();
  const csvLink = React.useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null);
  const [readyState, setReadyState] = React.useState<"loading" | "ready" | "error">("loading");
  const [importList, setImportList] = React.useState<ImportList>({
    new_sets: true,
    used_sets: true,
    minifigs: true,
    cmf: true,
  });

  const updateImportList = (key: string, checked: boolean) => {
    setImportList({ ...importList, [key]: checked });
  };

  React.useEffect(() => {
    if (currentHeartland && buy) {
      getInventorySettings.get(currentHeartland.id);
      const new_sets: LegoSetModel[] = buy.new_set_buy_lines.map((item) => item.lego_set);
      const used_sets: LegoSetModel[] = buy.used_set_buy_lines.map((item) => item.lego_set);
      const cmf: SeriesMinifigModel[] = buy.series_minifig_buy_lines.map(
        (item) => item.series_minifig
      );
      const minifigs: MinifigModel[] = [];
      const animals: AnimalModel[] = [];
      buy.minifig_buy_lines.forEach((item) => {
        if (item.minifig) {
          minifigs.push(item.minifig);
        }
      });
      buy.animal_buy_lines.forEach((item) => {
        if (item.animal) {
          animals.push(item.animal);
        }
      });
      const items: (LegoSetModel | AnimalModel | MinifigModel | SeriesMinifigModel)[] = [
        ...new_sets,
        ...used_sets,
        ...minifigs,
        ...cmf,
        ...animals,
      ];
      multipleHeartlandItems.get(currentHeartland.id, items);
    } else {
      setReadyState("error");
    }
  }, [buy, currentHeartland]);

  React.useEffect(() => {
    if (
      multipleHeartlandItems.status === api.success &&
      getInventorySettings.status === api.success &&
      getInventorySettings.inventorySetting
    ) {
      setReadyState("ready");
    } else if (multipleHeartlandItems.status === api.error) {
      setReadyState("error");
    }
  }, [multipleHeartlandItems, getInventorySettings]);

  const getHeartlandItem = (bricklinkId: string, condition: string) => {
    const items = multipleHeartlandItems.items[bricklinkId];
    if (items) {
      return items.find((item) => {
        return item["custom"]["department"] === condition;
      });
    } else {
      return {};
    }
  };

  const poVendor = () => {
    if (getInventorySettings.inventorySetting) {
      return getInventorySettings.inventorySetting.buy_trade_vendor_name;
    } else {
      return null;
    }
  };

  const itemNumber = (itemNumber: string, condition: string, retired: boolean) => {
    switch (condition) {
      case "used":
        return `${itemNumber}${getInventorySettings.inventorySetting?.complete_set_postfix}`;
      case "certified":
        return `${itemNumber}${getInventorySettings.inventorySetting?.certified_set_postfix}`;
      case "opened":
        return `${itemNumber}${getInventorySettings.inventorySetting?.open_box_postfix}`;
      case "build_only":
        return `${itemNumber}${getInventorySettings.inventorySetting?.build_only_set_postfix}`;
      case "project":
        return `${itemNumber}${getInventorySettings.inventorySetting?.project_set_postfix}`;
      default:
        if (retired && getInventorySettings.inventorySetting?.auto_set_retired) {
          return `${itemNumber}${getInventorySettings.inventorySetting?.retired_set_postfix}`;
        }
        return itemNumber;
    }
  };

  const importData = React.useMemo(() => {
    const csvData = [];
    csvData.push(header);

    const createLine = (
      description: string,
      defaultCost: string,
      currentPrice: string,
      department: string,
      itemNumber: string,
      poCost: string,
      quantity: string,
      bricklinkId: string
    ) => {
      return [
        `PATRON-${buy.id}`,
        `"${buy.summary}"`, // enclose in quotes since there may be commas in the summary
        moment(buy.completed_at).format("L"),
        moment(buy.completed_at).format("L"),
        `"${poVendor()}"`,
        buy.unit.heartland_name || buy.unit.heartland_location_id || buy.unit.name,
        description,
        defaultCost,
        currentPrice,
        "yes",
        "yes",
        `"${poVendor()}"`,
        "yes",
        department,
        "",
        "",
        itemNumber,
        poCost,
        quantity,
        bricklinkId,
      ];
    };
    if (importList.new_sets) {
      buy.new_set_buy_lines.forEach((line) => {
        const existing = getHeartlandItem(line.lego_set.bricklink_id, "New Sets");
        csvData.push(
          createLine(
            `"${line.lego_set.set_number} ${line.lego_set.name}"`,
            existing ? existing["cost"] : `${line.cost_final}`,
            existing ? existing["price"] : `${line.value}`,
            "New Sets",
            itemNumber(
              line.lego_set.set_number,
              line.opened ? "opened" : "new",
              line.lego_set.retired
            ),
            `${line.cost_final}`,
            `${line.quantity}`,
            line.lego_set.bricklink_id
          )
        );
      });
    }
    if (importList.used_sets) {
      buy.used_set_buy_lines.forEach((line) => {
        const existing = getHeartlandItem(line.lego_set.bricklink_id, "Used Sets");
        csvData.push(
          createLine(
            `"${line.lego_set.set_number} ${line.lego_set.name}"`,
            existing ? existing["cost"] : `${line.cost_final}`,
            existing ? existing["price"] : `${line.value}`,
            "Used Sets",
            itemNumber(line.lego_set.set_number, "used", false),
            `${line.cost_final}`,
            `${line.quantity}`,
            line.lego_set.bricklink_id
          )
        );
      });
    }
    if (importList.cmf) {
      buy.series_minifig_buy_lines.forEach((line) => {
        const existing = getHeartlandItem(line.series_minifig.bricklink_id, "Minifigs");
        csvData.push(
          createLine(
            `"CMF ${line.series_minifig.series} - ${line.series_minifig.name}"`,
            existing ? existing["cost"] : `${line.cost_final}`,
            existing ? existing["price"] : `${line.value}`,
            "Minifigs",
            existing ? existing["public_id"] : line.series_minifig.bricklink_id,
            `${line.cost_final}`,
            `${line.quantity}`,
            line.series_minifig.bricklink_id
          )
        );
      });
    }
    if (importList.minifigs) {
      buy.minifig_buy_lines.forEach((line) => {
        if (line.minifig) {
          const existing = getHeartlandItem(line.minifig.bricklink_id, "Minifigs");
          csvData.push(
            createLine(
              `"${line.minifig.bricklink_id} - ${line.minifig.name}"`,
              existing ? existing["cost"] : `${line.cost_final}`,
              existing ? existing["price"] : `${line.value}`,
              "Minifigs",
              existing ? existing["public_id"] : line.minifig.bricklink_id,
              `${line.cost_final}`,
              `${line.quantity}`,
              line.minifig.bricklink_id
            )
          );
        }
      });
    }
    return csvData;
  }, [buy, getInventorySettings.inventorySetting, importList, multipleHeartlandItems.items]);

  const doDownload = () => {
    csvLink?.current?.link?.click();
  };

  if (readyState === "ready") {
    return (
      <>
        <Checkbox
          label="New Sets"
          checked={importList.new_sets}
          onChange={(e) => updateImportList("new_sets", e.target.checked)}
        />
        <Checkbox
          label="Used Sets"
          checked={importList.used_sets}
          onChange={(e) => updateImportList("used_sets", e.target.checked)}
        />
        <Checkbox
          label="CMF"
          checked={importList.cmf}
          onChange={(e) => updateImportList("cmf", e.target.checked)}
        />
        <Checkbox
          label="Minifigs"
          checked={importList.minifigs}
          onChange={(e) => updateImportList("minifigs", e.target.checked)}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={doDownload}
        >
          Heartland PO CSV Import
        </Button>
        <CSVLink
          ref={csvLink}
          data={importData}
          enclosingCharacter=""
          filename={`patron_${buy.id}.csv`}
          target="_blank"
        />
      </>
    );
  } else if (readyState === "error") {
    return <>CSV PO Import Unavailable</>;
  } else {
    return <CircularProgress />;
  }
};
