import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { BuySettings } from "model";

interface State {
  status: string;
  msg: string;
  settings: BuySettings | null;
}

const defaultState: State = {
  status: api.idle,
  msg: "",
  settings: null,
};

interface Props {
  saveBuySettings: State & {
    save: (setting: BuySettings) => void;
    resetSettings: (heartland_id: number, section: string) => void;
    reset: () => void;
  };
}

export const useSaveBuySettings = (): Props => {
  const [state, setState] = React.useState(defaultState);

  const resetSettings = React.useCallback(
    async (heartland_id: number, section: string) => {
      setState((prevState) => ({ ...prevState, status: api.loading }));
      const resp = await api.post(
        `/api/heartlands/${heartland_id}/buys/settings/reset_${section}`,
        {}
      );
      if (resp.status === StatusCodes.OK) {
        setState({ settings: resp.body, status: api.success, msg: "Settings reset" });
      } else {
        setState({ status: api.error, settings: null, msg: "" });
      }
    },
    [setState]
  );

  const save = React.useCallback(
    async (settings: BuySettings) => {
      if (settings.settings) {
        setState((prevState) => ({ ...prevState, status: api.loading }));
        const body = settings;
        const resp = await api.put(`/api/buys/settings/${settings.settings.id}`, body);
        if (resp.status === StatusCodes.OK) {
          setState({ settings: resp.body, status: api.success, msg: "Settings saved" });
        } else {
          setState({ status: api.error, settings: null, msg: resp.body });
        }
      }
    },
    [setState]
  );

  const reset = React.useCallback(() => {
    setState(defaultState);
  }, [setState]);

  return { saveBuySettings: { ...state, save, reset, resetSettings } };
};
