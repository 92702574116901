import * as React from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import { useRequestReset } from "pages/login/api/requestReset";
import { api, isEmail } from 'lib';
import { UserModel } from "model";
import SendIcon from '@mui/icons-material/Send';
import { formRowStyle } from './styles';

interface FormProps {
  user: UserModel;
}

export const UserAccount: React.FC<FormProps> = (props: FormProps) => {
    const [user, setUser] = React.useState<UserModel>(props.user);
    const [skipMessage, setSkipMessage] = React.useState<string>('');

    const { status: requestStatus, message: requestMessage, requestReset } = useRequestReset();

    const doResetPassword = async () => {
        if (isEmail(user.email)) {
            setSkipMessage("");
            requestReset(user.email);
        } else {
            setSkipMessage("A valid email is required!");
        }
    }

    const renderRequestErrors = (): string => {
        if (skipMessage.length > 1) {
            return skipMessage;
        } else if (requestStatus === api.error) {
            return requestMessage;
        } else {
            return '';
        }
    };

    const renderSuccess = (): string => {
        if (requestStatus === api.success) {
            return 'Email sent successfully!';
        } else {
            return '';
        }
    }

    return (
        <>
            <Box sx={formRowStyle}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 700, textAlign: 'start' }}>
                    Password Details
                </Typography>
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'start', ml: 2 }}>
                    Last Password Reset: <Typography component="span" sx={{color: user?.reset_password_sent_at ? 'green' : '#CC0000'}}>{user?.reset_password_sent_at ? new Date(user.reset_password_sent_at).toDateString() : 'Not Available'}</Typography>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 2,
                        textAlign: 'start',
                        ml: 2,
                    }}
                >
                    Account Status: <Typography component="span" sx={{color: user.status === 'pending' ? 'orange' : user.status === 'active' ? 'green' : '#CC0000'}}>{user.status || 'Not Available'}</Typography>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            aria-label="Reset Password"
                            startIcon={<SendIcon />}
                            onClick={doResetPassword}
                            fullWidth
                        >
                            Send Reset Password Email
                        </Button>
                    </Grid>
                    <Box sx={formRowStyle} style={{ width: '60%' }}>
                        <div className="success">{renderSuccess()}</div>
                    </Box>
                    <Box sx={formRowStyle} style={{ width: '60%' }}>
                        <div className="error">{renderRequestErrors()}</div>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}