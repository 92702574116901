import * as React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { UnitModel } from 'model';
import { SessionContext, TSessionContext } from 'lib';

interface Props {
  onSelect?: (unit: UnitModel) => void;
  value?: UnitModel | null;
  size?: 'small' | 'medium';
  label?: boolean;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  displayIfSingle?: boolean;
  units?: UnitModel[] | null;
}

export const UnitSelector: React.FC<Props> = ({
  onSelect,
  value = null,
  size = 'medium',
  label = true,
  variant = 'outlined',
  displayIfSingle = true,
  units = null,
}: Props) => {
  const { units: unitsCache } = React.useContext(
    SessionContext
  ) as TSessionContext;
  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(
    null
  );

  const unitsList = React.useMemo(() => {
    if (units) {
      return units;
    } else if (unitsCache) {
      return unitsCache;
    }
    return null;
  }, [units, unitsCache]);

  const handleChange = (event: SelectChangeEvent) => {
    if (unitsList) {
      const unitId: number = +event.target.value;
      const unit = unitsList.find((s) => s.id === unitId);
      if (unit) {
        setSelectedUnit(unit);
        if (onSelect) {
          onSelect(unit);
        }
      }
    }
  };

  React.useEffect(() => {
    if (value) {
      setSelectedUnit(value);
    }
  }, [value]);

  if (unitsList) {
    if (unitsList.length > 1 || !displayIfSingle) {
      return (
        <FormControl fullWidth variant={variant}>
          {label ? (
            <InputLabel id='unit-select-label'>Location</InputLabel>
          ) : (
            <></>
          )}
          <Select
            labelId='unit-select-label'
            id='unit'
            value={`${value ? value.id : ''}`}
            onChange={handleChange}
            size={size}
          >
            {unitsList.map((unit: UnitModel) => (
              <MenuItem key={unit.id} value={`${unit.id}`}>
                {unit.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (unitsList.length === 1) {
      return <div>{unitsList[0].name}</div>;
    } else {
      return <div>No Locations Found!</div>;
    }
  } else {
    return <div>Unable to load locations</div>;
  }
};
