import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { RoyaltyFeeModel } from "model";

interface State {
  status: string;
  error: string;
  royaltyFees: RoyaltyFeeModel[];
  total: number;
}

interface Props {
  listRoyaltyFees: State & {
    list: () => void;
    reset: () => void;
  };
}

const defaultState: State = {
  status: api.idle,
  error: "",
  royaltyFees: [],
  total: 0,
};

export const useListRoyaltyFees = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const list = React.useCallback(async () => {
    setState((prevState) => ({ ...prevState, error: "", status: api.loading }));
    const resp = await api.get("/api/royalties");
    if (resp.status === StatusCodes.OK) {
      setState({
        status: api.success,
        royaltyFees: resp.body.royalty_fees,
        total: resp.body.total,
        error: "",
      });
    } else {
      setState({ status: api.error, royaltyFees: [], total: 0, error: resp.body });
    }
  }, [setState]);

  const reset = () => {
    setState(defaultState);
  };

  return {
    listRoyaltyFees: {
      ...state,
      list: list,
      reset: reset,
    },
  };
};
