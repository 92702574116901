import * as React from "react";

import { Grid, Link, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

import { useListRoyaltyFees } from "api";
import { Page } from "components";
import { api, formatCurrency, formatPercentage } from "lib";
import { UnitModel } from "model";

const minDate = dayjs(new Date("2025-01-01"));
const maxDate = dayjs(new Date("2025-12-31"));

interface Props {
  date?: string;
}

const columns: GridColDef[] = [
  {
    field: "unit",
    headerName: "Unit",
    flex: 2.0,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/royalties/${params.row.id}`}>{params.row.unit.name}</Link>
      </>
    ),
    valueGetter: (unit: UnitModel) => unit.name,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    type: "date",
    renderCell: (params: GridRenderCellParams<any, string>) => <>{params.row.date}</>,
    valueGetter: (date: string) => dayjs(date).toDate(),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1.25,
    renderCell: (params: GridRenderCellParams<any, string>) => {
      switch (params.row.status) {
        case "in_review":
          return <>In Review</>;
        case "pending":
          return (
            <Typography variant="body2" component="span" sx={{ color: "#e74c3c" }}>
              Pending
            </Typography>
          );
        case "rejected":
          return (
            <Typography variant="body2" component="span" sx={{ color: "#e74c3c" }}>
              Corrections Requested
            </Typography>
          );
        case "submitted":
          return (
            <Typography variant="body2" component="span" sx={{ color: "#27ae60" }}>
              Ready for Review
            </Typography>
          );
        case "approved":
          return <>Approved</>;
      }
    },
    type: "singleSelect",
    valueOptions: [
      { value: "pending", label: "Pending" },
      { value: "rejected", label: "Corrections Requested" },
      { value: "in_review", label: "In Review" },
      { value: "submitted", label: "Ready for Review" },
      { value: "apporved", label: "Approved" },
    ],
  },
  {
    field: "total_sales",
    type: "number",
    headerName: "Total Sales",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.total_sales)} </>
    ),
  },
  {
    field: "marketing_total_spend",
    type: "number",
    headerName: "Total Marketing Spend",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.marketing_total_spend)} </>
    ),
  },
  {
    field: "marketing_spend_percentage",
    type: "number",
    headerName: "% Marketing Actual",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatPercentage(params.row.marketing_total_spend / params.row.total_sales)} </>
    ),
  },
  {
    field: "royalties",
    type: "number",
    headerName: "Royalties",
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.royalties)} </>
    ),
  },
  {
    field: "advertising_fee",
    type: "number",
    headerName: "Advertising Fee",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.advertising_fee)} </>
    ),
  },
  {
    field: "tech_fee",
    type: "number",
    headerName: "Tech Fee",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.tech_fee)} </>
    ),
  },
  {
    field: "extra_tech_fee",
    type: "number",
    headerName: "Additional Fees",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{formatCurrency(params.row.extra_tech_fee)} </>
    ),
  },
  {
    field: "total_fees",
    type: "number",
    headerName: "Total Fees",
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, number>) => (
      <>{params.row.status === "pending" ? "TBD" : formatCurrency(params.row.total_fees)} </>
    ),
  },
];
export const RoyaltiesList: React.FC<Props> = (props: Props) => {
  // const [value, setValue] = React.useState<Dayjs>(props.date ? dayjs(props.date) : dayjs());
  const { listRoyaltyFees: royaltyFeesList } = useListRoyaltyFees();

  // const handleChange = (value: Dayjs | null) => {
  //   if (value) {
  //     setValue(value);
  //   }
  // };

  React.useEffect(() => {
    royaltyFeesList.list();
  }, []);

  return (
    <Page title="Royalties">
      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              minDate={minDate}
              maxDate={maxDate}
              value={value}
              onChange={handleChange}
            />
          </LocalizationProvider>
        </Grid>
      </Grid> */}
      <DataGridPro
        rows={royaltyFeesList.royaltyFees}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "net_sales", sort: "desc" }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
          columns: {
            columnVisibilityModel: {
              tech_fee: false,
              extra_tech_fee: false,
              advertising_fee: false,
            },
          },
        }}
        loading={royaltyFeesList.status === api.loading}
        rowCount={royaltyFeesList.total || 0}
        disableRowSelectionOnClick
      />
    </Page>
  );
};
