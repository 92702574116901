import * as React from "react";

import { Box } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";

import { LegoSetBricklinkPrices, LegoSetRecentBuys } from "components";

export const PriceGuide: React.FC = () => {
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;
  return (
    <Box>
      <Box sx={{ mb: 4, width: "80%", mx: "auto", textAlign: "left" }}>
        <LegoSetBricklinkPrices legoSet={legoSet} />
      </Box>
      <Box sx={{ mb: 4, width: "80%", mx: "auto", textAlign: "left" }}>
        <LegoSetRecentBuys legoSet={legoSet} maxHeight={800} />
      </Box>
    </Box>
  );
};
