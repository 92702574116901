import * as React from "react";

import { Box } from "@mui/material";
import { LegoSetContext, TLegoSetContext } from "contexts";
import Carousel from "react-material-ui-carousel";

import { RebrickableImage } from "components";
import { LegoSetImageModel } from "model";

interface Props {
  images: LegoSetImageModel[];
}

export const CarouselImagesDisplay: React.FC<Props> = (props: Props) => {
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;
  return (
    <>
      {props.images.length > 0 ? (
        <Carousel autoPlay={false}>
          {props.images.map((image) => (
            <Box
              sx={{
                width: "100%",
                height: 400,
                textAlign: "center",
                mx: "auto",
              }}
              key={image.id}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "100%",
                  verticalAlign: "middle",
                }}
              ></span>
              <img
                src={image.image_url}
                alt={legoSet.name}
                style={{
                  maxWidth: 370,
                  maxHeight: 370,
                  verticalAlign: "middle",
                }}
              />
            </Box>
          ))}
        </Carousel>
      ) : (
        <RebrickableImage imageUrl={legoSet.rebrickable_image_url} imageAlt={legoSet.name} />
      )}
    </>
  );
};
