import * as React from 'react';

import { Grid, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { Page } from 'components';

import { SessionContext, TSessionContext } from '../../lib';
import { UpdateSettingsForm } from './update_settings_form';

export const UserProfile: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <Page title={`Personal Settings: ${currentUser.display_name} (${currentUser.email})`}>
      <Grid item xs={12}>
        <UpdateSettingsForm />
      </Grid>
    </Page>
  );
};
