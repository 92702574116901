import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    textAlign: 'right',
    margin: theme.spacing(1, 0),
  },
  show: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    '& .units': {
      '& li ': {
        marginBottom: theme.spacing(1),
      },
    },
    '& .container': {
      display: 'flex',
      flexGrow: 1,
    },
    '& .main': {
      flexGrow: 1,
      marginRight: theme.spacing(4),
    },
    '& .sidebar': {
      minWidth: 200,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    '& .input': {
      margin: theme.spacing(3, 0),
    },
    '& .info': {
      margin: theme.spacing(2, 0),
    },
  },
  add: {
    margin: theme.spacing(2),
    textAlign: 'left',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    '& .container': {
      display: 'flex',
      flexGrow: 1,
    },
    '& .main': {
      flexGrow: 1,
      marginRight: theme.spacing(4),
    },
    '& .sidebar': {
      minWidth: 300,
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    '& .input': {
      margin: theme.spacing(3, 0),
    },
    '& .row': {
      margin: theme.spacing(2, 0),
    },
    '& .importContainer': {
      marginTop: theme.spacing(4),
    },
    '& .importResults': {
      marginTop: theme.spacing(2),
    },
  },
  import: {
    textAlign: 'left',
    width: '80%',
    '& .searchBar': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .searchField': {
      flexGrow: 1,
    },
    '& .searchButton': {
      marginLeft: theme.spacing(1),
    },
    '& .searchResults': {
      margin: theme.spacing(2, 'auto'),
      textAlign: 'center',
    },
  },
}));

export default useStyles;
