import * as React from "react";

import { Alert, Button, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useHeartlandAuth } from "api";
import { Page } from "components";
import { SessionContext, TSessionContext } from "lib";

export const HeartlandOAuth: React.FC = () => {
  const { currentUser, isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const heartlandAuth = useHeartlandAuth();
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (currentUser == null || !isAdmin()) {
      nav("/not-authorized");
      return;
    }

    const state = searchParams.get("state");
    const authorization_code = searchParams.get("code");
    if (!state || !authorization_code) return;

    heartlandAuth.storeAccessCode(state, authorization_code);
  }, []);

  if (heartlandAuth.status === "success") {
    return (
      <Page>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            Thank you for authorizing Heartland, redirecting you to the previous Heartland page...
          </Grid>
        </Grid>
      </Page>
    );
  } else if (heartlandAuth.status === "error") {
    return (
      <Page>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            <Alert>
              There was an error with authenticating heartland, please reach out to tech support
            </Alert>
          </Grid>
          <Grid item>
            <Alert severity="error">{heartlandAuth.error}</Alert>
          </Grid>
        </Grid>
      </Page>
    );
  } else {
    return <></>;
  }
};
