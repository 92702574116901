import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

export interface SummaryProps {
  summary: string;
  details: string | React.ReactNode;
}

export const Explain: React.FC<SummaryProps> = ({ summary, details }: SummaryProps) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
