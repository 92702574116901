import * as React from "react";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CancelIcon from "@mui/icons-material/Cancel";
import { Backdrop, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  CountrySelector,
  HeartlandSelector,
  InfoBar,
  Page,
  StateSelector,
  TitleLink,
} from "components";
import { UserSelector } from "components/user_selector";
import { api } from "lib";
import { HeartlandModel, UnitModel, UserModel } from "model";

import { useAddUnit } from "./api/add";
import useStyles from "./styles";
import { validateUnit } from "./validate_unit";

const initialOwner = {
  id: 0,
  first_name: "",
  last_name: "",
  display_name: "",
  email: "",
  username: "",
  user_type: "owner",
  settings: [],
};

const initialUnit = {
  id: 0,
  name: "",
  heartland_name: "",
  franchise_id: "0",
  address: "",
  address2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "US",
  open: false,
  grand_open_date: "",
  soft_open_date: "",
  heartland: {
    id: 0,
    api_key: "",
    domain: "",
    active: true,
    user: initialOwner,
    oauth_authenticated: false,
    use_oauth: false,
  },
  owner: initialOwner,
  settings: [],
  tags: [],
};

export const UnitAdd: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [unit, setUnit] = React.useState<UnitModel>(initialUnit);
  const [open, setOpen] = React.useState<boolean>(false);
  const { status, error, add, unit: newUnit } = useAddUnit();
  const updateUnit = (prop: string, value: string | number | UserModel | HeartlandModel) =>
    setUnit({ ...unit, [prop]: value });

  const hasValidUnitParams = React.useMemo(() => {
    return validateUnit(unit);
  }, [unit]);

  React.useEffect(() => {
    if (status !== api.loading) {
      setOpen(false);
    }
    if (status === api.success && newUnit) {
      navigate(`/units/${newUnit.id}`, {
        state: { status: "info", message: "Unit sucessfully created." },
      });
    }
  }, [status, navigate, newUnit]);

  const doAdd = () => {
    setOpen(true);
    add(unit);
  };

  return (
    <Page title={<TitleLink link="/units" linkText="Units" postText="New Unit" />}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {status === api.error && (
          <Grid item xs={12} md={12}>
            <InfoBar status="error" message={error} />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <TextField
                id="franchise_id"
                label="Franchise ID"
                variant="outlined"
                onChange={(e) => updateUnit("franchise_id", e.target.value)}
                fullWidth
                value={unit && unit.franchise_id !== "0" ? unit.franchise_id : ""}
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <UserSelector
                onSelect={(value) => {
                  updateUnit("owner", value);
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth
                onChange={(e) => updateUnit("name", e.target.value)}
                value={unit ? unit.name : ""}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <HeartlandSelector
                value={unit && unit.heartland ? unit.heartland : undefined}
                onSelect={(value) => updateUnit("heartland", value)}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                fullWidth
                value={unit ? unit.address : ""}
                onChange={(e) => updateUnit("address", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="address2"
                label="Address (Suite/Unit)"
                variant="outlined"
                fullWidth
                value={unit ? unit.address2 : ""}
                onChange={(e) => updateUnit("address2", e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="city"
                label="City"
                variant="outlined"
                fullWidth
                value={unit ? unit.city : ""}
                onChange={(e) => updateUnit("city", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <StateSelector
                value={unit.state}
                country={unit.country}
                onSelect={(value) => updateUnit("state", value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                id="postal_code"
                label="Postal Code"
                variant="outlined"
                fullWidth
                value={unit ? unit.postal_code : ""}
                onChange={(e) => updateUnit("postal_code", e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CountrySelector
                value={unit.country}
                onSelect={(value) => updateUnit("country", value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="phone"
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={unit ? unit.phone : ""}
                onChange={(e) => updateUnit("phone", e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="email"
                label="Email Address"
                variant="outlined"
                fullWidth
                value={unit ? unit.email : ""}
                onChange={(e) => updateUnit("email", e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Add"
                startIcon={<AddBusinessIcon />}
                fullWidth
                disabled={!hasValidUnitParams}
                onClick={doAdd}
              >
                Add unit
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/units"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
