import * as React from "react";

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { geographies } from "lib";

interface Props {
  country: string;
  value: string;
  onSelect?: (state: string) => void;
  size?: "small" | "medium" | undefined;
}

export const StateSelector: React.FC<Props> = ({
  onSelect,
  value,
  country,
  size = "medium",
}: Props) => {
  const [selectedState, setSelectedState] = React.useState<string>(value);

  const label = React.useMemo(() => (country === "US" ? "State" : "Province"), [country]);
  const states = React.useMemo(() => {
    if (country === "US") {
      return geographies.US.states;
    } else {
      return geographies.CA.states;
    }
  }, [country]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedState(event.target.value);
    if (event.target.value && onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="state-select-label">{label}</InputLabel>
      <Select
        labelId="state-select-label"
        id="State"
        size={size}
        value={`${selectedState ? selectedState : ""}`}
        onChange={handleChange}
        label={label}
      >
        {states.map((state) => (
          <MenuItem key={state.abbreviation} value={state.abbreviation}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
