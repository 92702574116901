import * as React from 'react';

import { Box, LinearProgress } from '@mui/material';

import { useGetHeartland } from 'api';
import { Page, RewardsFormTab } from 'components';
import { SessionContext, TSessionContext, api } from 'lib';

export const RewardsSignupFormSettings: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { getHeartland } = useGetHeartland();

  React.useEffect(() => {
    if (currentUser) {
      getHeartland.current();
    }
  }, [getHeartland.current, currentUser]);

  if (getHeartland.status === api.loading) {
    return <LinearProgress />;
  } else if (getHeartland.status === api.success && getHeartland.heartland) {
    return (
      <Page title="Rewards Signup Form Settings" title_margin={1}>
        <RewardsFormTab
          rewardsSignupForm={getHeartland.heartland.rewards_signup_form}
          customFields={getHeartland.heartland.customer_custom_fields}
        />
      </Page>
    );
  } else {
    return <></>;
  }
};
