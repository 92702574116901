import * as React from "react";

import { InputAdornment, TextField } from "@mui/material";

interface PercentageInputProps {
  label: string;
  value: number;
  max100?: boolean;
  fullWidth?: boolean;
  onChange: (value: number) => void;
}

export const PercentageInput: React.FC<PercentageInputProps> = ({
  label,
  value,
  onChange,
  max100 = false,
  fullWidth = false,
}: PercentageInputProps) => {
  const update = (value: number) => {
    if (value > 100 && max100) {
      value = 100;
    } else if (value < 0) {
      value = 0;
    }
    onChange(value);
  };

  return (
    <TextField
      label={label}
      type="number"
      value={value}
      size="small"
      fullWidth={fullWidth}
      onChange={(e) => update(parseInt(e.target.value) || 0)}
      onFocus={(event) => event.target.select()}
      InputProps={{
        style: { fontSize: "0.9rem" },
        endAdornment: (
          <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
            %
          </InputAdornment>
        ),
      }}
      sx={fullWidth ? {} : { width: 80 }}
    />
  );
};
