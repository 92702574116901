import * as React from "react";

import { Box, Grid, LinearProgress, Link, Tab, Tabs, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

import { useGetCustomer } from "api";
import { NotFound, Page, TitleLink } from "components";
import { SessionContext, TSessionContext, api } from "lib";
import { CustomerModel } from "model";

import { CustomerInfo, CustomerTradeHistory } from "./components";

interface FormProps {
  customer: CustomerModel;
}

export const CustomerForm: React.FC<FormProps> = (props: FormProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = React.useState<string>(searchParams.get("tab") || "info");
  const [customer, setCustomer] = React.useState<CustomerModel>(props.customer);

  const handleTabChange = (activeTab: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", activeTab);
    setSearchParams(newSearchParams);
  };

  React.useEffect(() => {
    setCurrentTab(searchParams.get("tab") || "info");
  }, [searchParams]);

  return (
    <Page
      title={
        <TitleLink
          link="/customers"
          linkText="Customers"
          postText={`${customer.first_name} ${customer.last_name}`}
        />
      }
    >
      <Tabs onChange={(e, v) => handleTabChange(v)} value={currentTab} sx={{ mb: 4 }}>
        <Tab label="Customer Info" value="info" sx={{ px: 8 }} />
        <Tab label="Buy/Trade History" value="history" sx={{ px: 8 }} />
      </Tabs>
      <Box role="tabpanel" hidden={currentTab !== "info"}>
        <CustomerInfo customer={customer} onCustomerChange={setCustomer} />
      </Box>
      <Box role="tabpanel" hidden={currentTab !== "history"}>
        <CustomerTradeHistory customer={customer} />
      </Box>
    </Page>
  );
};

export const CustomerShow: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { customer_id } = useParams();
  const { customer, status, get } = useGetCustomer();

  React.useEffect(() => {
    if (customer_id) {
      get(parseInt(customer_id));
    }
  }, [customer_id, get]);

  if (status === api.loading) {
    return (
      <div style={{ marginTop: 32, width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.error || !currentUser) {
    return (
      <div style={{ width: 800, margin: "0 auto", textAlign: "center" }}>
        <NotFound />
        <h4>
          <Link href="/customers">Return to Customers List</Link>
        </h4>
      </div>
    );
  } else if (status === api.success && customer) {
    return <CustomerForm customer={customer} />;
  }
  return <></>;
};
