import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { BuyContext, TBuyContext } from 'contexts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog, UnitSelector, UserSelector } from 'components';
import { WarningDisplay } from 'components/warnings/warning_display';
import { api, formatCurrency } from 'lib';
import {
  AnimalBuyLineModel,
  AnimalModel,
  AnimalPriceBand,
  BulkBuyLineModel,
  BulkPriceBand,
  BuySettings,
  CustomerImportModel,
  LegoSetModel,
  MinifigBuyLineModel,
  MinifigModel,
  MinifigPriceBand,
  MiscPriceBand,
  NewSetBuyLineModel,
  SeriesMinifigBuyLineModel,
  SeriesMinifigModel,
  UnitModel,
  UsedSetBuyLineModel,
  UserModel,
} from 'model';

import {
  AnimalSelector,
  BulkSelector,
  CMFSelector,
  CompleteModal,
  CustomerModal,
  MinifigSelector,
  MiscSelector,
  NewSetSelector,
  UsedSetSelector,
} from '../index';

interface TopBarProps {
  settings: BuySettings;
}

export const TopBar: React.FC<TopBarProps> = ({ settings }: TopBarProps) => {
  const navigate = useNavigate();
  const {
    buy,
    addBulk,
    addUsedSet,
    addNewSet,
    addMisc,
    addMinifig,
    addCMF,
    addAnimal,
    setCreator,
    setCustomer,
    save,
    cancel,
    setUnit,
    saveStatus,
    warnings,
  } = React.useContext(BuyContext) as TBuyContext;

  const [show, setShow] = React.useState<string | null>(null);
  const [showCustomerModal, setShowCustomerModal] = React.useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [cancelNote, setCancelNote] = React.useState<string>('');
  const [showExitModal, setShowExitModal] = React.useState<boolean>(false);
  const flags = useFlags();

  const closeCustomerModal = () => setShowCustomerModal(false);
  const closeCompleteModal = () => setShowCompleteModal(false);
  const finalize = () => setShowCompleteModal(true);
  const handleExit = () => setShowExitModal(true);
  const handleCancel = () => setShowCancelModal(true);

  const updateEmployee = (user: UserModel) => setCreator(user);
  const updateUnit = (unit: UnitModel) => setUnit(unit);

  const handleExitConfirm = (value: boolean) => {
    setShowExitModal(false);
    if (value) {
      navigate('/buys');
    }
  };

  const handleCancelConfirm = (value: boolean) => {
    setShowCancelModal(false);
    if (value) {
      cancel(cancelNote);
    }
  };

  const updateCustomer = (customer: CustomerImportModel) => {
    setCustomer(customer);
    setShowCustomerModal(false);
  };

  const calcMargin = (cost: any, total: any) => {
    if (!total || !cost) {
      return '0.0%';
    } else {
      const margin = (cost / total) * 100;
      return `${Math.round(margin)}%`;
    }
  };

  const minifigBuyLine: MinifigBuyLineModel = {
    id: 0,
    key: 0,
    value: 0,
    default_value: 0,
    value_discount: 0,
    offer_discount: 0,
    description: '',
    cost_final: 0,
    quantity: 1,
    overstock: false,
    selected: false,
    condition: 'new',
    notes: '',
    deleted: false,
    location: '',
  };

  const animalBuyLine: AnimalBuyLineModel = {
    id: 0,
    key: 0,
    value: 0,
    default_value: 0,
    value_discount: 0,
    offer_discount: 0,
    description: '',
    cost_final: 0,
    quantity: 1,
    overstock: false,
    selected: false,
    condition: 'new',
    notes: '',
    deleted: false,
    location: '',
  };

  const getMinifigValue = (minifig: MinifigModel | SeriesMinifigModel): number => {
    if (settings.settings?.default_minifig_price === 'bricklink_avg') {
      return Math.floor(minifig.new_bricklink_prices.avg || minifig.used_bricklink_prices.avg);
    } else if (settings.settings?.default_minifig_price === 'bricklink_min') {
      return Math.floor(minifig.new_bricklink_prices.min || minifig.used_bricklink_prices.min);
    } else if (settings.settings?.default_minifig_price === 'bricklink_max') {
      return Math.floor(minifig.new_bricklink_prices.max || minifig.used_bricklink_prices.max);
    } else {
      return 0;
    }
  };

  // TODO: once we integrate bricklink pricing, add the options here
  const getAnimalValue = (animal: AnimalModel): number => {
    return 0;
  };

  const addNewMinifig = (minifig: MinifigModel | null, band: MinifigPriceBand | null) => {
    if (minifig) {
      minifigBuyLine.minifig = minifig;
      minifigBuyLine.value = getMinifigValue(minifig);
      minifigBuyLine.default_value = minifigBuyLine.value;
      minifigBuyLine.description = `${minifig.bricklink_id}: ${minifig.name}`;
    }
    if (band) {
      minifigBuyLine.description = `${band.name} Minifigure`;
      minifigBuyLine.value = +band.value;
      minifigBuyLine.default_value = minifigBuyLine.value;
    }
    minifigBuyLine.condition = settings.settings
      ? settings.settings.default_minifig_condition
      : 'new';
    minifigBuyLine.value_discount = 0;
    minifigBuyLine.offer_discount = 0;
    addMinifig(minifigBuyLine);
  };

  const getNewValue = (newSet: LegoSetModel): number => {
    if (settings.settings?.default_nib_price === 'bricklink_avg') {
      return Math.floor(newSet.new_bricklink_prices.avg || newSet.used_bricklink_prices.avg);
    } else if (settings.settings?.default_nib_price === 'bricklink_min') {
      return Math.floor(newSet.new_bricklink_prices.min || newSet.used_bricklink_prices.min);
    } else if (settings.settings?.default_nib_price === 'bricklink_max') {
      return Math.floor(newSet.new_bricklink_prices.max || newSet.used_bricklink_prices.max);
    } else {
      return 0;
    }
  };

  const addNewAnimal = (animal: AnimalModel | null, band: AnimalPriceBand | null) => {
    if (animal) {
      animalBuyLine.animal = animal;
      animalBuyLine.value = getAnimalValue(animal);
      animalBuyLine.default_value = animalBuyLine.value;
      animalBuyLine.description = `${animal.bricklink_id}: ${animal.name}`;
    }
    if (band) {
      animalBuyLine.description = `${band.name} animal`;
      animalBuyLine.value = +band.value;
      animalBuyLine.default_value = animalBuyLine.value;
    }
    animalBuyLine.condition = settings.settings
      ? settings.settings.default_animal_condition
      : 'new';
    animalBuyLine.value_discount = 0;
    animalBuyLine.offer_discount = 0;
    addAnimal(animalBuyLine);
  };

  const getUsedValue = (usedSet: LegoSetModel): number => {
    if (settings.settings?.default_used_price === 'bricklink_avg') {
      return Math.floor(usedSet.used_bricklink_prices.avg);
    } else if (settings.settings?.default_used_price === 'bricklink_min') {
      return Math.floor(usedSet.used_bricklink_prices.min);
    } else if (settings.settings?.default_used_price === 'bricklink_max') {
      return Math.floor(usedSet.used_bricklink_prices.max);
    } else {
      return 0;
    }
  };

  const addNewMisc = (name: string) => {
    if (settings.settings && name) {
      const line = {
        id: 0,
        key: 0,
        description: name,
        quantity: 1,
        value: 0,
        notes: '',
        overstock: false,
        selected: false,
        value_discount: 0,
        offer_discount: 0,
        deleted: false,
        cost_final: 0,
        condition: '',
        location: '',
      };
      addMisc(line);
    }
  };

  const addMiscBand = (band: MiscPriceBand) => {
    if (settings.settings && band) {
      const line = {
        id: 0,
        key: 0,
        description: band.name,
        quantity: 1,
        value: band.value,
        notes: '',
        overstock: false,
        selected: false,
        value_discount: 0,
        offer_discount: 0,
        deleted: false,
        cost_final: 0,
        condition: '',
        location: '',
      };
      addMisc(line);
    }
  };

  const addLooseBulk = (gallons: number) => {
    if (settings.settings) {
      const line: BulkBuyLineModel = {
        id: 0,
        key: 0,
        description: `${gallons} gal`,
        value: settings.settings.bulk_resale_value,
        default_value: settings.settings.bulk_resale_value,
        quantity: gallons,
        value_discount: 0,
        offer_discount: 0,
        cost_final: 0,
        notes: '',
        overstock: false,
        selected: false,
        dirty: false,
        non_lego: 0,
        premium_parts: false,
        minifigs: false,
        deleted: false,
        location: '',
      };
      addBulk(line);
    }
  };

  const addNewBulk = (bulkBand: BulkPriceBand | null) => {
    if (bulkBand && settings.settings) {
      addBulk({
        id: 0,
        key: 0,
        description: `${bulkBand.name} (${bulkBand.volume} gal)`,
        value: settings.settings.bulk_resale_value,
        default_value: settings.settings.bulk_resale_value,
        quantity: bulkBand.volume,
        value_discount: 0,
        offer_discount: 0,
        cost_final: 0,
        notes: '',
        overstock: false,
        selected: false,
        dirty: false,
        non_lego: 0,
        premium_parts: false,
        minifigs: false,
        deleted: false,
        location: '',
      });
    }
  };

  const addNewUsedSet = (usedSet: LegoSetModel | null) => {
    if (usedSet) {
      const usedSetBuyLine: UsedSetBuyLineModel = {
        id: 0,
        key: 0,
        quantity: 1,
        lego_set: usedSet,
        value: getUsedValue(usedSet),
        default_value: getUsedValue(usedSet),
        value_discount: 0,
        offer_discount: 0,
        cost_final: 0,
        complete: 'default',
        dirty: false,
        certifiable: false,
        overstock: false,
        selected: false,
        desirability: 'good_seller',
        notes: '',
        deleted: false,
        location: '',
      };
      addUsedSet(usedSetBuyLine);
      setShow(null);
    }
  };

  const addNewNewSet = (newSet: LegoSetModel | null) => {
    if (newSet) {
      const newSetBuyLine: NewSetBuyLineModel = {
        id: 0,
        key: 0,
        quantity: 1,
        lego_set: newSet,
        value: getNewValue(newSet),
        default_value: getNewValue(newSet),
        value_discount: 0,
        offer_discount: 0,
        cost_final: 0,
        opened: false,
        damaged: false,
        overstock: false,
        selected: false,
        desirability: 'good_seller',
        notes: '',
        deleted: false,
        location: '',
      };
      addNewSet(newSetBuyLine);
      setShow(null);
    }
  };

  const addNewCMF = (minifig: SeriesMinifigModel) => {
    if (minifig) {
      const minifigBuyLine: SeriesMinifigBuyLineModel = {
        id: 0,
        key: 0,
        quantity: 1,
        series_minifig: minifig,
        value: getMinifigValue(minifig),
        default_value: getMinifigValue(minifig),
        value_discount: 0,
        offer_discount: 0,
        cost_final: 0,
        overstock: false,
        selected: false,
        condition: settings.settings ? settings.settings.default_minifig_condition : 'new',
        notes: '',
        deleted: false,
        location: '',
      };
      addCMF(minifigBuyLine);
      setShow(null);
    }
  };

  const handleShow = (newShow: string | null) => {
    if (newShow === show) {
      setShow(null);
    } else {
      setShow(newShow);
    }
  };

  const totalItems = () => {
    return (
      buy.total_minifig_qty +
      buy.total_cmf_qty +
      buy.total_nib_qty +
      buy.total_used_qty +
      buy.total_misc_qty
    );
  };

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          p: 1,
          top: 0,
          mt: -1,
          mb: 2,
          background: '#f9f9f9',
          borderBottom: '1px solid #999',
          zIndex: 999,
          width: '100%',
        }}
      >
        <Grid container alignItems="center" sx={{ mb: 1 }}>
          <Grid item xs={3.5}>
            <Grid container alignItems="center" justifyContent="center" spacing={0.5}>
              <Grid item xs={4}>
                {' '}
                <Typography variant="body2">ID:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{buy.id ? buy.id : '[new]'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Location:</Typography>
              </Grid>
              <Grid item xs={8}>
                <UnitSelector
                  size="small"
                  variant="standard"
                  label={false}
                  value={buy.unit}
                  onSelect={updateUnit}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Employee:</Typography>
              </Grid>
              <Grid item xs={8}>
                <UserSelector
                  type="employee"
                  size="small"
                  label={false}
                  variant="standard"
                  value={buy.creator}
                  onSelect={updateEmployee}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Customer:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Button
                  size="small"
                  sx={{ m: 0, p: 0, justifyContent: 'flex-start' }}
                  onClick={() => setShowCustomerModal(true)}
                >
                  {buy.customer ? `${buy.customer.first_name} ${buy.customer.last_name}` : 'none'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ mx: 4 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Estimated Retail Value:</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Non-Bulk:</TableCell>
                    <TableCell>
                      {formatCurrency(buy.total_retail, false)} ({totalItems()} items)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bulk:</TableCell>
                    <TableCell>
                      {formatCurrency(buy.total_bulk_value, false)} ({buy.total_bulk_qty} gallons)
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={4.5} sx={{ fontSize: '0.8em' }}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Credit</TableCell>
                  <TableCell>Cash</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Offer</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    {formatCurrency(buy.credit_min, false)} -{' '}
                    {formatCurrency(buy.credit_max, false)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    {formatCurrency(buy.cash_min, false)} - {formatCurrency(buy.cash_max, false)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Non-Bulk Cost</TableCell>
                  <TableCell>
                    {calcMargin(buy.credit_min - buy.bulk_credit_offered, buy.total_retail)} -{' '}
                    {calcMargin(buy.credit_max - buy.bulk_credit_offered, buy.total_retail)}
                  </TableCell>
                  <TableCell>
                    {calcMargin(buy.cash_min - buy.bulk_cash_offered, buy.total_retail)} -{' '}
                    {calcMargin(buy.cash_max - buy.bulk_cash_offered, buy.total_retail)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bulk Cost:</TableCell>
                  <TableCell>{calcMargin(buy.bulk_credit_offered, buy.total_bulk_value)}</TableCell>
                  <TableCell>{calcMargin(buy.bulk_cash_offered, buy.total_bulk_value)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={saveStatus === api.loading}
                  onClick={save}
                >
                  Save{' '}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={saveStatus === api.loading || !buy.id}
                  onClick={finalize}
                >
                  Finalize
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={saveStatus === api.loading || !buy.id}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={saveStatus === api.loading}
                  onClick={handleExit}
                >
                  Exit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {warnings.length > 0 && (
            <Grid item xs={12}>
              <WarningDisplay warnings={warnings} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={0.5}>
              <Grid item>
                <Button
                  onClick={() => handleShow('minifig')}
                  aria-label="Minifig"
                  sx={{ border: '1px solid #ccc', background: '#f0f0f3' }}
                >
                  <AddIcon />
                  Minifig
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleShow('cmf')}
                  aria-label="CMF"
                  sx={{ border: '1px solid #ccc', background: '#f0f9f9' }}
                >
                  <AddIcon />
                  CMF
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleShow('new')}
                  aria-label="New Set"
                  sx={{ border: '1px solid #ccc', background: '#f9f0f0' }}
                >
                  <AddIcon />
                  New Set
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleShow('used')}
                  aria-label="Used Set"
                  sx={{ border: '1px solid #ccc', background: '#f9f9f0' }}
                >
                  <AddIcon />
                  Used Set
                </Button>
              </Grid>
              {flags.animalCatalog && (
                <Grid item>
                  <Button
                    onClick={() => handleShow('animal')}
                    aria-label="Animal"
                    sx={{ border: '1px solid #ccc', background: '#efF7ef' }}
                  >
                    <AddIcon />
                    Animal
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  onClick={() => handleShow('bulk')}
                  aria-label="Bulk"
                  sx={{ border: '1px solid #ccc', background: '#feeeda' }}
                >
                  <AddIcon />
                  Bulk
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleShow('misc')}
                  aria-label="Misc"
                  sx={{ border: '1px solid #ccc', background: '#ffffe7' }}
                >
                  <AddIcon />
                  Misc
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomerModal
          open={showCustomerModal}
          onClose={closeCustomerModal}
          onAdd={updateCustomer}
        />
        <CompleteModal
          open={showCompleteModal}
          onClose={closeCompleteModal}
          settings={settings.settings}
          onComplete={() => {}}
        />
        <ConfirmationDialog
          show={showExitModal}
          onClose={handleExitConfirm}
          message="Are you sure you want to exit?"
        />
        <ConfirmationDialog
          show={showCancelModal}
          onClose={handleCancelConfirm}
          message="Are you sure you want to cancel this buy? This buy will be closed and can not be edited anymore."
        >
          <TextField
            label="Note"
            variant="outlined"
            size="small"
            fullWidth
            value={cancelNote}
            onChange={(e) => setCancelNote(e.target.value)}
          />
        </ConfirmationDialog>
        {show === 'used' && (
          <UsedSetSelector onAdd={addNewUsedSet} onCancel={() => setShow(null)} />
        )}
        {show === 'new' && <NewSetSelector onAdd={addNewNewSet} onCancel={() => setShow(null)} />}
        {show === 'cmf' && <CMFSelector onAdd={addNewCMF} onCancel={() => setShow(null)} />}
        {show === 'minifig' && (
          <MinifigSelector
            bands={settings.minifig_price_bands}
            onAdd={addNewMinifig}
            onCancel={() => setShow(null)}
          />
        )}
        {show === 'animal' && (
          <AnimalSelector
            bands={settings.animal_price_bands}
            onAdd={addNewAnimal}
            onCancel={() => setShow(null)}
          />
        )}
        {show === 'bulk' && (
          <BulkSelector
            bands={settings.bulk_price_bands}
            onAddBand={addNewBulk}
            onAddLoose={addLooseBulk}
            onCancel={() => setShow(null)}
          />
        )}
        {show === 'misc' && (
          <MiscSelector
            bands={settings.misc_price_bands}
            onAddMisc={addNewMisc}
            onAddBand={addMiscBand}
            onCancel={() => setShow(null)}
          />
        )}
      </Box>
    </>
  );
};
