import * as React from "react";

import { TextField } from "@mui/material";
import dayjs from "dayjs";

import { useDailySalesList } from "api";
import { api, formatCurrency } from "lib";

const readOnlyStyle = { background: "#f0f0f0", textAlign: "right" };

export interface PosSalesProps {
  unitId: number;
  date: string;
}

export const PosSales: React.FC<PosSalesProps> = ({ unitId, date }: PosSalesProps) => {
  const { dailySalesList, getDailySalesList } = useDailySalesList();

  React.useEffect(() => {
    const startDate = dayjs(date).startOf("month").format("YYYY-MM-DD");
    const endDate = dayjs(date).endOf("month").format("YYYY-MM-DD");
    getDailySalesList(startDate, endDate);
  }, [date]);

  const netSales = React.useMemo(() => {
    let data = null;
    if (dailySalesList.status === api.success && dailySalesList.sales.length > 0) {
      if (unitId) {
        data = dailySalesList.sales.find((sales) => sales.unit_id === unitId);
      } else {
        data = dailySalesList.total;
      }
    }
    if (data) {
      return formatCurrency(data.net_sales || 0);
    }
    return "Not Available";
  }, [dailySalesList, unitId]);

  return (
    <TextField
      id="status"
      label="MTD POS Revenue"
      variant="outlined"
      fullWidth
      value={netSales}
      inputProps={{ readOnly: true, sx: readOnlyStyle }}
    />
  );
};
