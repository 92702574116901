import * as React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useHeartlandsList } from 'api';
import { InfoBar, Page } from 'components';
import { api } from 'lib';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Link href={`heartlands/${params.row.id}`}>{params.row.id}</Link>
    ),
  },
  {
    field: 'domain',
    headerName: 'Domain',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Link href={`heartlands/${params.row.id}`}>{params.row.domain}</Link>
    ),
  },
  {
    field: 'heartland_url',
    headerName: 'Heartland URL',
    flex: 2,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`https://${params.row.domain}.retail.heartland.us`} className="externalLink">
          <Box display="flex" alignItems="center">
            <span>{`https://${params.row.domain}.retail.heartland.us`}</span>
            <LaunchIcon />
          </Box>
        </Link>
      </>
    ),
  },
  {
    field: 'user',
    headerName: 'Owner',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.user.first_name} {params.row.user.last_name}
      </>
    ),
  },
  {
    field: 'units_count',
    headerName: '# Units',
    renderCell: (params: GridRenderCellParams<any, string>) => <>{params.row.units_count}</>,
  },
];

interface ListProps {
  query: string;
}

const List: React.FC<ListProps> = ({ query }: ListProps) => {
  const { heartlands, status, total, error, list } = useHeartlandsList();
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });

  React.useEffect(() => {
    list(query, paginationModel.page, paginationModel.pageSize);
  }, [paginationModel, list, query]);

  if (status === api.error) {
    return <InfoBar status="error" message={error} />;
  }
  return (
    <>
      <DataGrid
        rows={heartlands}
        loading={status !== api.success}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        rowCount={total}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
      />
    </>
  );
};

export const HeartlandList: React.FC = () => {
  const [query, setQuery] = React.useState<string>('');

  return (
    <Page title="Heartland Instances">
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
        <Grid item xs={9}>
          <TextField
            name="query"
            label="Search"
            fullWidth
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" startIcon={<LocalOfferIcon />} href="/heartlands/new">
            New Heartland Instance
          </Button>
        </Grid>
      </Grid>
      <List query={query} />
    </Page>
  );
};
