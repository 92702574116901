import * as React from "react";

import { useManageLegoSetImage, useUpdateLegoSet } from "api";
import { api } from "lib";
import { LegoSetImageModel, LegoSetModel } from "model";

interface State {
  legoSet: LegoSetModel;
  updateStatus: { status: string; msg: string };
}

export type TLegoSetContext = State & {
  update: (key: string, value: string | number | boolean) => void;
  save: () => void;
  addImage: (legoSetImage: LegoSetImageModel) => void;
  deleteImage: (legoSetImage: LegoSetImageModel) => void;
};

interface LegoSetContextProps {
  children: React.ReactNode;
  legoSet: LegoSetModel;
  onSave?: (success: boolean, msg: string) => void;
  onAddImage?: (success: boolean, msg: string) => void;
  onDeleteImage?: (success: boolean, msg: string) => void;
}

export const LegoSetContext = React.createContext<TLegoSetContext | null>(null);

export const LegoSetProvider: React.FC<LegoSetContextProps> = (props: LegoSetContextProps) => {
  const [state, setState] = React.useState<State>({
    legoSet: props.legoSet,
    updateStatus: { status: api.idle, msg: "" },
  });
  const { updateLegoSet } = useUpdateLegoSet();
  const { addLegoSetImage, deleteLegoSetImage } = useManageLegoSetImage();

  const update = (key: string, value: string | number | boolean) => {
    setState({ ...state, legoSet: { ...state.legoSet, [key]: value } });
  };

  const save = () => {
    updateLegoSet.update(state.legoSet);
  };

  React.useEffect(() => {
    if (updateLegoSet.status === api.success && updateLegoSet.legoSet) {
      setState({ updateStatus: { status: api.success, msg: "" }, legoSet: updateLegoSet.legoSet });
      if (props.onSave) {
        props.onSave(true, "Saved");
      }
    } else if (updateLegoSet.status === api.error) {
      setState({ ...state, updateStatus: { status: api.error, msg: updateLegoSet.message } });
      if (props.onSave) {
        props.onSave(false, updateLegoSet.message);
      }
    }
  }, [updateLegoSet]);

  React.useEffect(() => {
    if (addLegoSetImage.status === api.success && addLegoSetImage.image) {
      if (props.onAddImage) {
        props.onAddImage(true, "Image added");
      }
      addLegoSetImage.reset();
    }
  }, [addLegoSetImage]);

  React.useEffect(() => {
    if (deleteLegoSetImage.status === api.success && deleteLegoSetImage.image?.id) {
      if (props.onDeleteImage) {
        props.onDeleteImage(true, "Image deleted");
      }
      deleteLegoSetImage.reset();
    }
  }, [deleteLegoSetImage]);

  const addImage = (legoSetImage: LegoSetImageModel) => {
    addLegoSetImage.add(legoSetImage.image_url, legoSetImage.lego_set_id, legoSetImage.condition);
    const currentLegoSet = { ...state.legoSet };
    if (legoSetImage.condition === "new") {
      setState({
        ...state,
        legoSet: { ...currentLegoSet, new_images: [...currentLegoSet.new_images, legoSetImage] },
      });
    } else {
      setState({
        ...state,
        legoSet: { ...currentLegoSet, used_images: [...currentLegoSet.used_images, legoSetImage] },
      });
    }
  };

  const deleteImage = (legoSetImage: LegoSetImageModel) => {
    if (legoSetImage.id) deleteLegoSetImage.deleteImage(legoSetImage.id, legoSetImage.lego_set_id).then((new_primary_image_url) => {
      const currentLegoSet = { ...state.legoSet };
      const newImages = currentLegoSet.new_images.filter((image) => image.id !== legoSetImage.id);
      const usedImages = currentLegoSet.used_images.filter((image) => image.id !== legoSetImage.id);
      setState({
        ...state,
        legoSet: {
          ...currentLegoSet,
          new_images: newImages,
          used_images: usedImages,
          rebrickable_image_url: new_primary_image_url || "",
        },
      });
    });
  };

  return (
    <LegoSetContext.Provider
      value={{
        legoSet: state.legoSet,
        updateStatus: state.updateStatus,
        update,
        save,
        addImage,
        deleteImage,
      }}
    >
      {props.children}
    </LegoSetContext.Provider>
  );
};
