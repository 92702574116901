import * as React from "react";

import { StatusCodes } from "http-status-codes";

import { api } from "lib";
import { LegoSetImageModel } from "model";

interface State {
  status: string;
  message: string;
  primaryImageUrl: string | null;
  image: LegoSetImageModel | null;
}

interface Props {
  addLegoSetImage: State & {
    add: (url: string, legoSetId: number, type: string) => void;
    reset: () => void;
  };
  deleteLegoSetImage: State & {
    deleteImage: (imageId: number, legoSetId: number) => Promise<string | null>;
    reset: () => void;
  };
}

const defaultState = {
  status: api.idle,
  message: "",
  primaryImageUrl: null,
  image: null,
};

export const useManageLegoSetImage = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const add = React.useCallback(async (url: string, legoSetId: number, type: string) => {
    setState({ status: api.loading, message: "", image: null, primaryImageUrl: null });
    const resp = await api.post(`/api/lego_sets/${legoSetId}/images/upload`, {
      url: url,
      type: type,
    });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, image: resp.body, primaryImageUrl: null, message: "" });
    } else {
      setState({ status: api.error, image: null, primaryImageUrl: null, message: resp.body });
    }
  }, []);

  const deleteImage = React.useCallback(async (imageId: number, legoSetId: number) => {
    setState({ status: api.loading, message: "", image: null, primaryImageUrl: null });
    const resp = await api.delete(`/api/lego_sets/${legoSetId}/images/delete?image_id=${imageId}`);
    if (resp.status === StatusCodes.OK) {
      setState({
        status: api.success,
        image: { ...resp.body, id: imageId },
        primaryImageUrl: resp.body.new_primary_image_url,
        message: "",
      });
    } else {
      setState({ status: api.error, image: null, primaryImageUrl: null, message: resp.body });
    }
    return resp.body.new_primary_image_url;
  }, []);

  const reset = React.useCallback(() => {
    setState({ ...defaultState });
  }, []);

  return {
    addLegoSetImage: { ...state, add, reset },
    deleteLegoSetImage: { ...state, deleteImage, reset },
  };
};
